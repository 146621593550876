import React, { useState } from 'react';
import { NetworkStatus } from 'apollo-client';
import { useQuery } from 'react-apollo';
import { gql } from 'apollo-boost';

const xml2js = require('xml2js');

const GQL_QUERY_TELEMETRY_CONFIG = gql`
    query TelemetryConfig {
        telemetryConfig {
            xml
        }
    }`;

function TelemetryConfigLoader(props) {
    // console.log("TelemetryConfigLoader() - props="+JSON.stringify(props));
    const [parseError, setParseError] = useState(null);

    function onCompleted(data) {
        // console.log("TelemetryConfigLoader.onCompleted(): data="+JSON.stringify(data));
        if (data && data.telemetryConfig && data.telemetryConfig.xml) {
            xml2js.parseString(
                data.telemetryConfig.xml,
                {
                    mergeAttrs: true,
                    explicitArray: false,
                    trim: true,
                    attrValueProcessors: [
                        (value, name) => {
                            // console.log("attrValueProcessor() - value=" + value + ", name = " + name);
                            return (name==='id') ? parseInt(value) : value;
                        }
                    ]
                },
                (err,result) => {
                    if (err) {
                        setParseError("Failed to parse received XML: " + err);
                    }
                    else {
                        // console.log("TelemetryConfigLoader.onCompleted(): data="+JSON.stringify(result));
                        if (result.TelemetryConfig) {
                            props.onTelemetryConfigLoaded(result.TelemetryConfig);
                        }
                        else {
                            setParseError("Telemetry Config not found");
                        }
                    }
                }
            );
        }
        else {
            setParseError("Unexpected Telemetry Config received from the server");
        }
    }

    function onError() {
        console.log("TelemetryConfigLoader.onError()");
    }
    const queryResult =
        useQuery(
            GQL_QUERY_TELEMETRY_CONFIG,
            {
                client: props.loginState.client,
                onCompleted: onCompleted,
                onError: onError,
                notifyOnNetworkStatusChange: true
            }
        );

    var element;

    if (!queryResult.called || queryResult.loading) {
        element = (<div className="progressText">Fetching...</div>);
    }
    else if (queryResult.networkStatus === NetworkStatus.refetch) {
        element = (<div className="progressText">Refreshing...</div>);
    }
    else if (queryResult.error) {
        element = (<div className="errorText">{'Error: ' + queryResult.error}</div>);
    }
    else if (parseError) {
        element = (<div className="errorText">Parse error: {parseError}</div>);
    }
    else if (queryResult.data && queryResult.data.TelemetryConfig) {
       element = (<p>Telemetry Config loaded</p>);
    }
    else {
        element = <p className="errorText">Error: unexpected state</p>;
    }
    
    return element;
}

export default TelemetryConfigLoader;