import React, {useState, createRef} from 'react';
import ReactDOMServer from 'react-dom/server';
import Select from 'react-select';
import { FaTrashAlt, FaEye, FaEyeSlash, FaPlus } from 'react-icons/fa';

import WiFiListsInteractor from './WiFiListsInteractor';
import { isArrayNonEmpty, askConfirnation, compareEntriesByID, showServerError } from '../Utils';

import _ from 'lodash';

function isAttachedToDevice(device, wifiList) {
    for (const key in device.wifiLists) {
        const deviceWifiList = device.wifiLists[key];
        if (deviceWifiList.id === wifiList.id) {
            return {label: device.displayName, value: device.uuid, wifiList: wifiList.id};
        }
    }
    return null;
}

function getSpotUserInput(wifiList) {
    const inputSSID = document.getElementById("wfsp_input_ssid_" + wifiList.id);
    const inputPassword = document.getElementById("wfsp_input_password_" + wifiList.id);
    const errorString =  document.getElementById("wfsp_input_error_" + wifiList.id);

    const args_ssid = inputSSID.value.trim();
    let args_password = inputPassword.value.trim();
    if (!args_ssid) {
        errorString.innerText = "Error: incorrect value";
        return null;
    }

    if (!args_password) {
        args_password = "";
    }

    errorString.innerHTML = "";
    return { ssid: args_ssid, password: args_password };
}

function onShowPassword(idInput, idBtn) {
    const pwdInput = document.getElementById(idInput);
    const pwdBtn = document.getElementById(idBtn);
    if (pwdInput.type === "password") {
        pwdInput.type = "text";
        pwdBtn.innerHTML = ReactDOMServer.renderToString(<FaEyeSlash/>);
    } else {
        pwdInput.type = "password";
        pwdBtn.innerHTML = ReactDOMServer.renderToString(<FaEye/>);
    }
}

function renderWiFiListTableHeader(foreign) {
    return (
        <tr>
            <th className="Account-table-th shrink">ID</th>
            <th className="Account-table-th shrink list-name-min-width">Name</th>
            <th className="Account-table-th" style={{width: '20%'}}>Spots</th>
            <th className="Account-table-th">Attached Devices</th>
            {foreign ? null : (<th className="Account-table-th shrink"></th>)}
        </tr>
    );
}

function renderWiFiListTable(elements, foreign) {
    return (
        <table className="Account-table" width="100%">
            <thead>{renderWiFiListTableHeader(foreign)}</thead>
            <tbody>
                {elements}
            </tbody>
        </table>
    );
}

function WiFiListsManager(props) {
    const [action, setAction] = useState(null);
    const [actionsSequence, setSequenceOfAction] = useState(null);

    if (actionsSequence && !action) {
        var nextAction = actionsSequence.shift();
        if (nextAction) {
            setAction(nextAction);
            setSequenceOfAction(actionsSequence);
        } else {
            setAction(null);
            setSequenceOfAction(null);
        }
    }

    const wifiLists = _.cloneDeep(props.wifiLists);
    const viewOnly = props.foreign ? true : false;
    let own_WifiLists = wifiLists.filter((spot) => !spot.foreign);
    let foreign_WifiLists = wifiLists.filter((spot) => spot.foreign);
    own_WifiLists.sort(compareEntriesByID);
    foreign_WifiLists.sort(compareEntriesByID);

    const [actionError, setActionError] = useState(null);

    let inputDisplayName = createRef();

    function renderActionError() {
        if (Array.isArray(actionError))
            return (<div> {actionError.map((error) => (<div>{error}</div>))} </div> );
        return (<div> {actionError} </div>);
    }

    function onCreateCompleted(data) {
        inputDisplayName.current.value = "";
        setAction(null);
    }

    function getUserInput() {
        const args_displayName = inputDisplayName.current.value.trim();
        if (!args_displayName) {
            setActionError("Incorrect values.");
            return null;
        }

        setActionError(null);
        return { displayName: args_displayName };
    }

    function onSubmitWiFiList() {
        const args = getUserInput();
        if (!args) return;
    
        setAction({create: args});
    }

    function onGetCompleted(data) { setAction(null); }
    function onGetError(error) { console.log(error); setAction(null); }
    
    function onAttachDetachDeleteCompleted(data) { setAction(null); }
    function onCreateAttachDetachDeleteError(error) {
        showServerError(error);
        setAction(null);
    }

    function onChangeAttachedDevices(_newDevicesSet, action) {
        switch(action.action) {
            case "select-option":
                setAction({attach: {id: action.option.wifiList, deviceUuid: action.option.value}});
                break;
            case "remove-value":
                setAction({detach: {id: action.removedValue.wifiList, deviceUuid: action.removedValue.value}});
                break;
            case "clear":
                let actions = [];
                for (const key in action.removedValues) {
                    actions.push({detach: {id: action.removedValues[key].wifiList, deviceUuid: action.removedValues[key].value}});
                }
                setSequenceOfAction(actions);
                break;
            default:
        }
    }

    function createDevicesSelector(allOptions, wifiList) {
        const preselected = props.devices.map((device) => (
            isAttachedToDevice(device, wifiList)
        ));
        return (
            <Select
                options={allOptions}
                isMulti={true}
                value={preselected}
                defaultValue={preselected}
                onChange={onChangeAttachedDevices}
                isDisabled={(action ? true : false) || wifiList.foreign || viewOnly}
            />
        );
    }

    function generateOptionsForWiFiList(wifiList) {
        return props.devices
            .filter((device) => device.foreign !== true)
            .map((device) => (
                {label: device.displayName, value: device.uuid, wifiList: wifiList.id}
            ));
    }

    function onDeleteWiFiList(id) {
        askConfirnation('Are you sure you want to delete WiFi list?', () => setAction({delete: {id: id}}), () => {});
    }

    function onSubmitWiFiSpot(wifiList) {
        let args = getSpotUserInput(wifiList);
        if (!args) {
            console.log("Can't add spot, invalid arguments");
            return;
        }

        args.wifiListId = wifiList.id;
    
        console.log("Adding...");
        setAction({addSpot: args});
    }

    function cleanupInput(wifiListId) {
        const inputSSID = document.getElementById("wfsp_input_ssid_" + wifiListId);
        const inputPassword = document.getElementById("wfsp_input_password_" + wifiListId);
        if (inputSSID) { inputSSID.value = ""; }
        if (inputPassword) { inputPassword.value = ""; }
    }

    function onAddSpotCompleted(data) {
        if (action && action.addSpot && action.addSpot.wifiListId) {
            cleanupInput(action.addSpot.wifiListId)
        }
        setAction(null);
    }

    function onAddSpotError(error) {
        showServerError(error);
        setAction(null);
    }

    function onRemoveSpotCompleted(data) { setAction(null); }
    function onRemoveSpotError(error) {
        showServerError(error);
        setAction(null);
    }

    function renderInputErrorString(wifiList) {
        if (wifiList.foreign || viewOnly) {
            return null;
        }
        return (
            <tr><td colSpan={4} id={"wfsp_input_error_" + wifiList.id}></td></tr>
        );
    }

    function renderCreateSpotForm(wifiList) {
        if (wifiList.foreign || viewOnly) {
            return null;
        }
        return (
            <tr>
                <td className="td-wifi-spot-id" style={{width: "0.01%", textAlign: "center"}}>
                    <button
                        className="image-button icon-middle Green"
                        title="Add"
                        onClick={() => onSubmitWiFiSpot(wifiList)}
                        disabled={(action ? true : false)}
                    ><FaPlus/></button>
                </td>
                <td className="td-wifi-spot" style={{width: "50%"}}>
                    <input type="text"
                        className="name-input"
                        key={"wfsp_input_ssid_" + wifiList.id}
                        id={"wfsp_input_ssid_" + wifiList.id}
                        style={{minWidth: "150px"}}
                        disabled={(action ? true : false)}
                        placeholder="SSID"
                    />
                </td>
                <td className="td-wifi-spot" style={{width: "50%"}}>
                    <input type="password"
                        className="name-input"
                        autoComplete={"off"}
                        onFocus={(e) => {e.target.removeAttribute('readOnly')}}
                        onBlur={(e) => {e.target.setAttribute('readOnly', 'true')}}
                        readOnly={true}
                        key={"wfsp_input_password_" + wifiList.id}
                        id={"wfsp_input_password_" + wifiList.id}
                        style={{minWidth: "150px"}}
                        disabled={(action ? true : false)}
                        placeholder="Password"
                    />
                </td>
                <td className="td-wifi-spot" style={{width: "0.01%"}}>
                    <button
                        className="image-button icon-middle Gray"
                        title="Show" id={"wfsp_btn_password_" + wifiList.id}
                        disabled={(action ? true : false) || wifiList.foreign || viewOnly}
                        onClick={() => onShowPassword("wfsp_input_password_" + wifiList.id, "wfsp_btn_password_" + wifiList.id)}
                    ><FaEye/></button>
                </td>                
            </tr>
        );
    }

    function onRemoveSpot(wifiListId, wifiSpotId) {
        askConfirnation('Are you sure you want to delete wifi spot?', () => setAction({removeSpot: {id: wifiSpotId, wifiListId: wifiListId}}), () => {});
    }

    function renderWiFiSpot(wifiList, wifiSpot) {
        return (<tr key={"wifiSpot_" + wifiSpot.id}>
            <td className="td-wifi-spot-id" style={{width: "0.01%"}}><input className="name-input" value={"ID: " + wifiSpot.id} disabled={true}/></td>
            <td className="td-wifi-spot" style={{width: "100%", minWidth: "300px"}} colSpan={2}><input className="name-input" type={"text"} disabled={true} value={wifiSpot.ssid}/></td>
            {(wifiList.foreign || viewOnly) ? null : (
                <td style={{width: "0.01%"}}>
                    <button
                        className="image-button icon-middle Red"
                        title="Delete!"
                        disabled={(action ? true : false)}
                        onClick={() => onRemoveSpot(wifiList.id, wifiSpot.id)}
                    >
                        <FaTrashAlt/>
                    </button>
                </td>
            )}
        </tr>);
    }

    function renderWiFiSpots(wifiList) {
        const elements = 
            isArrayNonEmpty(wifiList.wifiSpots)
                ? wifiList.wifiSpots.sort(compareEntriesByID).map((wifiSpot) => (
                        renderWiFiSpot(wifiList, wifiSpot)
                    ))
                : [];
        return (
            <div>
                <table>
                    <tbody>
                        {elements}
                        {renderCreateSpotForm(wifiList)}
                        {renderInputErrorString(wifiList)}
                    </tbody>
                </table>
            </div>
        );
    }

    function renderOwnWiFiListsTable(wifiList) {
        let elements = wifiList.map((wifiList) => (
            <tr key={"wifiList_" + wifiList.id}>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <span>{wifiList.id}</span>
                </td>
                <td className="Account-table-td" style={{width: "20%"}}>{wifiList.displayName}</td>
                <td className="Account-table-td" style={{width: "30%"}}>{renderWiFiSpots(wifiList)}</td>
                <td className="Account-table-td" style={{width: "50%"}}>
                    {createDevicesSelector(generateOptionsForWiFiList(wifiList), wifiList)}
                </td>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <button className="image-button icon-big Red"
                        title="Delete!" id="red"
                        disabled={(action ? true : false)}
                        onClick={() => onDeleteWiFiList(wifiList.id)}
                    >
                        <FaTrashAlt/>
                    </button>
                </td>
            </tr>
        ));

        elements.push((
            <tr key={"wifiList_add_list"}>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <button
                        className="image-button icon-middle Green"
                        title="Add new WiFi list"
                        onClick={() => onSubmitWiFiList()}
                        disabled={(action ? true : false)}
                    ><FaPlus/></button>
                </td>
                <td className="Account-table-td center">
                    <input
                        type="text"
                        key={"wflist_input_displayName"}
                        ref={inputDisplayName}
                        className="name-input"
                        style={{textAlign: "left"}}
                        placeholder="New WiFi List Name"
                        disabled={(action ? true : false)}
                    />
                </td>
                <td className="Account-table-td" style={{width: "0.01%"}}>{renderActionError()}</td>
                <td className="Account-table-td" style={{width: "50%"}}></td>
                <td className="Account-table-td" style={{width: "0.01%"}}></td>
            </tr>
        ));

        return renderWiFiListTable(elements);
    }

    function renderForeignWiFiListsTable(wifiList) {
        const elements = wifiList.map((wifiList) => (
            <tr key={"wifiList_" + wifiList.id}>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <span>{wifiList.id}</span>
                </td>
                <td className="Account-table-td" style={{width: "20%"}}>{wifiList.displayName}</td>
                <td className="Account-table-td" style={{width: "30%"}}>{renderWiFiSpots(wifiList)}</td>
                <td className="Account-table-td" style={{width: "50%"}}>
                    {createDevicesSelector(generateOptionsForWiFiList(wifiList), wifiList)}
                </td>
            </tr>
        ));
        return renderWiFiListTable(elements, true);
    }

    return (
        <div>
            <h4>Own WiFi lists:</h4>
            {
                viewOnly 
                    ? renderForeignWiFiListsTable(own_WifiLists)
                    : renderOwnWiFiListsTable(own_WifiLists)
            }
            
            <h4>WiFi lists attached to observed devices:</h4>
            {renderForeignWiFiListsTable(foreign_WifiLists)}

            <WiFiListsInteractor action={action}
                wifiLists={wifiLists}
                onCreateCompleted={onCreateCompleted}
                onCreateError={onCreateAttachDetachDeleteError}
                onGetCompleted={onGetCompleted}
                onGetError={onGetError}
                onAttachCompleted={onAttachDetachDeleteCompleted}
                onAttachError={onCreateAttachDetachDeleteError}
                onDetachCompleted={onAttachDetachDeleteCompleted}
                onDetachError={onCreateAttachDetachDeleteError}
                onDeleteCompleted={onAttachDetachDeleteCompleted}
                onDeleteError={onCreateAttachDetachDeleteError}
                onAddSpotCompleted={onAddSpotCompleted}
                onAddSpotError={onAddSpotError}
                onRemoveSpotCompleted={onRemoveSpotCompleted}
                onRemoveSpotError={onRemoveSpotError}
            />
        </div>
    );
}

export default WiFiListsManager;