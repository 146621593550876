import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import TelemetryView from './TelemetryView';
import TelemetryDataSubscription from './TelemetryDataSubscription';
import { getDisplayTimestampLocal } from './Utils';
import { gql } from 'apollo-boost';
import { useQuery } from 'react-apollo';
import { isArrayNonEmpty } from './Utils';
import { FaCaretSquareDown, FaCaretSquareUp } from 'react-icons/fa';
import _ from 'lodash';

const GQL_QUERY_LAST_VALUE = gql`
    query LastTelemetryValue($deviceUuid: String!, $id: Int!) {
        getDevice(uuid: $deviceUuid) {
            telemetry(id: $id, limit: 1) {
                semantics
                time
                json
            }
        }
    }`;

function TelemetryViewHeader(props) {
    const cookies = new Cookies();
    const cookieName = "tmViewCfg_" + props.telemetry.id;

    const [viewConfig, setViewConfig] = useState(cookies.get(cookieName));
    const [headValue, setHeadValue] = useState(null);

    function viewCfgValue(key, defaultValue) {
        if (!viewConfig) {
            return defaultValue;
        }
        if (!viewConfig[key]) {
            return defaultValue;
        }
        return viewConfig[key];
    }

    function changeVisibility() {
        const visible = !viewCfgValue('visible', false);
        const newViewConfig = {...viewConfig, ...{visible: visible}};
        cookies.set(cookieName, newViewConfig);
        setViewConfig(newViewConfig);
    }

    useQuery(
        GQL_QUERY_LAST_VALUE,
        {
            variables: {
                deviceUuid: props.deviceUuid,
                id: props.telemetry.id
            },
            onCompleted: onQueryCompleted
        }
    );

    function updateHeadValue(newData) {
        const notify = _.isNil(headValue);
        setHeadValue(newData);
        if (notify) {
            props.onFirstDataArrived();
        }
    }

    function onQueryCompleted(data) {
        if (!data
         || !data.getDevice
         || !data.getDevice.telemetry
         || !isArrayNonEmpty(data.getDevice.telemetry))
            return;

        updateHeadValue(data.getDevice.telemetry[0]);
    }

    function onSubscriptionData(telemetryId, data) {
        // console.log(`TelemetryViewHeader.onSubscriptionData - ${JSON.stringify(data)}`)
        if (Array.isArray(data)) {
            if (isArrayNonEmpty(data)) {
                updateHeadValue(data[data.length - 1]);
                return;
            }
            console.log("WARNING: subscription data is empty!");
            return;
        }

        if (data.__typename && data.__typename === "Telemetry") {
            updateHeadValue(data);
            return;
        }

        console.log("ERROR: unknown data type!");
        console.log(data);
    }

    function renderTelemetry() {
        if (!props.deviceInfo) {
            return (<div className="StandardText">Loading...</div>);
        }
        return (
            <TelemetryView
                    key={"telemetry_view_" + props.telemetry.id}
                    deviceUuid={props.deviceUuid}
                    telemetryConfig={props.telemetryConfig}
                    telemetry={props.telemetry}
                    deviceInfo={props.deviceInfo}
                    isVisible={viewCfgValue('visible', false)}
                />
        );
    }

    function hasData() {
        if (!headValue) return false;
        if (!headValue.time) return false;
        return true;
    }

    function stringifyHeadValue() {
        if (!hasData()) {
            return (
                <div className="StandardText" align="right">NO DATA</div>
            );
        }

        const date = new Date(headValue.time);
        const timeStr = getDisplayTimestampLocal(date);

        return (
            <div>
                <p className="StandardText" align="right">{timeStr}</p>
            </div>
        );
    }

    function renderExpandButton() {
        if (viewCfgValue('visible', false))
            return (<FaCaretSquareUp/>);
        return (<FaCaretSquareDown/>);
    }

    function renderData() {
        if (!props.showEmpty && !hasData()) {
            return null
        }
        return (
            <div>
                <h2 className={hasData() ? "Telemetry-h2" : "Telemetry-h2-no-data"} style={{paddingTop: "0px", paddingBottom: "0px"}}>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td align='right' width="0%">
                                    <button className="image-button icon-telemetry-collapse-expand" onClick={changeVisibility}>
                                        {renderExpandButton()}
                                    </button>
                                </td>
                                <td style={{wordWrap: "nowrap", whiteSpace: "nowrap"}}>[{props.telemetry.id}] {props.telemetry.name}</td>
                                <td width="100%">{stringifyHeadValue()}</td>
                            </tr>
                        </tbody>
                    </table>
                </h2>
                {renderTelemetry()}
            </div>
        )
    }

    console.log(`TelemetryViewHeader(${props.telemetry.id}) - hasData()=${hasData()}`);

    return (
        <div>
            <TelemetryDataSubscription
                key={"telemetry_sub_" + props.telemetry.id}
                deviceUuid={props.deviceUuid}
                telemetry={props.telemetry}
                onSubscriptionData={onSubscriptionData}
            />
            {renderData()}
        </div>
    );
}

export default TelemetryViewHeader;