import React, {useState} from 'react';
import AppSidebar from './AppSidebar';
import MainPane from './MainPane';
import { v4 as uuidv4 } from 'uuid';
import { getLoggedOutState } from './Utils';

function updateStringInLocalStorage(name, val) {
    if (typeof val === 'string') {
        console.log(`updateStringInLocalStorage() - saving ["${name}"] = "${val}"`);
        localStorage.setItem(name, val);
    }
    else {
        console.log(`updateStringInLocalStorage() - removing ["${name}"]`);
        localStorage.removeItem(name);
    }
}

function AppLayout() {
    // console.log("AppLayout()");

    const [loginState, setLoginState] = useState({
        appUuid: localStorage.getItem("appUuid") || uuidv4(),
        // server: "94.177.227.107",
        // server: "swdev3.softjoys.com",
        email: localStorage.getItem("email") || '',
        token: localStorage.getItem("token") || null
    });
    const [telemetryConfig, setTelemetryConfig] = useState(null);
    const [accountInfo, setAccountInfo] = useState(null);

    function onLoginStateChanged(newLoginState) {
        console.log("AppLayout.onLoginStateChanged()");
        // Object.entries(newLoginState).forEach(([key, val]) => {console.log(`  [${key}] = ${val}`)});
        setLoginState(newLoginState);
        updateStringInLocalStorage('email', newLoginState.email);
        updateStringInLocalStorage('token', newLoginState.token);
    }

    function onTelemetryConfigLoaded(newTelemetryConfig) {
        console.log("AppLayout.onTelemetryConfigLoaded()")
        setTelemetryConfig(newTelemetryConfig);
    }

    function doLogout() {
        console.log("AppLayout.doLogout()");
        setTelemetryConfig(null);
        setAccountInfo(null);
        localStorage.clear();
        onLoginStateChanged(getLoggedOutState(loginState));
    }

    function onAccountInfoChanged(newAccountInfo) {
        console.log("AppLayout.onAccountInfoChanged()");
        setAccountInfo(newAccountInfo);
    }

    return (<div className="App">
        <AppSidebar loginState={loginState} doLogout={doLogout} accountInfo={accountInfo}/>
        <MainPane
            loginState={loginState}
            onLoginStateChanged={onLoginStateChanged}
            doLogout={doLogout}
            telemetryConfig={telemetryConfig}
            onTelemetryConfigLoaded={onTelemetryConfigLoaded}
            accountInfo={accountInfo}
            onAccountInfoChanged={onAccountInfoChanged}
        />
    </div>);
}

export default AppLayout;