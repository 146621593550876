import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import TelemetryViewHeader from './TelemetryViewHeader';
import DeviceInteractor from './Device/DeviceInteractor';
import DeviceConfigurationView from './Device/DeviceConfigurationView';
import { extractErrorInfo } from './Utils';
import _ from 'lodash';

// TODO:
//   1. Device information may be already loaded with account, but only in case if we are looking at an own device.
//      In the case we are looking at someone's device as an admin this module requires to load device information with DeviceInteractor.
//      TODO: try lookup a device info in the account info first.

function DeviceTelemetryView(props) {

    console.log("DeviceTelemetryView, INFO: properties");
    console.log(props);

    const [state, setState] = useState({
        // interaction result
        actionResult:    null,
        error: null,

        // in-progress interaction
        doReloadInfo:    true,
        doApplyConfig:   false,

        // interaction data
        deviceInfo:      null,
        configChangeset: null
    });
    const [refresh, setRefresh] = useState(0);

    function changeState(changeset) {
        setState({...state, ...changeset});
    }

    const cookies = new Cookies();
    const cookieName = "tmViewCfg";
    const [viewConfig, setViewConfig] = useState(cookies.get(cookieName));

    function viewCfgValue(key, defaultValue) {
        if (!viewConfig) {
            return defaultValue;
        }
        if (!viewConfig[key]) {
            return defaultValue;
        }
        return viewConfig[key];
    }

    function isShowEmpty() {
        return viewCfgValue("showEmpty", false);
    }

    function onShowEmpty() {
        let newValue = !isShowEmpty();
        console.log(`DeviceTelemetryView.onShowEmpty() newValue=${newValue}`);
        let newViewConfig = {...viewConfig, ...{showEmpty: newValue}};
        cookies.set(cookieName, newViewConfig, { path: '/' });
        setViewConfig(newViewConfig);
    }
/*
    // DOOMan: Just a single telemetry for debugging
    const info = props.telemetryConfig.Telemetry.find(nfo => nfo.id === 5);
    const elements = [
        (<TelemetryView
            key={info.id}
            deviceUuid={device.uuid}
            telemetryConfig={props.telemetryConfig}
            telemetry={info}
        />)
    ];
    // const elements = [];
*/

    function onFirstDataArrived() {
        console.log(`DeviceTelemetryView.onFirstDataArrived()`);
        setRefresh(refresh+1);
    }

/* --- [TELEMETRY ELEMENTS] ----------------------------------------------- */
    const elements = props.telemetryConfig.Telemetry.map((info) => (
        // (info.id < 65000 && (info.id < 2 || info.id > 4) && (info.id < 6 || info.id > 9) ) &&
            <div key={"telemetry_view_header_div_" + info.id}>
                <TelemetryViewHeader
                    key={"telemetry_view_header_" + info.id}
                    deviceUuid={props.deviceUuid}
                    telemetryConfig={props.telemetryConfig}
                    telemetry={info}
                    deviceInfo={state.deviceInfo}
                    showEmpty={isShowEmpty()}
                    onFirstDataArrived={onFirstDataArrived}
                />
            </div>
        )
    );
/* ------------------------------------------------------------------------ */

    function renderSimpleProp(name, val) {
        return (
            <tr key={"prop_"+name}>
                <td className="Account-prop-name-td">{name}</td>
                <td className="Account-prop-value-td">{val}</td>
            </tr>
        );
    }

    function renderObservers() {
        if (!state.deviceInfo.observers || state.deviceInfo.observers.length <= 0)
            return null;

        const observers = state.deviceInfo.observers.map((observer) => (
            <tr key={"observer_" + observer.email}>
                <td>
                    {observer.email}
                </td>
            </tr>
        ));

        return (
            <div>
                <h3 className="Account-h3">Observers</h3>
                <table>
                    <tbody>
                        {observers}
                    </tbody>
                </table>
            </div>
        );
    }

    // When user changes config
    function onConfigurationChanged(changeset) {
        console.log("DeviceTelemetryView, INFO: Userinput new device config.");
        changeState({doApplyConfig: true, configChangeset: changeset});
    }

    function renderDeviceInfo() {
        if (state.deviceInfo) {
            let owner = 
                (state.deviceInfo.owners && state.deviceInfo.owners.length > 0)
                    ? state.deviceInfo.owners[0].email
                    : "Not Owned";

            return (
                <div>
                    <h2 className="Account-h3">View Device</h2>
                    <table>
                        <tbody>
                            {renderSimpleProp('id', state.deviceInfo.id)}
                            {renderSimpleProp('Name', state.deviceInfo.displayName)}
                            {renderSimpleProp('Phone', state.deviceInfo.phone)}
                            {renderSimpleProp('Owner', owner)}
                        </tbody>
                    </table>
                    {renderObservers()}
                    <DeviceConfigurationView
                        // view data
                        actionInProgress={state.doApplyConfig || state.doReloadInfo}
                        actionResult={state.actionResult}
                        deviceInfo={state.deviceInfo}
                        loginState={props.loginState}
                        // callback
                        onConfigurationChanged={onConfigurationChanged}
                    />
                </div>
            );
        }
        if (state.actionResult && state.actionResult !== "OK") {
            return (
                <div>
                    <h1 className="errorText">ERROR: Fail to load the device data!</h1>
                    { !_.isEmpty(state.error) && !_.isEmpty(state.error.message)
                        ? state.error.message
                        : 'Please, try refresh the page.'
                    }
                </div>
            );
        }

        return (
            <h1>Loading...</h1>
        );
    }

    function onGQLMutation_DeviceChangeConfig_Completed(data) {
        console.log("DeviceTelemetryView, INFO: device config was changed");
        console.log(data);
        changeState({
            doReloadInfo: true,
            doApplyConfig: false,
            configChangeset: null,
            actionResult: "OK"
        });
    }

    function onGQLMutation_DeviceChangeConfig_Error(err) {
        console.log("DeviceTelemetryView, ERROR: device config was NOT changed");
        console.log(err);
        changeState({
            doApplyConfig: false,
            configChangeset: null,
            actionResult: "ERROR",
            error: extractErrorInfo(err)
        });
    }

    function onGQLQuery_GetDevice_Completed(data) {
        console.log("DeviceTelemetryView, INFO: device info received");
        console.log(data);
        changeState({
            doReloadInfo: false,
            deviceInfo: data,
            actionResult: "OK"
        });
    }

    function onGQLQuery_GetDevice_Error(err) {
        console.log("DeviceTelemetryView, ERROR: fail to get device info");
        console.log(err);
        changeState({
            doReloadInfo: false,
            deviceInfo: null,
            actionResult: "ERROR",
            error: extractErrorInfo(err)
        });
    }

    return (
        <div>
            {renderDeviceInfo()}
            <DeviceInteractor
                deviceUuid={props.deviceUuid}

                action={{
                    reloadInfo: state.doReloadInfo,
                    applyConfig: state.doApplyConfig,
                    configChangeset: state.configChangeset
                }}

                onGQLQuery_GetDevice_Completed={onGQLQuery_GetDevice_Completed}
                onGQLQuery_GetDevice_Error={onGQLQuery_GetDevice_Error}

                onGQLMutation_DeviceChangeConfig_Completed={onGQLMutation_DeviceChangeConfig_Completed}
                onGQLMutation_DeviceChangeConfig_Error={onGQLMutation_DeviceChangeConfig_Error}
            />
            { state.deviceInfo ?
                <div>
                    <label>
                        <input type={"checkbox"} checked={isShowEmpty()} onChange={onShowEmpty}/>
                        Show empty telemetry
                    </label>
                </div>
                : null
            }
            {elements}
        </div>
    );
}

export default DeviceTelemetryView;