import React from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

const GQL_MUTATION_OBSERVE_REQUEST_CANCEL = gql`
  mutation ObserveRequestCancel($uuid: String!) {
    deviceObserveCancel(uuid: $uuid) {
      message
    }
  }
`;

function DeviceObserveOutgoingRequest(props) {
    const [cancel, cancelResult] =
        useMutation(
            GQL_MUTATION_OBSERVE_REQUEST_CANCEL,
            {
                variables: {
                    uuid: props.request.deviceUuid,
                }
            }
        );
    function canCancelRequest() {
        return (
            !cancelResult.called ||
            (
                !cancelResult.loading &&
                cancelResult.error
            )
        ) ? true : false
    }

    function onCancel(e) {
        e.preventDefault();
        if (canCancelRequest()) {
            cancel();
        }
    }

    const canCancel = canCancelRequest();

    return (
        <span>
            {props.request.displayName} ({props.request.deviceUuid})
            {(canCancel && (<button onClick={onCancel}>Cancel</button>)) || (cancelResult.loading && (<UseAnimations animation={loading} />))}
        </span>
    );
}

export default DeviceObserveOutgoingRequest;