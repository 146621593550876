import React, { useEffect, useState, createRef } from 'react';
import { Line } from 'react-chartjs-2';
import { getQueryTimestampLocal } from './TelemetryDataLoader';
import { MapContainer, TileLayer, FeatureGroup, Marker, Popup, Polyline, CircleMarker, useMap } from 'react-leaflet';
import "leaflet/dist/leaflet.css";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { FaGlobeEurope, FaDrawPolygon } from 'react-icons/fa';
import { isArrayNonEmpty, parseJSON } from './Utils';

import SafeZonesManagerMap from './SafeZone/SafeZonesManagerMap';

import _ from 'lodash';

import { interpolatePlasma, interpolateInferno } from "d3-scale-chromatic";

const onlineLabels = ['<unknown>', 'offline', 'connected', 'online'];
const onlineValues = {
    offline: 1,
    connected: 2,
    online: 3,
};

const L = require("leaflet");

const DefaultIcon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

function TelemetryMapAutoFit(props) {
    const map = useMap();
    useEffect(
        () => {
            if (map) {
                let featureGroup
                map.eachLayer((layer) => {
                    if (layer.options && !Object.keys(layer.options).length && !layer._rootGroup) {
                        featureGroup = layer;
                    }
                    /*
                    const nvps = Object.entries(layer).map(([key, value]) => {
                        if (key === 'options') {
                            return key + '={' + Object.entries(value).map(([k, v]) => `${k} = ${v}`).join(',') + '}';
                        }
                        return `${key} = ${value}`;
                    });
                    console.log("TelemetryMapAutoFit.useEffect() - " + nvps.join(','));
                    */
                    return undefined;
                });
                if (featureGroup) {
                    console.log("TelemetryMapAutoFit.useEffect() - fitting bounds...");
                    map.fitBounds(featureGroup.getBounds());
                }
                else {
                    console.log("TelemetryMapAutoFit.useEffect() - featureGroup not found!");
                }
            }
        },
        [map, props.telemetryData]
    );
    return null;
}

function getArrChartData(props) {
    if (!isArrayNonEmpty(props.telemetryData)) {
        return undefined;
    }

    switch (props.telemetry.type) {
        case 'arrInt':
        case 'arrUInt':
        case 'arrReal':
            break;
        default:
            return undefined;
    }

    let dataFiltered =
        props.telemetryData.map(
            (measurement) => {return {..._.cloneDeep(measurement), value: parseJSON(measurement.json)}}
        ).filter(
            (measurement) => isArrayNonEmpty(measurement.value)
        );

    if (!isArrayNonEmpty(dataFiltered)) {
        return undefined;
    }

    let maxArraySize = Math.max(...dataFiltered.map((measurement) => measurement.value.length));

    if (!maxArraySize) {
        return undefined;
    }

    let label = props.telemetry.name;
    if (props.telemetry.units) {
        label += ', ' + props.telemetry.units;
    }

    return {
        data: {
            datasets: [...Array(maxArraySize).keys()].map((i) => { return {
                    label: `[${i}] ${label}`,
                    fill: false,
                    lineTension: 0,
                    backgroundColor: interpolateInferno(i/maxArraySize),
                    borderColor: interpolatePlasma(i/maxArraySize),
                    // borderWidth: 2,
                    data: dataFiltered.filter((measurement) => _.isNumber(measurement.value[i])).map((measurement) => {return {t: getQueryTimestampLocal(measurement.time), y: +measurement.value[i]};})
                }
            })
        },
        options: {
            maintainAspectRatio: false,
            title: {
                display: false, // true,
                text: props.telemetry.name,
                fontSize:20
            },
            legend:{
                display:true,
                position:'right'
            },
            scales: {
                xAxes: [{
                    type: 'time'
                }]
            },
            animation: {
                duration: 0 // general animation time
            },
            hover: {
                animationDuration: 0 // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0 // animation duration after a resize
        },
        height: 250
    };

}

function TelemetryArrayChartView(props) {
    var element = null;
    var chartData = getArrChartData(props);
    if (chartData) {
        element = (
            <div className="chart-container">
                <Line
                    height={chartData.height}
                    data={chartData.data}
                    options={chartData.options}
                />
            </div>
        );
    }

    return element;
}

function TelemetryChartView(props) {
    // console.log("TelemetryChartView()");
    const [mapBounds, setMapBounds] = useState(null);
    const allObjectsMapLayer = createRef();
    const trackOnlyMapLayer = createRef();

    function getChartData(telemetry) {
        var data;
        function getDataAsEvents() {
            return telemetry.map((measurement) => {return {t: getQueryTimestampLocal(measurement.time), y: measurement.json ? 1 : 0};})
        }
        function getDataAsValues() {
            return telemetry.map((measurement) => {return {t: getQueryTimestampLocal(measurement.time), y: +measurement.json};})
        }
        function getDataAsBoolean() {
            return telemetry.map((measurement) => {return {t: getQueryTimestampLocal(measurement.time), y: (measurement.json === 'true') ? 1 : 0};})
        }
        function getDataAsOnline() {
            return telemetry.map((measurement) => {
                let value = parseJSON(measurement.json);
                const y = onlineValues[value ? value : 'offline']; // FIXME
                return {t: getQueryTimestampLocal(measurement.time), y: y ? y : 0}
            });
        }
        function getDataAsVector3() {
            return telemetry.map((measurement) => {
                let value = parseJSON(measurement.json);
                let vectorLength = 0
                if (isArrayNonEmpty(value) && value.length === 3) {
                    vectorLength = Math.sqrt(value[0]*value[0] + value[1]*value[1] + value[2]*value[2]);
                }
                return {t: getQueryTimestampLocal(measurement.time), y: vectorLength}
            });
        }

        var options = {
            maintainAspectRatio: false,
            title: {
                display: false, // true,
                text: props.telemetry.name,
                fontSize:20
            },
            legend:{
                display:true,
                position:'right'
            },
            scales: {
                xAxes: [{
                    type: 'time'
                }]
            },
            animation: {
                duration: 0 // general animation time
            },
            hover: {
                animationDuration: 0 // duration of animations when hovering an item
            },
            responsiveAnimationDuration: 0 // animation duration after a resize
        };

        function setupOptionsForOnline(opts) {
            opts.scales.yAxes = [{
                ticks: {
                    callback: (value) => onlineLabels[value]
                }
            }];
        }

        function setupOptionsForSOS(opts) {
            opts.scales.yAxes = [{
                ticks: {
                    callback: (value) => (value === 1 ? "SOS" : (value === 0 ? "Safe" : null))
                }
            }];
        }

        function setupOptionsForBoolean(opts) {
            opts.scales.yAxes = [{
                ticks: {
                    callback: (value) => (value === 1 ? "true" : (value === 0 ? "false" : null))
                }
            }];
        }

        function setupOptionsForEvents(opts) {
            opts.scales.yAxes = [{
                ticks: {
                    callback: (value) => (value === 1 ? "event" : null)
                }
            }];
        }

        let height = 150;

        switch (props.telemetry.type) {
            case 'uint':
                if (props.telemetry.semantics && props.telemetry.semantics === 'sos') {
                    data = getDataAsEvents();
                    setupOptionsForSOS(options);
                    height = 150;
                    break;
                }
                // fallthrough
            case 'int': // fallthrough
            case 'real':
                data = getDataAsValues();
                height = 350;
                break;
            case 'bool':
                data = getDataAsBoolean();
                setupOptionsForBoolean(options);
                height = 150;
                break;
            case 'onlineStatus':
                data = getDataAsOnline();
                setupOptionsForOnline(options);
                height = 150;
                break;
            case 'arrReal':
                // FIXME - need semantics for accelerometer or 3-vectors in general
                if (props.telemetry.name === 'Accelerometer') {
                    data = getDataAsVector3();
                    height = 250;
                    break;
                }
                // fallthrough
            default:
                data = getDataAsEvents();
                setupOptionsForEvents(options);
                height = 150;
        }
        let label = props.telemetry.name;
        if (props.telemetry.units) {
            label += ', ' + props.telemetry.units;
        }
        return {
            data: {
                datasets: [
                    {
                        label: label,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: 'rgba(0,0,255,1)',
                        borderColor: 'rgba(192,192,255,1)',
                        // borderWidth: 2,
                        data: data
                    }
                ]
            },
            options: options,
            height: height
        };
    }

    function getMapData() {
        let mapData = {positions: [], popups: []};
        for(let n = 0; n < props.telemetryData.length; n++) {
            const json = JSON.parse(props.telemetryData[n].json);
            if (
                typeof json.latitude === 'number' && !isNaN(json.latitude) &&
                typeof json.longitude === 'number' && !isNaN(json.longitude)
            ) {
                const timestamp = getQueryTimestampLocal(props.telemetryData[n].time);
                mapData.positions.push([json.latitude, json.longitude]);
                mapData.popups.push(
                    <div>
                        <h3>[{n+1}] {timestamp}</h3>
                        <table>
                            <tbody>
                                {Object.entries(json).map(([key, value]) => (<tr key={key}><td className="map-tooltip-prop-name">{key}</td><td>{value}</td></tr>))}
                            </tbody>
                        </table>
                    </div>
                );
            }
        }
        return mapData;
    }

    function fitAllObjects() {
        if (allObjectsMapLayer.current) {
            setMapBounds(allObjectsMapLayer.current.getBounds())
        }
    }

    function fitTrack() {
        if (trackOnlyMapLayer.current) {
            setMapBounds(trackOnlyMapLayer.current.getBounds())
        }
    }

    function onAutoZoom() {
        queueMicrotask(() => {setMapBounds(null)});
    }

    function AutoZoom(args) {
        const map = useMap();
        if (args.bounds && args.bounds.isValid()) {
            map.fitBounds(args.bounds, {padding: [5, 5]});
            args.onZoom()
        }
        return null;
    }

    var element;

    if (isArrayNonEmpty(props.telemetryData)) {
        var chartData = getChartData(props.telemetryData);
        const points = chartData.data.datasets[0].data;
        if (isArrayNonEmpty(points)) {
            // console.log("TelemetryChartView() - " + points.length + " points");
            let elementMap;
            if (props.telemetry.type === 'pos') {
                const mapData = getMapData();
                if (isArrayNonEmpty(mapData.positions)) {
                    const pathOptions = {color: 'blue'};
                    const pointOptions = {color: 'blue'};
                    var elementsPath = [];
                    for (let n = 0; n < mapData.positions.length; n++) {
                        if (n) {
                            elementsPath.push((
                                <CircleMarker
                                        key={mapData.positions.length - n}
                                        center={mapData.positions[n]}
                                        pathOptions={pointOptions}
                                        radius={5}
                                    >
                                        <Popup>{mapData.popups[n]}</Popup>
                                </CircleMarker>
                            ));
                        }
                        else {
                            elementsPath.push((
                                <Marker
                                    key={mapData.positions.length - n}
                                    position={mapData.positions[n]}
                                >
                                    <Popup>{mapData.popups[n]}</Popup>
                                </Marker>
                            ));
                        }
                    }
                    elementMap = (<div id="mapid">
                        <MapContainer
                            center={mapData.positions[0]}
                            zoom={13}
                            scrollWheelZoom={false}
                        >
                            <AutoZoom bounds={mapBounds} onZoom={onAutoZoom}/>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <FeatureGroup ref={allObjectsMapLayer}>
                                <SafeZonesManagerMap
                                    safeZones={
                                        isArrayNonEmpty(props.deviceInfo.safeZones)
                                            ? props.deviceInfo.safeZones
                                            : []
                                    }
                                    devices={[props.deviceInfo]}
                                />
                                <FeatureGroup ref={trackOnlyMapLayer}>
                                    <Polyline pathOptions={pathOptions} positions={mapData.positions}/>
                                    {elementsPath}
                                </FeatureGroup>
                            </FeatureGroup>
                            <TelemetryMapAutoFit telemetryData={props.telemetryData}/>
                        </MapContainer>
                        <div className="map-controls">
                            <button className="map-button icon-big" title="Show all!" onClick={fitAllObjects}>
                                <FaGlobeEurope/>
                            </button><span> </span>
                            <button className="map-button icon-big" title="Show track!" onClick={fitTrack}>
                                <FaDrawPolygon/>
                            </button>
                        </div>
                    </div>);
                }
            }
            element = (
                <div>
                    <div>Displaying {points.length} points from {new Date(points[points.length - 1].t).toLocaleString()} to {new Date(points[0].t).toLocaleString()}</div>
                    <div className="chart-container">
                        <Line
                            height={chartData.height}
                            data={chartData.data}
                            options={chartData.options}
                        />
                    </div>
                    {elementMap}
                    <TelemetryArrayChartView telemetry={props.telemetry} telemetryData={props.telemetryData}/>
                </div>    
            );
            // ref={chartRef}
        }
        else {
            element = (<div>No data</div>);
        }
    }
    else {
        element = (<div>No data (null)</div>);
    }

    return element;
}

export default TelemetryChartView;