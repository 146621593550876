import { gql } from 'apollo-boost';
import CommonQuery from '../Utils/CommonQuery';
import CommonMutation from '../Utils/CommonMutation';
import React from 'react';

function GQLQuery_GetWiFiList() {
    return gql`
    query WiFiList($id: Int!) {
        getWifiList(id: $id) {
            id
            displayName
            wifiSpots {
                id
                ssid
              }
        }
    }`;
}

function GQLMutation_CreateWiFiList() {
    return gql`
        mutation CreateWiFiList($displayName: String!) {
            createWifiList(displayName: $displayName) {
                id
                displayName
            }
        }`;
}

function GQLMutation_DeleteWiFiList() {
    return gql`
        mutation DeleteWiFiList($id: Int!) {
            deleteWifiList(id: $id) { message }
        }`;
}

function GQLMutation_AttachWifiListToDevice() {
    return gql`
        mutation AttachWifiListToDevice($wifiListId: Int!, $deviceUuid: String!) {
            attachWifiListToDevice(wifiListId: $wifiListId, deviceUuid: $deviceUuid) { message }
        }`;
}

function GQLMutation_DetachWifiListFromDevice() {
    return gql`
        mutation DetachWifiListFromDevice($wifiListId: Int!, $deviceUuid: String!) {
            detachWifiListFromDevice(wifiListId: $wifiListId, deviceUuid: $deviceUuid) { message }
        }`;
}

function GQLMutation_AddWifiSpot() {
    return gql`
        mutation AddWifiSpotToList($wifiListId: Int!, $ssid: String!, $password: String!) {
            addWifiSpotToList(wifiListId: $wifiListId, ssid: $ssid, password: $password) { id ssid }
        }`;
}

function GQLMutation_RemoveWifiSpot() {
    return gql`
        mutation RemoveWifiSpotFromList($wifiListId: Int!, $id: Int!) {
            removeWifiSpotFromList(wifiListId: $wifiListId, id: $id) { message }
        }`;
}

function WiFiListsInteractor(props) {

    console.log("WiFiListsInteractor, INFO: properties");
    // console.log(props);

    if (!props.action ||
        (!props.action.get &&
         !props.action.create &&
         !props.action.delete &&
         !props.action.attach &&
         !props.action.detach &&
         !props.action.addSpot &&
         !props.action.removeSpot)
    ) {
        console.log("WiFiListsInteractor: INFO: no any action required");
        return null;
    }

    //---------------------------------------------------------------------------------
    // CREATE WIFI LIST
    function GQL_createWiFiList() {
        if (!props.action.create) { return null; }

        if (!props.action.create.displayName) {
            console.log("ERROR: invalid args, no displayName supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_CreateWiFiList;
        args.mutationArgs = props.action.create;
        args.onCompleted  = props.onCreateCompleted;
        args.onError      = props.onCreateError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // DELETE WIFI LIST
    function GQL_deleteWiFiList() {
        if (!props.action.delete) { return null; }

        if (!props.action.delete.id) {
            console.log("ERROR: invalid args, no wifi list id is supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_DeleteWiFiList;
        args.mutationArgs = props.action.delete;
        args.onCompleted  = props.onDeleteCompleted;
        args.onError      = props.onDeleteError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // ATTACH OR DETACH WIFI LIST
    function GQL_attachOrDetachWiFiList() {
        var in_args = null;
        do {
            if (props.action.attach) { in_args = props.action.attach; break; }
            if (props.action.detach) { in_args = props.action.detach; break; }
            return null;
        } while (false);

        if (!in_args.id) {
            console.log("ERROR: invalid args, no wifi list id is supplied!");
            return null;
        }

        if (!in_args.deviceUuid) {
            console.log("ERROR: invalid args, no device uuid is supplied!");
            return null;
        }

        var args = {};
        args.mutation     = props.action.attach ? GQLMutation_AttachWifiListToDevice : GQLMutation_DetachWifiListFromDevice;
        args.mutationArgs = { wifiListId: in_args.id,
                              deviceUuid: in_args.deviceUuid };
        args.onCompleted  = props.action.attach ? props.onAttachCompleted : props.onDetachCompleted;
        args.onError      = props.action.attach ? props.onAttachError : props.onDetachError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // ADD WIFI SPOT
    function GQL_addWiFiSpot() {
        if (!props.action.addSpot) { return null; }

        if (!props.action.addSpot.wifiListId) {
            console.log("ERROR: invalid args, no wifiListId supplied!");
            return null;
        }

        if (!props.action.addSpot.ssid) {
            console.log("ERROR: invalid args, no ssid supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_AddWifiSpot;
        args.mutationArgs = props.action.addSpot;
        args.onCompleted  = props.onAddSpotCompleted;
        args.onError      = props.onAddSpotError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // REMOVE WIFI SPOT
    function GQL_removeWiFiSpot() {
        if (!props.action.removeSpot) { return null; }

        if (!props.action.removeSpot.wifiListId) {
            console.log("ERROR: invalid args, no wifiListId supplied!");
            return null;
        }

        if (!props.action.removeSpot.id) {
            console.log("ERROR: invalid args, no id supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_RemoveWifiSpot;
        args.mutationArgs = props.action.removeSpot;
        args.onCompleted  = props.onRemoveSpotCompleted;
        args.onError      = props.onRemoveSpotError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // QUERY WIFI LIST INFO
    function GQL_getWiFiList() {
        if (!props.action.get) { return null; }

        var args = {};
        args.queryArgs = {};
        args.query       = GQLQuery_GetWiFiList;
        args.queryArgs   = props.action.get;
        args.onCompleted = props.onGetCompleted;
        args.onError     = props.onGetError;

        return (
            <CommonQuery {...args}/>
        );
    }

    return (
        <div>
            {GQL_createWiFiList()}
            {GQL_deleteWiFiList()}
            {GQL_attachOrDetachWiFiList()}
            {GQL_getWiFiList()}
            {GQL_addWiFiSpot()}
            {GQL_removeWiFiSpot()}
        </div>
    );
}

export default WiFiListsInteractor;