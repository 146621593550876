import React from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { FaEyeSlash } from 'react-icons/fa';
import { isArrayNonEmpty, account_isAdmin } from './Utils';
import { confirmAlert } from 'react-confirm-alert';

const GQL_MUTATION_OBSERVER_DROP = gql`
  mutation ObserverDrop($email: String!, $uuid: String!) {
    deviceObserveCancel(email: $email, uuid: $uuid) {
      message
    }
  }
`;

function ObserverDrop(props) {
    console.log("ObserverDrop");
    // console.log(props);
    const [drop, dropResult] =
        useMutation(
            GQL_MUTATION_OBSERVER_DROP,
            {
                variables: {
                    email: props.observer.email,
                    uuid: props.device.uuid,
                }
            }
        );
    function canDropRequest() {
        return (
            !dropResult.called ||
            (
                !dropResult.loading &&
                dropResult.error
            )
        ) ? true : false
    }

    function onDrop(e) {
        e.preventDefault();
        if (canDropRequest()) {
            confirmAlert({
                overlayClassName: "alert-overlay",
                title: 'Deny to observe your device',
                message: 'Are you sure you want to deny ' + props.observer.email + ' observe your device?',
                buttons: [{ label: 'Yes', onClick: () => drop() },
                          { label: 'No', onClick: () => {} }]
              });
        }
    }

    function renderDropButton() {
        let owner = isArrayNonEmpty(props.device.owners) ? props.device.owners[0] : null;

        if ( (owner && owner.email === props.accountInfo.email)
          || props.accountInfo.email === props.observer.email
          || account_isAdmin(props.accountInfo)
        ) {
            return (<button className="image-button Red" title="Stop observing!" onClick={onDrop}><FaEyeSlash/></button>);
        }
        return null;
    }

    const canDrop = canDropRequest();

    return (
        <span>
            {(canDrop && (renderDropButton())) || (dropResult.loading && (<UseAnimations animation={loading} />))}
        </span>
    );
}

export default ObserverDrop;