import React from 'react';
// import ReactDOM from 'react-dom';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppLayout from './AppLayout'

function App() {
    // console.log("App()");
    return (
        <BrowserRouter>
            <AppLayout/>
        </BrowserRouter>
  );
}

export default App;