import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { gql } from 'apollo-boost';
import TelemetryDataLoader from './TelemetryDataLoader';
import TelemetryDataSubscription from './TelemetryDataSubscription';
import TelemetryChartView from './TelemetryChartView';
import TelemetryValueView from './TelemetryValueView';
import TelemetryTableView from './TelemetryTableView';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import alertCircle from 'react-useanimations/lib/alertCircle';
import { isArrayNonEmpty, parseJSON } from './Utils';
import _ from 'lodash';

const PAGE_SIZE_POINTS=900

const Range = Slider.createSliderWithTooltip(Slider.Range);

const GQL_QUERY_HISTORY_INFO = gql`
    query TelemetryHistoryInfo($deviceUuid: String!, $id: Int!) {
        getDevice(uuid: $deviceUuid) {
            id
            deviceTelemetryHistoryInfo(id: $id) {
                id
                first
                last
            }
        }
    }`;

function renderTelemetryProp(name, val) {
    return (
        <tr>
            <td className="Telemetry-prop-name-td">{name}</td>
            <td className="Telemetry-prop-value-td">{val}</td>
        </tr>
    );
}

function getInitialPoints(telemetry) {
    if (!telemetry) {
        return 10
    }
    // FIXME - need semantics for accelerometer or 3-vectors in general
    if (telemetry.name === 'Accelerometer') {
        return 250
    }
    return 10
}

const c_initialTelemetryTextAreaSize = {
    'bool': {cols: 7, rows: 1},
    'int': {cols: 20, rows: 1},
    'uint': {cols: 20, rows: 1},
    'real': {cols: 30, rows: 1},
    'text': {cols: 80, rows: 1},
    'binary': {cols: 80, rows: 1},
    'onlineStatus': {cols: 12, rows: 1},
    'pos': {cols: 50, rows: 10},
    'arrBool': {cols: 10, rows: 4},
    'arrInt': {cols: 20, rows: 4},
    'arrUInt': {cols: 20, rows: 4},
    'arrBinary': {cols: 80, rows: 4},
    'arrText': {cols: 80, rows: 4},
    'arrReal': {cols: 30, rows: 4},
    'wifiScan': {cols: 81, rows: 15},
    'wifiActive': {cols: 81, rows: 13},
    'gsmScan': {cols: 30, rows: 13},
    'gsmActive': {cols: 30, rows: 11},
    'error': {cols: 80, rows: 4}
}

function getInitialTelemetryTextAreaSize(telemetry) {
    let size = c_initialTelemetryTextAreaSize[telemetry.type]

    size = size ? _.cloneDeep(size) : {cols: 30, rows: 1}

    if (!telemetry) {
        return size
    }

    switch (telemetry.type) {
        case 'text':
            if (telemetry.semantics === 'nmea') {
                size.rows = 7
            }
            break;
        case 'arrText':
            if (telemetry.semantics === 'nmea') {
                size.rows = 9
            }
            break
        case 'arrReal':
            // FIXME - need semantics for accelerometer or 3-vectors in general
            if (telemetry.name === 'Accelerometer') {
                size.rows = 5
             }
            break
        default:
            break
    }
    return size
}

function TelemetryView(props) {
    console.log("TelemetryView()");
    // console.log(props);

    const [isVisible, setIsVisible] = useState(props.isVisible);
    const [telemetryRange, setTelemetryRange] = useState(null);
    const [viewRange, setViewRange] = useState(null);
    const [viewTelemetryData, setViewTelemetryData] = useState(null);
    const [recentValue, setRecentValue] = useState(null);

    /*
    console.log(
        "TelemetryView()\n  telemetryRange=" + JSON.stringify(telemetryRange) +
        "\n  viewRange=" + JSON.stringify(viewRange) +
        "\n  viewTelemetryData=" + (viewTelemetryData ? viewTelemetryData.length + " points" : "null") +
        "\n  recentValue=" + JSON.stringify(recentValue)
    );
    */

    const queryResult = useQuery(
            GQL_QUERY_HISTORY_INFO,
            {
                // client: props.client,
                variables: {
                    deviceUuid: props.deviceUuid,
                    id: props.telemetry.id
                },
                onCompleted: onQueryCompleted,
                // onError: onQueryError,
                notifyOnNetworkStatusChange: true
            }
        );

    if (isVisible !== props.isVisible) {
        if (props.isVisible === true) {
            queryResult.refetch();
        }
        setIsVisible(props.isVisible);
    }

    function updateRecentValue(jsonString) {
        // console.log("TelemetryView.updateRecentValue() - " + jsonString);
        let value = parseJSON(jsonString);
        setRecentValue(value);
    }

    function updateTelemetryRange(telemetry) {
        const t = new Date(telemetry.time).getTime();
        /*
        console.log(
            "TelemetryView.updateTelemetryRange(" + t + ")" +
            "\n  telemetryRange=" + JSON.stringify(telemetryRange) +
            "\n  viewRange=" + JSON.stringify(viewRange) +
            "\n  viewTelemetryData=" + (viewTelemetryData ? viewTelemetryData.length + " points" : "null") +
            "\n  recentValue=" + JSON.stringify(recentValue)
        );
        */
        let newTelemetryRange;

        if (telemetryRange) {
            if (t > telemetryRange.max) {
                newTelemetryRange = {
                    min: telemetryRange.min,
                    max: t
                };
                updateRecentValue(telemetry.json);
            }
            else if (t < telemetryRange.min) {
                newTelemetryRange = {
                    min: t,
                    max: telemetryRange.max
                };
            }
            else if (t === telemetryRange.max) {
                updateRecentValue(telemetry.json);
            }
            else {
                // console.log("TelemetryView.updateTelemetryRange() - already in range!");
            }
        }
        else {
            newTelemetryRange = {
                min: t,
                max: t
            };
            updateRecentValue(telemetry.json);
        }
        if (newTelemetryRange) {
            setTelemetryRange(newTelemetryRange);
        }
        return newTelemetryRange ? newTelemetryRange : telemetryRange;
    }

    function isTailView() {
        if (!viewRange) {
            // console.log("TelemetryView.isTailView() - false (no viewRange)");
            return false;
        }
        if (!telemetryRange) {
            // console.log("TelemetryView.isTailView() - false (no telemetryRange)");
            return false;
        }
        if (viewRange.max !== telemetryRange.max) {
            // console.log("TelemetryView.isTailView() - false (" + viewRange.max + " <> " + telemetryRange.max + ")");
            return false;
        }
        // console.log("TelemetryView.isTailView() - true");
        return true;
        // return viewRange && telemetryRange && (viewRange.max === telemetryRange.max);
    }

    function addTelemetryPoint(newTelemetryPoint) {
        console.log(`TelemetryView.addTelemetryPoint(): ${JSON.stringify(newTelemetryPoint)}`)

        if (!newTelemetryPoint) {
            return;
        }
        const tailView = isTailView();
        const newTelemetryRange = updateTelemetryRange(newTelemetryPoint);
        if (viewRange && tailView) {
            // console.log(`TelemetryView.addTelemetryPoint - viewTelemetryData: ${viewTelemetryData ? viewTelemetryData.length + " points" : "null"}, non-empty: ${isArrayNonEmpty(viewTelemetryData)}, greater: ${newTelemetryRange.max > viewRange.max}, viewRange: ${JSON.stringify(viewRange)}, newTelemetryRange: ${JSON.stringify(newTelemetryRange)}, adjusting tail view...`)
            let minTimestamp = viewRange.min;
            if (
                isArrayNonEmpty(viewTelemetryData) &&
                viewTelemetryData.length >= PAGE_SIZE_POINTS &&
                newTelemetryRange.max > viewRange.max
            ) {
                // Scroll if the number of displayed points is more than props.pageSize to prevent performance degradation
                minTimestamp += (newTelemetryRange.max - viewRange.max)
                console.log(`TelemetryView.addTelemetryPoint - scrolling by ${newTelemetryRange.max - viewRange.max}`)
            }
            updateViewRange({
                min: minTimestamp,
                max: newTelemetryRange.max
            });
        }
    }

    function onSubscriptionData(telemetryId, data) {
        // console.log(`TelemetryView.onSubscriptionData(${telemetryId}): ${JSON.stringify(data)}`);
        /*
        console.log(
            `TelemetryView.onSubscriptionData(${telemetryId}): ${JSON.stringify(data)}`  +
            "\n  telemetryRange=" + JSON.stringify(telemetryRange) +
            "\n  viewRange=" + JSON.stringify(viewRange) +
            "\n  viewTelemetryData=" + (viewTelemetryData ? viewTelemetryData.length + " points" : "null") +
            "\n  recentValue=" + JSON.stringify(recentValue)
        );
        */
        if (props.onSubscriptionData) {
            props.onSubscriptionData(
                telemetryId,
                data
            );
        }
        if (isVisible) {
            addTelemetryPoint(data);
        }
    }

    function onQueryCompleted(data) {
        console.log(
            "TelemetryView.onQueryCompleted() - data=" + JSON.stringify(data) +
            "\n  telemetryRange=" + JSON.stringify(telemetryRange) +
            "\n  viewRange=" + JSON.stringify(viewRange) +
            "\n  viewTelemetryData=" + (viewTelemetryData ? viewTelemetryData.length + " points" : "null") +
            "\n  recentValue=" + JSON.stringify(recentValue)
        );
        console.assert(viewRange === null);
        function convertTelemetryHistoryInfoToRange(info) {
            return {
                min: new Date(info.first).getTime(),
                max: new Date(info.last).getTime()
            };
        }
        function unionRanges(r1, r2) {
            return ({
                min: Math.min(r1.min, r2.min),
                max: Math.max(r1.max, r2.max)
            });
        }
        if (
            data &&
            data.getDevice &&
            data.getDevice.deviceTelemetryHistoryInfo
        ) {
            const info = data.getDevice.deviceTelemetryHistoryInfo;
            if (info.first && info.last) {
                const range = convertTelemetryHistoryInfoToRange(data.getDevice.deviceTelemetryHistoryInfo);
                setTelemetryRange(telemetryRange ? unionRanges(telemetryRange, range) : range);    
            }
        }
    }

    function isQueryCompletedSuccessfully() {
        return queryResult.called && !queryResult.loading;
    }

    function updateViewRange(newRange, force) {
        /*
        console.log(
            "TelemetryView.updateViewRange() - newRange=" + JSON.stringify(newRange) +
            "\n  telemetryRange=" + JSON.stringify(telemetryRange) +
            "\n  viewRange=" + JSON.stringify(viewRange) +
            "\n  viewTelemetryData=" + (viewTelemetryData ? viewTelemetryData.length + " points" : "null") +
            "\n  recentValue=" + JSON.stringify(recentValue)
            * /
        );
        */
        // console.log("TelemetryView.updateViewRange() - newRange=" + JSON.stringify(newRange));
        setViewRange(newRange);
    }

    function rangeFromBounds(newBounds) {
        let newRange;
        if (newBounds[0] > newBounds[1]) {
            newRange = {
                min: newBounds[1],
                max: newBounds[0]
            };
        }
        else {
            newRange = {
                min: newBounds[0],
                max: newBounds[1]
            };
        }
        return newRange;
    }

    function onViewRangeChanging(newBounds) {
        setViewRange(rangeFromBounds(newBounds));
    }

    function onViewRangeChanged(newBounds) {
        console.log("TelemetryView.onViewRangeChanged() - " + JSON.stringify(newBounds));
        updateViewRange(rangeFromBounds(newBounds), true);
    }

    function getDataRange(data) {
        if (data && data.length) {
            return ({
                min: new Date(data[data.length - 1].time).getTime(),
                max: new Date(data[0].time).getTime()
            });
        }
        return null;
    }

    function onViewTelemetryDataChanged(newData) {
        console.log("TelemetryView.onViewTelemetryDataChanged()" /* - " + JSON.stringify(newData) */);
        setViewTelemetryData(newData);
        if (!viewRange) {
            setViewRange(getDataRange(newData));
        }
    }

    function onMoveStart() {
        console.log("TelemetryView.onMoveStart() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        updateViewRange({
            min: telemetryRange.min,
            max: telemetryRange.min + (viewRange.max - viewRange.min)
        });
    }

    function onExpandLeft() {
        console.log("TelemetryView.onExpandLeft() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        let interval=viewRange.max - viewRange.min;
        if (!interval) {
            interval = 100;
        }
        let t = viewRange.min - interval;
        if (t < telemetryRange.min) {
            t = telemetryRange.min;
        }
        updateViewRange({
            min: t,
            max: viewRange.max
        });
    }

    function onPageLeft() {
        console.log("TelemetryView.onPageLeft() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        const interval=viewRange.max - viewRange.min;
        let t = viewRange.min - interval;
        if (t < telemetryRange.min) {
            t = telemetryRange.min;
        }
        updateViewRange({
            min: t,
            max: t + interval
        });
    }

    function onZoomIn() {
        console.log("TelemetryView.onZoomIn() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        const interval=viewRange.max - viewRange.min;
        let min = Math.round(viewRange.min + interval / 4);
        let max = Math.round(viewRange.max - interval / 4);
        if (min < max) {
            updateViewRange({
                min: min,
                max: max
            });
        }        
    }

    function onAllData() {
        console.log("TelemetryView.onAllData() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        updateViewRange({
            min: telemetryRange.min,
            max: telemetryRange.max
        });
    }

    function onReset() {
        console.log("TelemetryView.onReset() - viewRange=" + JSON.stringify(viewRange));
        if (!telemetryRange) {
            return;
        }
        setViewRange(null)
        setViewTelemetryData(null)
    }

    function onZoomOut() {
        console.log("TelemetryView.onZoomOut() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        const interval=viewRange.max - viewRange.min;
        let min = Math.round(viewRange.min - interval / 2);
        if (min < telemetryRange.min) {
            min = telemetryRange.min;
        }
        let max = Math.round(viewRange.max + interval / 2);
        if (max > telemetryRange.max) {
            max = telemetryRange.max;
        }
        updateViewRange({
            min: min,
            max: max
        });        
    }

    function onPageRight() {
        console.log("TelemetryView.onPageRight() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        const interval=viewRange.max - viewRange.min;
        let t = viewRange.max + interval;
        if (t > telemetryRange.max) {
            t = telemetryRange.max;
        }
        updateViewRange({
            min: t - interval,
            max: t
        });
    }

    function onExpandRight() {
        console.log("TelemetryView.onExpandRight() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        let interval=viewRange.max - viewRange.min;
        if (!interval) {
            interval = 100;
        }
        let t = viewRange.max + interval;
        if (t > telemetryRange.max) {
            t = telemetryRange.max;
        }
        updateViewRange({
            min: viewRange.min,
            max: t
        });
    }

    function onMoveEnd() {
        console.log("TelemetryView.onMoveEnd() - viewRange=" + JSON.stringify(viewRange));
        if (!viewRange || !telemetryRange) {
            return;
        }
        updateViewRange({
            min: telemetryRange.max - (viewRange.max - viewRange.min),
            max: telemetryRange.max
        });
    }

    function onValueReceived(telemetryPoint) {
        // console.log("TelemetryView.onValueReceived() - " + JSON.stringify(telemetryPoint));
        addTelemetryPoint(telemetryPoint);
        updateRecentValue(telemetryPoint.json);
    }

    var elementStatus;

    if (!isQueryCompletedSuccessfully()) {
        elementStatus = (<div className="progressText"><UseAnimations animation={loading} />Fetching ({queryResult.networkStatus})...</div>);
    }
    else if (queryResult.error) {
        elementStatus = (<div className="errorText"><UseAnimations animation={alertCircle} />{'Error: ' + queryResult.error}</div>);
    }

    var elementRangeView;
    var maxDate

    if (telemetryRange) {
        const minDate=new Date(telemetryRange.min);
        maxDate=new Date(telemetryRange.max);
        const elementRange = (
            <Range
                min={telemetryRange.min}
                max={telemetryRange.max}
                // step={1}
                // defaultValue={[viewRange.min, viewRange.max]}
                value={viewRange ? [viewRange.min, viewRange.max] : [telemetryRange.max, telemetryRange.max]}
                // pushable={true}
                disabled={!viewRange || viewRange.min === viewRange.max}
                tipFormatter={t => (new Date(t)).toLocaleString()}
                onChange={onViewRangeChanging}
                onAfterChange={onViewRangeChanged}
            />);
        elementRangeView = (
            <div className="Centered-table-container">
                <table width="95%">
                    <tbody>
                        <tr>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>{minDate.toLocaleString()}</td>
                                            <td width="80%">{elementRange || <p>Rendering chart...</p>}</td>
                                            <td>{maxDate.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <button onClick={onMoveStart}>{'<< Start'}</button>
                                                <button onClick={onExpandLeft}>{'<+ Expand Left'}</button>
                                                <button onClick={onPageLeft}>{'< Page Left'}</button>
                                                <button onClick={onZoomIn}>{'Zoom In (+)'}</button>
                                                <button onClick={onAllData}>{'All Data'}</button>
                                                <button onClick={onReset}>{'Reset'}</button>
                                                <button onClick={onZoomOut}>{'Zoom Out (-)'}</button>
                                                <button onClick={onPageRight}>{'Page Right >'}</button>
                                                <button onClick={onExpandRight}>{'Expand Right +>'}</button>
                                                <button onClick={onMoveEnd}>{'End >>'}</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <TelemetryDataLoader
                                    deviceUuid={props.deviceUuid}
                                    telemetryConfig={props.telemetryConfig}
                                    telemetry={props.telemetry}
                                    viewRange={viewRange}
                                    maxInitialPoints={getInitialPoints(props.telemetry)}
                                    pageSize={PAGE_SIZE_POINTS}
                                    onTelemetryDataChanged={onViewTelemetryDataChanged}
                                />
                                <TelemetryChartView
                                    deviceUuid={props.deviceUuid}
                                    telemetryConfig={props.telemetryConfig}
                                    telemetry={props.telemetry}
                                    telemetryData={viewTelemetryData}
                                    deviceInfo={props.deviceInfo}
                                />
                                <TelemetryTableView
                                    telemetry={props.telemetry}
                                    telemetryData={viewTelemetryData}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    let elementTelemetryView = null;

    if (isVisible) {
        const recentValuePretty = _.isNil(recentValue) ? '' : JSON.stringify(recentValue, null, 2)
        const textAreaSize = getInitialTelemetryTextAreaSize(props.telemetry)
        elementTelemetryView = (
            <div>
                <table>
                    <tbody>
                        {renderTelemetryProp('Description', props.telemetry.Description)}
                        {renderTelemetryProp('Updated', maxDate ? maxDate.toLocaleString() : '-')}
                        {renderTelemetryProp('Value',
                            (<div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {(_.isNil(recentValue) && _.isNil(maxDate))? '-' : (
                                                    <textarea rows={textAreaSize.rows} cols={textAreaSize.cols} readOnly={1} value={recentValuePretty} className={'no-horz-scroller'}/>
                                                )}
                                            </td>
                                            <td>
                                                {telemetryRange && <TelemetryValueView
                                                    deviceUuid={props.deviceUuid}
                                                    telemetryConfig={props.telemetryConfig}
                                                    telemetry={props.telemetry}
                                                    timestamp={maxDate}
                                                    value={recentValue}
                                                    onValueReceived={onValueReceived}
                                                />}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>)
                        )}
                    </tbody>
                </table>
                <h3>History</h3>
                {elementStatus || elementRangeView || <p>No telemetry data</p>}
            </div>
        );
    }

    return (
        <div>
            <TelemetryDataSubscription
                key={"telemetry_view_sub_" + props.telemetry.id}
                deviceUuid={props.deviceUuid}
                telemetry={props.telemetry}
                onSubscriptionData={onSubscriptionData}
            />
            {elementTelemetryView}
        </div>
    )
}

export default TelemetryView;