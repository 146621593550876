import React, {useState} from 'react';
import { extractErrorInfo, account_isAdmin } from '../Utils';

// AccountInfoView - view
import AccountInfoView from '../AccountInfoView';

// AccountInfoLoader - interactor
import AccountInfoLoader from '../AccountInfoLoader';

// controller/presenter
function AdminViewAccountController(props) {

    console.log("AdminViewAccountController");
    console.log(props);

    const [accountInfo, setAccountInfo] = useState(null);
    const [loadingError, setLoadingError] = useState(null);

    function clearError() {
        if (!loadingError) {return}
        setLoadingError(null);
    }

    function onUpdated(accountInfo) {
        console.log("AdminViewAccountController.onUpdated()");
        console.log(accountInfo);
        clearError();
        setAccountInfo(accountInfo);
    }

    function onDisconnected() {
        console.log("AdminViewAccountController.onDisconnected()");
        clearError();
    }

    function renderAccount() {
        if (!accountInfo)
            return (<p>Loading...</p>);
        return (<AccountInfoView accountInfo={accountInfo} foreign={true}/>);
    }

    function onLoadAccountError(err) {
        setLoadingError(extractErrorInfo(err));
    }

    function renderPage() {
        return (
            <div>
                <AccountInfoLoader
                    loginState={props.loginState}
                    adminViewAccountEmail={props.adminViewAccountEmail}
                    onAccountInfoChanged={onUpdated}
                    onLoginStateChanged={onDisconnected}
                    telemetryConfig={props.telemetryConfig}
                    onError={onLoadAccountError}
                />
                { loadingError
                    ?   <h2>ERROR: {loadingError}</h2>
                    :   renderAccount()
                }
            </div>
        )
    }
    
    return (
        <div>
            { !account_isAdmin(props.accountInfo)
                ?   <h2>ERROR: ACCESS DENIED</h2>
                :   renderPage()
            }
        </div>
    );
}

export default AdminViewAccountController;