import React, { useState, useRef } from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { useHistory } from 'react-router-dom';
import { createApolloClient, closeClient, extractErrorInfo } from './Utils';

const GQL_MUTATION_CONFIRM_ACCOUNT = gql`
mutation ConfirmAccount($code: String!, $email: String!) {
    confirmAccount(code: $code, email: $email) {
      message
    }
}`;

function AccountConfirmView(props) {
    // console.log("AccountConfirmView");

    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const clientRef = useRef(props.loginState.client);
    const history = useHistory();

    if (clientRef.current) {
        if (props.loginState.client) {
            if (clientRef.current !== props.loginState.client) {
                closeClient(clientRef.current);
                clientRef.current = props.loginState.client;
            }
        }
    }
    else {
        clientRef.current = props.loginState.client || createApolloClient(props.loginState);
    }

    const [confirmAccount, confirmAccountResult] =
        useMutation(
          GQL_MUTATION_CONFIRM_ACCOUNT,
            {
                client: clientRef.current,
                onCompleted: onCompleted,
                onError: (err) => console.log("AccountConfirmView.onError() - err=" + err)
            }
        );

    function onCompleted(data) {
          // console.log("AccountConfirmView.onCompleted() - data=" + JSON.stringify(data));
          if (data && data.confirmAccount && data.confirmAccount.message) {
              history.push('/');
          }
          else {
              console.log("AccountConfirmView.onCompleted() - signup failed");
          }
    }

    function onCodeChanged(event) {
        setCode(event.target.value);
        setError("");
    }

    function onClickConfirm(event) {
        event.preventDefault();
        startConfirmation();
    }

    function startConfirmation() {
        const msgErr = getConfirmationNotReadyCause();
        if (msgErr !== '') {
            console.log("AccountConfirmView.startConfirmation() - not starting: " + msgErr);
            setError(msgErr);
            return;
        }
        console.log("AccountConfirmView.startConfirmation()");
        confirmAccount({
            variables: {
                code: code.trim(),
                email: props.loginState.email,
            }
        });
    }

    function getConfirmationNotReadyCause() {
        if (confirmAccountResult.loading) {
            return "Creating account...";
        }
        if (code.trim() === '') {
            return "Confirmation code can't be empty";
        }
        return '';
    }

    if (confirmAccountResult.loading) {
        return (<div className="Login-screen">
            <div>Confirming account...</div>
            <UseAnimations animation={loading} />
        </div>);
    }

    let elementError;

    if (error) {
        elementError = (<p className="errorText">{error}</p>);
    }
    else if (confirmAccountResult.error) {
        const errorInfo = extractErrorInfo(confirmAccountResult.error);
        elementError = errorInfo.message && (<div className="centered-column">
            <p className="errorText">{errorInfo.message}</p>
            <p className="progressText">{errorInfo.error_debug}</p>
        </div>);
    }
    return (
        <div className="Login-screen">
            <p>Email sent to {props.loginState.email} with the confirmation code.<br/>Please enter this code below and click 'Confirm'.</p>
            <form onSubmit={onClickConfirm}>
                <table>
                    <tbody>
                        {typeof props.loginState.server === 'string' && props.loginState.server && (
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="server">Server</label></td>
                                <td>{props.loginState.server}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="Login-form-table-label-td"><label htmlFor="code">Confirmation code</label></td>
                            <td><input name="code" value={code} onChange={onCodeChanged} className="Login-form-table-input"/></td>
                        </tr>
                        <tr>
                            <td colSpan={2}><button type="submit">Confirm</button></td>
                        </tr>
                    </tbody>
                </table>
            </form>
            {elementError}
        </div>
    );
}

export default AccountConfirmView;