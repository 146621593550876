import React, {useState} from 'react';
import { account_isAdmin, account_roles, relURL } from '../Utils';
import { useQuery } from 'react-apollo';
import { gql } from 'apollo-boost';
import { Link } from 'react-router-dom';
import _ from 'lodash';

const GQL_ADMIN_ACCOUNTS = gql`
query AdminAccounts($email: String!) {
    accounts(email: $email) {
        id
        displayName
        email
        phone
        devices {
            id
        }
        observes {
            id
        }
        roles {
            role
        }
    }
}`;

function QueryAccounts(props) {

    function onComplete(data) { props.onComplete(data); }
    function onError(err) { props.onError(err); }

    useQuery(
        GQL_ADMIN_ACCOUNTS,
        {
            variables: {
                email: ""
            },
            client: props.client,
            onCompleted: onComplete,
            onError: onError,
            notifyOnNetworkStatusChange: true
        }
    );

    return (
        <div>
            <p>Loading...</p>
        </div>
    )
}

function AdminViewAccountsTable(props) {

    const [accounts, setAccountsData] = useState(null);
    const [error, setError] = useState(null);

    function onAccountsInfo(data) {
        if (_.isEmpty(data) || _.isEmpty(data.accounts)) {
            setAccountsData([]);
            setError(null);
            return;
        }

        setAccountsData(data.accounts);
        setError(null);
    }

    function onError(err) {
        console.log("onError, err:" + err);
        setError(err);
    }

    if (!account_isAdmin) {
        return (
            <div>
                <h2>! ACCESS DENIED !</h2>
            </div>
        );
    }

    function renderError() {
        if (!error) return;
        return (
            <h2>ERROR: {error}</h2>
        );
    }

    function queryAccounts() {
        return (
            <QueryAccounts
                    client={props.loginState.client}
                    onComplete={onAccountsInfo}
                    onError={onError}
                />
        );
    }

    function formatDevicesInfo(account) {
        var devicesCount = (account.devices) ? account.devices.length : 0;
        var observesCount = (account.observes) ? account.observes.length : 0;
        var str = "" + devicesCount;
        if (observesCount > 0) {
            str += " + " + observesCount;
        }
        return str; 
    }

    function renderAccount(account) {
        return (<tr key={account.id}>
            <td className="Account-table-td" align="center">{account.id}</td>
            <td className="Account-table-td">{account_roles(account)}</td>
            <td className="Account-table-td">{account.displayName}</td>
            <td className="Account-table-td"><Link key={account.id} to={relURL({page: 'admin', admin: 'account', email: account.email})}>{account.email}</Link></td>
            <td className="Account-table-td">{account.phone}</td>
            <td className="Account-table-td">{formatDevicesInfo(account)}</td>
        </tr>);
    }

    function renderTotal(keyPart) {
        return (
            <tr key={"total" + keyPart}>
                <td className="Account-table-th" colSpan="6" align="right"><b>Total:</b> {accounts.length}</td>
            </tr>
        );
    }

    function renderAccountsTable() {
        if (!accounts || !accounts.length) {
            return;
        }
        return (
            <div>
                <table width="100%" className="Account-table">
                    <thead>
                        <tr>
                            <th className="Account-table-th" align="center">ID</th>
                            <th className="Account-table-th">Roles</th>
                            <th className="Account-table-th">Name</th>
                            <th className="Account-table-th">EMail</th>
                            <th className="Account-table-th">Phone</th>
                            <th className="Account-table-th">Devices</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTotal("Pre")}
                        {accounts.map((account) => renderAccount(account))}
                        {renderTotal("Post")}
                    </tbody>
                </table>
            </div>
        );
    }

    function render() {
        if (!account_isAdmin(props.accountInfo)) {
            return (
                <div>
                    <h2>ERROR: ACCESS DENIED</h2>
                </div>
            )
        }

        if (!accounts)
            return queryAccounts();

        return (
            <div>
                {renderError()}
                {renderAccountsTable()}
            </div>
        );
    }

    return (
        <div className="Account-screen">
            <h3 className="Account-h3">Accounts</h3>
            {render()}
        </div>
    );
}

export default AdminViewAccountsTable;
