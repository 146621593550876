import React, { useState, useRef, useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { gql } from 'apollo-boost';
// import { NetworkStatus } from 'apollo-client';
/*
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import alertCircle from 'react-useanimations/lib/alertCircle';
*/
import { isArrayNonEmpty } from './Utils';

const GQL_QUERY_CURRENT_VALUE = gql`
    query TelemetryCurrentValue($deviceUuid: String!, $id: Int!) {
        getDevice(uuid: $deviceUuid) {
            id
            telemetry(id: $id, limit: 1) {
                id
                time
                json
            }
        }
    }`;

function TelemetryValueView(props) {
    const [timestamp, setTimestamp] = useState(null);
    const [value, setValue] = useState(props.value);
    const completed = useRef(true);
/*
    console.log(
        "TelemetryValueView()" +
        "\n  completed=" + completed.current +
        "\n  props.timestamp=" + (props.timestamp ? props.timestamp.getTime() : props.timestamp) + " (timestamp=" + (timestamp ? timestamp.getTime() : timestamp) + ")" +
        "\n  props.value=" + JSON.stringify(props.value) + " (value=" + JSON.stringify(value) + ")"
    );
*/
    function setCompleted(b) {
        completed.current = b;
    }

    const [runQuery, queryResult] =
        useLazyQuery(
            GQL_QUERY_CURRENT_VALUE,
            {
                variables: {
                    deviceUuid: props.deviceUuid,
                    id: props.telemetry.id
                },
                notifyOnNetworkStatusChange: true,
                onCompleted: (data) => {
                    // console.log("TelemetryValueView.onCompleted() - " + JSON.stringify(data));
                    setCompleted(true);
                    if (
                        data &&
                        data.getDevice &&
                        data.getDevice.telemetry
                    ) {
                        const telemetry = queryResult.data.getDevice.telemetry;
                        if (isArrayNonEmpty(telemetry)) {
                            setTimestamp(new Date(telemetry[0].time));
                            setValue(JSON.parse(telemetry[0].json));
                            props.onValueReceived(telemetry[0]);
                        }
                        else {
                            setTimestamp(new Date(props.timestamp)); // workaround: to stop requesting in endless loop if server doesn't provide stale data
                        }
                    }
                },
                onError: (...args) => {
                    console.log("TelemetryValueView.onError() - " + JSON.stringify(args));
                    setCompleted(true);
                },
            }
        );

    useEffect(
        () => {
            if (
                completed.current &&
                (
                    (props.value === null) &&
                    (
                        (timestamp === null) ||
                        (props.timestamp.getTime() !== timestamp.getTime())
                    )
                )
            ) {
                if (queryResult.called && queryResult.error) {
                    console.log("TelemetryValueView.useEffect() - not starting due to previous error to workaround the crash");
                }
                else {
                    // console.log(`TelemetryValueView.useEffect() - restarting query... (props.timestamp=${props.timestamp}, timestamp=${timestamp}`);
                    setCompleted(false);
                    try {
                        (queryResult.called && !queryResult.error) ? queryResult.refetch() : runQuery();
                    }
                    catch(e) {
                        console.log("TelemetryValueView.useEffect() - !!! exception: " + e);
                        setCompleted(true);
                    }
                }
            }
        },
        [runQuery, queryResult, props.timestamp, timestamp, props.value, value ]
    );

    if (props.value === null) {
        let elementFetchStatus;
        let elementError
        if (!completed.current/* !queryResult.called /* || queryResult.loading */) {
            // elementFetchStatus = (<span className="progressText"><UseAnimations animation={loading} />{'Fetching (' + queryResult.networkStatus + ')...'}</span>);
            elementFetchStatus = (<span className="progressText">{`Fetching (${queryResult.networkStatus})...`}</span>);
        }
        else if (queryResult.error) {
            // elementError = (<span className="errorText"><UseAnimations animation={alertCircle} />{'Failed to fetch: ' + queryResult.error}</span>);
            elementError = (<span className="errorText">{`Failed to fetch: ${queryResult.error}`}</span>);
        }
        // return (<div>{props.timestamp.toLocaleString()}<br/>{elementFetchStatus || elementError || (<strong><u>{JSON.stringify(value, null, 1)}</u></strong>)}</div>);
        return elementFetchStatus || elementError || null;
    }
    else {
        return null; // (<div>{props.timestamp.toLocaleString()}<br/><strong><u>{JSON.stringify(props.value, null, 1)}</u></strong></div>);
    }
}

export default TelemetryValueView;