import React, { useRef, useEffect } from 'react';
import { NetworkStatus } from 'apollo-client';
import { useLazyQuery, useSubscription } from 'react-apollo';
import { gql } from 'apollo-boost';
import { getLoggedOutState, getPositionTelemetryID } from './Utils';

function gqlQueryAccount(query = "myAccount", coordsQuery) {
    return gql`
    query MyAccount {
        ${query} {
            id
            email
            displayName
            phone
            roles {
                role
            }
            devices {
                id
                uuid
                displayName
                phone
                warningLowBattery
                sosId
                sosStartedTime
                sosNotificationTime
                sticky
                owned
                debug
                ${coordsQuery}
                deviceConfig {
                    batteryCriticalLevel
                    callsAllowAcceptAll
                    dataUploadInterval
                    sosDataUploadInterval
                    observeAutoAccept
                }
                owners {
                    id
                    email
                    displayName
                    phone
                }
                observers {
                    id
                    email
                    displayName
                    phone
                }
                phonesLists {
                    id
                    displayName
                    phones {
                        id
                        phone
                        displayName
                        sosCall
                        sosSms
                    }
                }
                safeZones {
                    id
                    displayName
                    type
                    latitude
                    longitude
                    radius
                    alertTimeout
                    violated
                    distance
                }
                wifiLists {
                    id
                    displayName
                    wifiSpots {
                        id
                        ssid
                    }
                }
            }
            observes {
                id
                uuid
                displayName
                phone
                warningLowBattery
                sosId
                sosStartedTime
                sosNotificationTime
                sticky
                owned
                debug
                ${coordsQuery}
                deviceConfig {
                    batteryCriticalLevel
                    callsAllowAcceptAll
                    dataUploadInterval
                    sosDataUploadInterval
                    observeAutoAccept
                }
                owners {
                    id
                    email
                    displayName
                    phone
                }
                observers {
                    id
                    email
                    displayName
                    phone
                }
                phonesLists {
                    id
                    displayName
                    phones {
                        id
                        phone
                        displayName
                        sosCall
                        sosSms
                    }
                }
                safeZones {
                    id
                    displayName
                    type
                    latitude
                    longitude
                    radius
                    alertTimeout
                    violated
                    distance
                }
                wifiLists {
                    id
                    displayName
                    wifiSpots {
                        id
                        ssid
                    }
                }
            }
            phonesLists {
                id
                displayName
                phones {
                    id
                    phone
                    displayName
                    sosCall
                    sosSms
                }
            }
            safeZones {
                id
                displayName
                type
                latitude
                longitude
                radius
                alertTimeout
            }
            wifiLists {
                id
                displayName
                wifiSpots {
                    id
                    ssid
                }
            }
            incomingRequests {
                deviceUuid
                requestorEmail
                requestorName
                requestorPhone
            }
            outgoingRequests {
                deviceUuid
                displayName
            }
        }
    }`;
}

// dd00: Why do re-query myAccount on subscription update?
//       All required fields can be inserted into subscription.
function gqlSubscribeAccount(query = "accountUpdated") {
    return gql`
    subscription AccountSubscription {
        ${query} {
            id
        }
    }`;
 }

function AccountInfoLoader(props) {

    console.log("AccountInfoLoader()");
    const refetchRequired = useRef(false);

    const positionTelemetry = getPositionTelemetryID(props)
    const coordsQuery = positionTelemetry
        ?  `coordinates: telemetry(id: ${positionTelemetry.id}, limit: 1) { json }`
        : "";

    
    function updateMyAccount(myAccount) {
        console.log("AccountInfoLoader.updateMyAccount()");
        props.onAccountInfoChanged(myAccount);
    }

    function logout() {
        console.log("AccountInfoLoader.logout()");
        props.onLoginStateChanged(getLoggedOutState(props.loginState, true));
    }

    function onCompleted(data) {
        if (refetchRequired.current) {
            refetchRequired.current = false;
            restartQuery();
        }
        else if (
            data &&
            (data.myAccount || data.accounts)
        ) {
            let accountData = data.myAccount ? data.myAccount : data.accounts;
            if (Array.isArray(accountData))
                 updateMyAccount(accountData[0]);
            else updateMyAccount(accountData);
        }
        else {
            console.log("AccountInfoLoader.onCompleted() - strange response");
            console.log(data);
            logout();
        }
    }

    function onError(err) {
        console.log("AccountInfoLoader.onError()");
        refetchRequired.current = false;
        if (props.onError) {props.onError(err)}
        if (props.adminViewAccountEmail) {return}
        logout();
    }

    let query = props.adminViewAccountEmail
        ? "accounts(email: \"" + props.adminViewAccountEmail + "\")"
        : "myAccount";
    const [runQuery, queryResult] =
        useLazyQuery(
            gqlQueryAccount(query, coordsQuery),
            {
                client: props.loginState.client,
                notifyOnNetworkStatusChange: true,
                onCompleted: onCompleted,
                onError: onError,
                errorPolicy: 'all'
            }
        );

    let subscription = props.adminViewAccountEmail
        ? "accountUpdated(email: \"" + props.adminViewAccountEmail + "\")"
        : "accountUpdated";
    const sub =
        useSubscription(
            gqlSubscribeAccount(subscription),
            {
                client: props.loginState.client,
                onSubscriptionData: onSubscriptionData
            }
        );
/*
    console.log(
        "AccountInfoLoader()\n" +
        "  props.loginState = " + dumpObj(props.loginState) + "\n" +
        "  queryResult = " + dumpObj(queryResult) + "\n" +
        "  queryResult.error = " + JSON.stringify(queryResult.error)
    );
*/
    function onSubscriptionData(data) {
        console.log("AccountInfoLoader.onSubscriptionData()");
        restartQuery();
    }

    function restartQuery() {
        if (queryResult.networkStatus !== NetworkStatus.ready && queryResult.networkStatus !== NetworkStatus.error) {
            console.log("AccountInfoLoader.restartQuery() - not staring: networkStatus=" + queryResult.networkStatus );
            refetchRequired.current = true;
            return;
        }
        console.log("AccountInfoLoader.restartQuery() - starting query ...");
        runQuery();
    }

    function renderNetworkState() {
        return null;
        // return (
        //     <div className="progressText">AccountInfoLoader: networkStatus={queryResult.networkStatus}</div>
        // );
    }

    useEffect(
        () => {
            console.log("AccountInfoLoader.useEffect()");
            restartQuery();
        },
        [ runQuery, props.loginState ]
    );
    
    return (
        <div>
            {renderNetworkState()}
        </div>
    );
}

export default AccountInfoLoader;