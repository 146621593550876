import { gql } from 'apollo-boost';
import CommonQuery from '../Utils/CommonQuery';
import CommonMutation from '../Utils/CommonMutation';
import React from 'react';

import _ from 'lodash';

function GQLQuery_GetPhoneList() {
    return gql`
    query PhoneList($id: Int!) {
        getPhonesList(id: $id) {
            id
            displayName
            phones {
                id
                displayName
                phone
                sosSms
                sosCall
            }
        }
    }`;
}

function GQLMutation_CreatePhoneList() {
    return gql`
        mutation CreatePhoneList($displayName: String!) {
            createPhonesList(displayName: $displayName) {
                id
                displayName
            }
        }`;
}

function GQLMutation_DeletePhoneList() {
    return gql`
        mutation DeletePhoneList($id: Int!) {
            deletePhonesList(id: $id) { message }
        }`;
}

function GQLMutation_AttachPhoneListToDevice() {
    return gql`
        mutation AttachPhoneListToDevice($phoneListId: Int!, $deviceUuid: String!) {
            attachPhonesListToDevice(phonesListId: $phoneListId, deviceUuid: $deviceUuid) { message }
        }`;
}

function GQLMutation_DetachPhoneListFromDevice() {
    return gql`
        mutation DetachPhoneListFromDevice($phoneListId: Int!, $deviceUuid: String!) {
            detachPhonesListFromDevice(phonesListId: $phoneListId, deviceUuid: $deviceUuid) { message }
        }`;
}

function GQLMutation_AddPhoneToList() {
    return gql`
        mutation AddPhoneToList($phoneListId: Int!, $displayName: String!, $phone: String!, $sosSms: Boolean!, $sosCall: Boolean!) {
            addPhoneToList(phonesListId: $phoneListId, displayName: $displayName, phone: $phone, sosSms: $sosSms, sosCall: $sosCall) {
                id
                displayName
                phone
                sosSms
                sosCall
            }
        }`;
}

function GQLMutation_RemovePhoneFromList() {
    return gql`
        mutation RemovePhoneFromList($phoneListId: Int!, $id: Int!) {
            removePhoneFromList(phonesListId: $phoneListId, id: $id) { message }
        }`;
}

function GQLMutation_ChangePhoneListEntry() {
    return gql`
        mutation ChangePhoneListEntry($phoneListId: Int!, $id: Int!, $displayName: String!, $phone: String!, $sosSms: Boolean!, $sosCall: Boolean!) {
            changePhoneListEntry(phonesListId: $phoneListId, id: $id, displayName: $displayName, phone: $phone, sosSms: $sosSms, sosCall: $sosCall) {
                id
                displayName
                phone
                sosSms
                sosCall
            }
        }`;
}

function PhoneListsInteractor(props) {
    if (!props.action ||
         (!props.action.get &&
          !props.action.create &&
          !props.action.delete &&
          !props.action.attach &&
          !props.action.detach &&
          !props.action.addPhone &&
          !props.action.removePhone &&
          !props.action.changePhone
         )
    ) {
        // console.log("PhoneListsInteractor: INFO: no any action required");
        return null;
    }

    //---------------------------------------------------------------------------------
    // CREATE Phone LIST
    function GQL_createPhoneList() {
        if (!props.action.create) {
            return null;
        }

        if (!_.isString(props.action.create.displayName) ||
             _.isEmpty (props.action.create.displayName)) {
            props.onCreateError("ERROR: invalid args, no displayName supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_CreatePhoneList;
        args.mutationArgs = props.action.create;
        args.onCompleted  = props.onCreateCompleted;
        args.onError      = props.onCreateError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // DELETE Phone LIST
    function GQL_deletePhoneList() {
        if (!props.action.delete) {
            return null;
        }

        if (!_.isNumber(props.action.delete.id)) {
            props.onDeleteError("ERROR: invalid args, no Phone list id is supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_DeletePhoneList;
        args.mutationArgs = props.action.delete;
        args.onCompleted  = props.onDeleteCompleted;
        args.onError      = props.onDeleteError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // ATTACH OR DETACH Phone LIST
    function GQL_attachOrDetachPhoneList() {
        var in_args = null;
        do {
            if (props.action.attach) { in_args = props.action.attach; break; }
            if (props.action.detach) { in_args = props.action.detach; break; }
            return null;
        } while (false);

        if (!_.isNumber(in_args.id)) {
            const msg = "ERROR: invalid args, no Phone list id is supplied!";
            props.action.attach ? props.onAttachError(msg) : props.onDetachError(msg);
            return null;
        }

        if (!_.isString(in_args.deviceUuid) ||
             _.isEmpty (in_args.deviceUuid)) {
            const msg = "ERROR: invalid args, no device uuid is supplied!";
            props.action.attach ? props.onAttachError(msg) : props.onDetachError(msg);
            return null;
        }

        var args = {};
        args.mutation     = props.action.attach ? GQLMutation_AttachPhoneListToDevice : GQLMutation_DetachPhoneListFromDevice;
        args.mutationArgs = { phoneListId: in_args.id,
                              deviceUuid: in_args.deviceUuid };
        args.onCompleted  = props.action.attach ? props.onAttachCompleted : props.onDetachCompleted;
        args.onError      = props.action.attach ? props.onAttachError : props.onDetachError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // ADD PHONE TO LIST
    function GQL_addPhone() {
        if (!props.action.addPhone) {
            return null;
        }

        if (!_.isNumber(props.action.addPhone.phoneListId)) {
            props.onAddPhoneError("ERROR: invalid args, wrong phoneListId supplied!");
            return null;
        }

        if (!_.isString(props.action.addPhone.displayName) ||
             _.isEmpty (props.action.addPhone.displayName)) {
            props.onAddPhoneError("ERROR: invalid args, wrong displayName supplied!");
            return null;
        }

        if (!_.isString(props.action.addPhone.phone) ||
             _.isEmpty (props.action.addPhone.phone)) {
            props.onAddPhoneError("ERROR: invalid args, no phone supplied!");
            return null;
        }

        if (!_.isBoolean(props.action.addPhone.sosSms)) {
            props.onAddPhoneError("ERROR: invalid args, wrong sosSms value supplied!");
            return null;
        }

        if (!_.isBoolean(props.action.addPhone.sosCall)) {
            props.onAddPhoneError("ERROR: invalid args, wrong sosCall value supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_AddPhoneToList;
        args.mutationArgs = props.action.addPhone;
        args.onCompleted  = props.onAddPhoneCompleted;
        args.onError      = props.onAddPhoneError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // REMOVE PHONE FROM LIST
    function GQL_removePhone() {
        if (!props.action.removePhone) {
            return null;
        }

        if (!_.isNumber(props.action.removePhone.phoneListId)) {
            props.onRemovePhoneError("ERROR: invalid args, wrong phoneListId supplied!");
            return null;
        }

        if (!_.isNumber(props.action.removePhone.id)) {
            props.onRemovePhoneError("ERROR: invalid args, wrong id supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_RemovePhoneFromList;
        args.mutationArgs = props.action.removePhone;
        args.onCompleted  = props.onRemovePhoneCompleted;
        args.onError      = props.onRemovePhoneError;

        return (
            <CommonMutation {...args}/>
        );
    }

    //---------------------------------------------------------------------------------
    // CHANGE PHONE ENTRY
    function GQL_changePhone() {
        if (!props.action.changePhone) {
            return null;
        }

        if (!_.isNumber(props.action.changePhone.phoneListId)) {
            props.onChangePhoneError("ERROR: invalid args, wrong phoneListId supplied!");
            return null;
        }

        if (!_.isNumber(props.action.changePhone.id)) {
            props.onChangePhoneError("ERROR: invalid args, wrong id supplied!");
            return null;
        }

        if (!_.isString(props.action.changePhone.displayName) ||
             _.isEmpty (props.action.changePhone.displayName)) {
            props.onChangePhoneError("ERROR: invalid args, no displayName supplied!");
            return null;
        }

        if (!_.isString(props.action.changePhone.phone) ||
             _.isEmpty (props.action.changePhone.phone)) {
            props.onChangePhoneError("ERROR: invalid args, no phone supplied!");
            return null;
        }

        if (!_.isBoolean(props.action.changePhone.sosSms)) {
            props.onChangePhoneError("ERROR: invalid args, wrong sosSms value supplied!");
            return null;
        }

        if (!_.isBoolean(props.action.changePhone.sosCall)) {
            props.onChangePhoneError("ERROR: invalid args, wrong sosCall value supplied!");
            return null;
        }

        var args = {};
        args.mutation     = GQLMutation_ChangePhoneListEntry;
        args.mutationArgs = props.action.changePhone;
        args.onCompleted  = props.onChangePhoneCompleted;
        args.onError      = props.onChangePhoneError;

        return (
            <CommonMutation {...args}/>
        );
    }    

    //---------------------------------------------------------------------------------
    // QUERY PHONE LIST INFO
    function GQL_getPhoneList() {
        if (!props.action.get) {
            return null;
        }

        var args = {};
        args.queryArgs = {};
        args.query       = GQLQuery_GetPhoneList;
        args.queryArgs   = props.action.get;
        args.onCompleted = props.onGetCompleted;
        args.onError     = props.onGetError;

        return (
            <CommonQuery {...args}/>
        );
    }

    return (
        <div>
            {GQL_createPhoneList()}
            {GQL_deletePhoneList()}
            {GQL_attachOrDetachPhoneList()}
            {GQL_getPhoneList()}
            {GQL_addPhone()}
            {GQL_removePhone()}
            {GQL_changePhone()}
        </div>
    );
}

export default PhoneListsInteractor;