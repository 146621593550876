import React, { useState } from 'react';
import { isArrayNonEmpty, getDisplayTimestampLocal, getDisplayTimestampUTC, parseJSON } from './Utils';
import JSONPretty from 'react-json-pretty';
import { CSVLink } from 'react-csv';
import * as CSV from 'csv-string';
import _ from 'lodash';
var moment = require('moment');

function timestampDiffSuffix(p, next, field) {
    if (_.isNil(p) || _.isNil(p[field]) || _.isNil(next) || _.isNil(next[field])) {
        return '';
    }
    return `(${moment(p[field]) - moment(next[field])} ms)`;// moment(p[field]) - moment(next[field]);
}

function renderTelemetryPoint(p, next) {
    return (<tr key={p.time}>
        <td className="Telemetry-table-td-timestamp"><div>{getDisplayTimestampLocal(p.time)}</div><div>{timestampDiffSuffix(p, next, 'time')}</div></td>
        <td className="Telemetry-table-td-timestamp"><div>{getDisplayTimestampUTC(p.deviceTime)}</div><div>{timestampDiffSuffix(p, next, 'deviceTime')}</div></td>
        <td className="Telemetry-table-td"><JSONPretty data={p.json}/></td>
    </tr>);
}
function csvArrayHeaderFromTelemetry(telemetry) {
    let arrHeaders = [];
    switch(telemetry.type) {
        case 'pos':
            arrHeaders = ['timestamp', 'device_ts', 'latitude', 'longitude', 'altitude', 'speed', 'course', 'accuracyH', 'accuracyV', 'provider'];
            break;
        case 'error':
            arrHeaders = ['timestamp', 'device_ts', 'code', 'info'];
            break;
        default:
            arrHeaders = ['timestamp', 'device_ts', 'value'];
            break;
    }
    return arrHeaders;
}

function stringRemoveEnclosingDoubleQuotes(str) {
    if (typeof(str) === 'string') {
        if (str.length >= 2 && str[0] === '"' && str[str.length - 1] === '"') {
            str = str.substring(1, str.length - 1);
        }
    }
    return str;
}

function csvStringFromAny(str) {
    // DOOMan: returns CSV-escaped string without enclosing double-quotes
    let result = CSV.stringify(str);
    // console.log(`csvStringFromAny - after CSV.stringify() = ${result}`);
    if (typeof(result) === 'string') {
        result = stringRemoveEnclosingDoubleQuotes(result.trim());
    }
    return result;
}

function csvStringFromJSONString(jstr) {
    // DOOMan: returns CSV-escaped string without enclosing double-quotes
    let result = '';
    if (typeof(jstr) === 'string') {
        let json = parseJSON(jstr);
        if (_.isNil(json)) {
            console.log("csvStringFromJSONString - null or undefined, replaced with blank string");
        }
        else {
            let str = (typeof(json) === 'string') ? json : JSON.stringify(json);
            // console.log(`csvStringFromJSONString - json = ${json}, str = ${str}`);
            result = csvStringFromAny(str);
        }
    }
    else {
        console.log("csvStringFromJSONString - type is " + typeof(jstr) + ", not 'string', replacing value with a blank string");
    }
    // console.log(`csvStringFromJSONString - result = ${result}`);
    return result;
}

function csvArrayFromTelemetryPoint(telemetry, p) {
    let arrResult = [];
    switch(telemetry.type) {
        case 'arrBool':
        case 'arrInt':
        case 'arrUInt':
        case 'arrReal':
        case 'arrText':
        case 'arrBinary':
            // Array types
            let arr = parseJSON(p.json);
            arrResult = [p.time, p.deviceTime, ...(isArrayNonEmpty(arr) ? arr.map((s) => csvStringFromAny(s)) : [])];
            break;
        case 'pos':
            let pos = parseJSON(p.json);
            arrResult = [
                p.time,
                p.deviceTime,
                ...(pos ? [ pos.latitude, pos.longitude, pos.altitude, pos.speed, pos.course, pos.accuracyH, pos.accuracyV, pos.provider].map((s) => csvStringFromAny(s)) : [])];
            break;
        case 'error':
            let err = parseJSON(p.json);
            arrResult = [p.time, p.deviceTime, ...(err ? [ err.code, err.info ].map((s) => csvStringFromAny(s)) : [])];
            break;
        default:
            // 'bool', 'int', 'uint', 'real', 'text', 'binary', 'onlineStatus', 'wifiScan', 'wifiActive', 'gsmScan', 'gsmActive'
            arrResult = [p.time, p.deviceTime, csvStringFromJSONString(p.json)];
            break;
    }
    // console.log(`csvArrayFromTelemetryPoint - arrResult = ${arrResult}`);
    return arrResult;
}

function TelemetryTableView(props) {
    const [collapsed, setCollapsed] = useState(true);

    if (!isArrayNonEmpty(props.telemetryData)) {
        return null;
    }

    function onToggle(e) {
        e.preventDefault();
        setCollapsed(!collapsed);
    }

    const elementCollapse = (<button onClick={onToggle}>{collapsed ? "Show Raw Telemetry" : "Hide Raw Telemetry"}</button>);

    const csvData = [
        csvArrayHeaderFromTelemetry(props.telemetry),
        ...props.telemetryData.map((measurement) => csvArrayFromTelemetryPoint(props.telemetry, measurement))
    ];

    const elementDownload = (<CSVLink data={csvData} filename={`telemetry-${props.telemetry.id}.csv`} target="_brank" className="download-csv-link">Download CSV</CSVLink>);

    if (collapsed) {
        return (<div>{elementCollapse}{elementDownload}</div>);
    }

    return (<div>
        {elementCollapse}
        {elementDownload}
        <table className="Telemetry-table">
            <thead>
                <tr>
                    <th className="Telemetry-table-th">Time (local)</th>
                    <th className="Telemetry-table-th">Device Timestamp</th>
                    <th className="Telemetry-table-th">Value</th>
                </tr>
            </thead>
            <tbody>
                {props.telemetryData.map((telemetryPoint, i, arr) => renderTelemetryPoint(telemetryPoint, (i+1 < arr.length) ? arr[i+1] : null ))}
            </tbody>
        </table>
    </div>);
}

export default TelemetryTableView;