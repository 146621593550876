import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { useHistory } from 'react-router-dom';
import { extractErrorInfo } from './Utils';
import DeviceUuidQRScanner from './DeviceUuidQRScanner';

const GQL_MUTATION_OBSERVE_REQUEST = gql`
  mutation ObserveRequest($uuid: String!) {
    deviceObserve(uuid: $uuid) {
      message
    }
  }
`;

function DeviceObserveRequest(props) {
    const [uuid, setUuid] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const [observe, observeResult] =
        useMutation(
            GQL_MUTATION_OBSERVE_REQUEST,
            {
                onCompleted: onCompleted,
                onError: (err) => console.log("DeviceObserveRequest.onError() - err=" + err)
            }
        );

    function onCompleted(data) {
            // console.log("DeviceObserveRequest.onCompleted() - data=" + JSON.stringify(data));
            if (data && data.deviceObserve && data.deviceObserve.message) {
                history.push('/');
            }
            else {
                console.log("DeviceObserveRequest.onCompleted() - observe request failed");
            }
    }

    function updateUuid(newValue) {
        setUuid(newValue);
        setError(getRequestNotReadyCause(newValue));
    }

    function onUuidChanged(event) {
        updateUuid(event.target.value);
    }

    function onClickObserve(event) {
        event.preventDefault();
        startRequest();
    }

    function startRequest() {
        const msgErr = getRequestNotReadyCause(uuid);
        if (msgErr !== '') {
            console.log("DeviceObserveRequest.startRequest() - not starting: " + msgErr);
            setError(msgErr);
            return;
        }
        console.log("DeviceObserveRequest.startRequest()");
        observe({
            variables: {
                uuid: uuid.trim()
            }
        });
    }

    function getRequestNotReadyCause(uuid) {
        if (observeResult.loading) {
            return "Requesting...";
        }
        if (uuid.trim() === '') {
            return "Device UUID can't be empty";
        }
        return '';
    }

    if (observeResult.loading) {
        return (<div className="Login-screen">
            <div>Requesting observation for device {uuid.trim()} ...</div>
            <UseAnimations animation={loading} />
        </div>);
    }

    let elementError;

    if (error) {
        elementError = (<p className="errorText">{error}</p>);
    }
    else if (observeResult.error) {
        const errorInfo = extractErrorInfo(observeResult.error);
        elementError = errorInfo.message && (<div className="centered-column">
            <p className="errorText">{errorInfo.message}</p>
            <p className="progressText">{errorInfo.error_debug}</p>
        </div>);
    }
    return (
        <div className="Login-screen">
            <p>Please enter the Device UUID to observe and click 'Observe'.<br/>The request will be sent to device owner to allow observation.</p>
            <form onSubmit={onClickObserve}>
                <table>
                    <tbody>
                        {typeof props.loginState.server === 'string' && props.loginState.server && (
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="server">Server</label></td>
                                <td>{props.loginState.server}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="Login-form-table-label-td"><label htmlFor="uuid">Device UUID</label></td>
                            <td>
                                <input name="uuid" value={uuid} onChange={onUuidChanged} className="Login-form-table-input"/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <DeviceUuidQRScanner onUuidScanned={updateUuid}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}><button type="submit">Observe</button></td>
                        </tr>
                    </tbody>
                </table>
            </form>
            {elementError}
        </div>
    );
}

export default DeviceObserveRequest;