import { useSubscription } from 'react-apollo';
import { gql } from 'apollo-boost';
import { getPositionTelemetryID } from '../Utils';

const GQL_SUB_TELEMETRY = gql`
    subscription TelemetrySubscription($deviceUuid: String!, $id: Int!) {
        onTelemetry(deviceUuid: $deviceUuid, id: $id) {
            id
            time
            json
        }
    }`;

function DevicePositionSubscription(props) {
    console.log("DevicePositionSubscription");

    const positionTelemetryID = getPositionTelemetryID(props);
    if (!positionTelemetryID) { return null; }

    const device = props.device;

    function DevicePositionSubscriptionImpl(args) {
        useSubscription(
            GQL_SUB_TELEMETRY,
            {
                variables: {
                    deviceUuid: device.uuid,
                    id: positionTelemetryID
                },
                onSubscriptionData: (data) => { args.callback(data, device.uuid) }
            }
        );
        return null;
    }

    function onSubscriptionData(options, uuid) {
        console.log("DevicePositionSubscription, onSubscriptionData");
        if (
            options &&
            options.subscriptionData &&
            options.subscriptionData.data &&
            options.subscriptionData.data.onTelemetry &&
            options.subscriptionData.data.onTelemetry.time
        ) {
            
            const coordsJson = options.subscriptionData.data.onTelemetry.json;
            try {
                const coords = JSON.parse(coordsJson);
                props.onSubscriptionData({latitude: coords.latitude, longitude: coords.longitude}, uuid);
            } catch {
                // do nothing on catch
            }
        }
    }
    
    return <DevicePositionSubscriptionImpl callback={onSubscriptionData} />;
}

export default DevicePositionSubscription;