import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { relURL } from './Utils';

const GQL_MUTATION_OBSERVE_REQUEST_ACCEPT = gql`
  mutation ObserveRequestAccept($email: String!, $uuid: String!) {
    deviceObserveAccept(email: $email, uuid: $uuid) {
      message
    }
  }
`;

const GQL_MUTATION_OBSERVE_REQUEST_REJECT = gql`
  mutation deviceObserveCancel($email: String!, $uuid: String!) {
    deviceObserveCancel(email: $email, uuid: $uuid) {
      message
    }
  }
`;

function DeviceObserveIncomingRequest(props) {
    const [accept, acceptResult] =
        useMutation(
            GQL_MUTATION_OBSERVE_REQUEST_ACCEPT,
            {
                variables: {
                    email: props.request.requestorEmail,
                    uuid: props.request.deviceUuid,
                }
            }
        );
    function canAccept() {
        return (
            !acceptResult.called ||
            (
                !acceptResult.loading &&
                acceptResult.error
            )
        ) ? true : false
    }

    const [reject, rejectResult] =
        useMutation(
            GQL_MUTATION_OBSERVE_REQUEST_REJECT,
            {
                variables: {
                    email: props.request.requestorEmail,
                    uuid: props.request.deviceUuid,
                }
            }
        );
    function canReject() {
        return (
            !rejectResult.called ||
            (
                !rejectResult.loading &&
                rejectResult.error
            )
        ) ? true : false
    }

    function canDoAction() {
        return canAccept() || canReject();
    }

    function onAccept(e) {
        e.preventDefault();
        if (canDoAction()) {
            accept();
        }
    }

    function onReject(e) {
        e.preventDefault();
        if (canDoAction()) {
            reject();
        }
    }

    const canAct = canDoAction();

    const device = props.accountInfo.devices.find((d) => (d.uuid === props.request.deviceUuid));
    if (!device) {
        return <span>Broken entry.</span>
    }
    return (
        <span>
            <Link to={relURL({page: 'devices', uuid: device.id})}>{device.displayName}</Link> from <Link to={'mailto:' + props.request.requestorEmail}>{props.request.requestorName}</Link> (<Link to={'tel:'+props.request.requestorPhone}>{props.request.requestorPhone}</Link>)
            {(canAct && (<button onClick={onAccept}>Accept</button>)) || (acceptResult.loading && (<UseAnimations animation={loading} />))}
            {(canAct && (<button onClick={onReject}>Reject</button>)) || (rejectResult.loading && (<UseAnimations animation={loading} />))}
        </span>
    );
}

export default DeviceObserveIncomingRequest;