import { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';

function CommonQuery(props) {

    const [runQuery, ] =
        useLazyQuery(
            props.query(),
            {
                onCompleted: onCompleted,
                onError: onError,
                errorPolicy: 'all'
            }
        );

    function onCompleted(data) {
        props.onCompleted(data)
    }

    function onError(err) {
        props.onError(err)
    }
    
    useEffect(
        () => {
            if (!props             ||
                !props.query       ||
                !props.queryArgs   ||
                !props.onCompleted ||
                !props.onError
              ) {
                  console.log("ERROR: CommonInteractor, invalid args!");
                  return () => {};
              }
              runQuery({variables: props.queryArgs});
              return () => {};
            },
        [ runQuery, props ]
    );

    return null;
}

export default CommonQuery;