import React, { useState } from 'react';
import QrReader from 'react-qr-reader';

function DeviceUuidQRScanner(props) {
    const [qrReaderVisible, setQrReaderVisible] = useState(false);

    function onToggleQRScanner() {
        setQrReaderVisible(!qrReaderVisible);
    }

    function handleScanError(err) {
        console.error(err);
    }

    function handleScan(data) {
        console.log(`DeviceUuidQRScanner.handleScan - data=$data`);
        if (data) {
            if(!props.onUuidScanned(data)) {
                setQrReaderVisible(false);
            }
        }
    }

    return (
        <div>
            {qrReaderVisible && (
                <QrReader
                    delay={300}
                    onError={handleScanError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                />
            )}
            <button type="button" onClick={onToggleQRScanner}>{qrReaderVisible ? 'Hide QR Scanner' : 'Show QR Scanner'}</button>
        </div>
    );
}

export default DeviceUuidQRScanner;