import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { useHistory } from 'react-router-dom';
import { extractErrorInfo } from './Utils';
import DeviceUuidQRScanner from './DeviceUuidQRScanner';

const GQL_MUTATION_DEVICE_REGISTER = gql`
  mutation DeviceRegister($displayName: String!, $phone: String!, $uuid: String!) {
    deviceRegister(displayName: $displayName, phone: $phone, uuid: $uuid) {
      message
    }
  }
`;

function DeviceRegisterView(props) {
    const [displayName, setDisplayName] = useState('');
    const [phone, setPhone] = useState('');
    const [uuid, setUuid] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();
    const [register, registerResult] =
        useMutation(
            GQL_MUTATION_DEVICE_REGISTER,
            {
                onCompleted: onCompleted,
                onError: (err) => console.log("DeviceRegisterView.onError() - err=" + err)
            }
        );

    function onCompleted(data) {
        // console.log("DeviceRegisterView.onCompleted() - data=" + JSON.stringify(data));
        if (data && data.deviceRegister && data.deviceRegister.message) {
            history.push('/');
        }
        else {
            console.log("DeviceRegisterView.onCompleted() - observe request failed");
        }
    }

    function updateUuid(newValue) {
        setUuid(newValue);
        setError(getRequestNotReadyCause(displayName, phone, newValue));
    }

    function onDisplayNameChanged(event) {
        setDisplayName(event.target.value);
        setError("");
    }

    function onPhoneChanged(event) {
        setPhone(event.target.value);
        setError("");
    }

    function onUuidChanged(event) {
        updateUuid(event.target.value);
    }

    function onClickRegister(event) {
        event.preventDefault();
        startRequest();
    }

    function startRequest() {
        const msgErr = getRequestNotReadyCause(displayName, phone, uuid);
        if (msgErr !== '') {
            console.log("DeviceRegisterView.startRequest() - not starting: " + msgErr);
            setError(msgErr);
            return;
        }
        console.log("DeviceRegisterView.startRequest()");
        register({
            variables: {
                displayName: displayName.trim(),
                phone: phone.trim(),
                uuid: uuid.trim()
            }
        });
    }

    function getRequestNotReadyCause(displayName, phone, uuid) {
        if (registerResult.loading) {
            return "Requesting...";
        }
        if (displayName.trim() === '') {
            return "Device display name can't be empty";
        }
        if (phone.trim() === '') {
            return "Device phone number can't be empty";
        }
        if (uuid.trim() === '') {
            return "Device UUID can't be empty";
        }
        return '';
    }

    if (registerResult.loading) {
        return (<div className="Login-screen">
            <div>Registering device {displayName.trim()} ({uuid.trim()}) ...</div>
            <UseAnimations animation={loading} />
        </div>);
    }

    let elementError;

    if (error) {
        elementError = (<p className="errorText">{error}</p>);
    }
    else if (registerResult.error) {
        const errorInfo = extractErrorInfo(registerResult.error);
        elementError = errorInfo.message && (<div className="centered-column">
            <p className="errorText">{errorInfo.message}</p>
            <p className="progressText">{errorInfo.error_debug}</p>
        </div>);
    }
    return (
        <div className="Login-screen">
            <p>Specify parameters of the new device.</p>
            <form onSubmit={onClickRegister}>
                <table>
                    <tbody>
                        {typeof props.loginState.server === 'string' && props.loginState.server && (
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="server">Server</label></td>
                                <td>{props.loginState.server}</td>
                            </tr>
                        )}
                        <tr>
                            <td className="Login-form-table-label-td"><label htmlFor="displayName">Display Name</label></td>
                            <td><input name="displayName" value={displayName} onChange={onDisplayNameChanged} className="Login-form-table-input"/></td>
                        </tr>
                        <tr>
                            <td className="Login-form-table-label-td"><label htmlFor="phone">Phone</label></td>
                            <td><input name="phone" type="tel" value={phone} onChange={onPhoneChanged} className="Login-form-table-input"/></td>
                        </tr>
                        <tr>
                            <td className="Login-form-table-label-td"><label htmlFor="uuid">Device UUID</label></td>
                            <td><input name="uuid" value={uuid} onChange={onUuidChanged} className="Login-form-table-input"/></td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <DeviceUuidQRScanner onUuidScanned={updateUuid}/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}><button type="submit">Register</button></td>
                        </tr>
                    </tbody>
                </table>
            </form>
            {elementError}
        </div>
    );
}

export default DeviceRegisterView;