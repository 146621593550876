import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import * as AbsintheSocket from "@absinthe/socket";
import {createAbsintheSocketLink} from "@absinthe/socket-apollo-link";
import {Socket as PhoenixSocket} from "phoenix";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import _ from 'lodash';

let moment = require('moment');

export function isArrayNonEmpty(obj) {
    return (Array.isArray(obj) && obj.length) ? true : false;
}

export function closeClient(client) {
    if (!client) {
        return;
    }
    console.log("closeClient() - closing client");
    client.clearStore();
    client.stop()
}

export function createApolloClient(loginState) {
    // console.log("createApolloClient() - loginState=" + JSON.stringify(loginState));
    console.log("createApolloClient() - " + (loginState.token ? "WITH" : "WITHOUT") + " token");
    const httpServer = (loginState.server ? 'http://' + loginState.server : '');
    const wsServer = (loginState.server ? 'ws://' + loginState.server : '');
    closeClient(loginState.client);
    loginState.client = null;
    // FIXME: DOOMan: the code below doesn't work - always null!!!!
    if (loginState.linkWebSocket) {
        loginState.linkWebSocket.subscriptionClient.close()
        loginState.linkWebSocket = null
    }
    let linkWebSocket;
    const cache = new InMemoryCache(
        /*{
            typePolicies: {
                Device: {
                    fields: {
                        telemetry: {
                            // keyArgs: ['id'],
                            // Concatenate the incoming list items with
                            // the existing list items.
                            merge(existing = [], incoming) {
                                const result = mergeTimeSeries(existing, incoming);
                                console.log("InMemoryCache.Device.telemetry.merge() - existing: " + existing.length + ", incoming: " + incoming.length + ", result: " + result.length);
                                return result;
                            },
                        }
                    }
                }
            }
        }*/
    );
    const linkHttp = createHttpLink({
        uri: httpServer + '/api'
    });
    let link = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        // console.log("ApolloLink():\noperation = " + JSON.stringify(operation) + "\nforward=" + JSON.stringify(forward) + "\ntoken=" + token);
        if (loginState.token) {
            operation.setContext({
                headers: {
                    Authorization: "Bearer " + loginState.token,
                }
            });
        }

        return forward(operation);
    });
    let link2;

    if (loginState.token) {
        linkWebSocket =
            createAbsintheSocketLink(AbsintheSocket.create(
                new PhoenixSocket(
                    wsServer +
                    "/socket/websocket?Authorization=" +
                    encodeURIComponent("Bearer " + loginState.token) +
                    "&vsn="+encodeURIComponent("2.0.0")
                )
            ));
        link2 = split(
            ({ query }) => {
              const definition = getMainDefinition(query);
              // console.log("split(" + JSON.stringify(definition) + ")");
              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              );
            },
            linkWebSocket,
            linkHttp
        );
    }
    else {
        link2 = linkHttp;
    }

    const client = new ApolloClient({
        cache: cache,
        link: link.concat(link2),
        // /*
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache', // 'cache-and-network',
                errorPolicy: 'ignore'
            },
            query: {
                fetchPolicy: 'no-cache', // 'network-only',
                errorPolicy: 'all',
                returnPartialData: false
            },
            mutate: {
                errorPolicy: 'all'
            },
        }
        // */
    });
    return client;
}

export function copyLoginState(loginState, changes) {
    return Object.assign({}, loginState, changes);
}

export function getLoggedOutState(loginState, preserveEmailAndPassword) {
    return copyLoginState(
        loginState,
        {
            token: '',
            client: null,
            email: (preserveEmailAndPassword && (typeof loginState.email === 'string')) ? loginState.email : '',
            password: (preserveEmailAndPassword && (typeof loginState.password === 'string')) ? loginState.password : '',
            loggedIn: false,
            loginInProgress: false,
        }
    );
}

export function extractErrorInfo(error) {
    if (_.isString(error)) {
        return {message: error};
    }
    let result = {
        error_code: '',
        error_debug: '',
        message: ''
    };
    if (error) {
        if (error.networkError) {
            result.error_code = 'network';
            result.message = error.networkError.toString();
            result.error_debug = JSON.stringify(error.networkError)
        }
        else if (error.graphQLErrors && error.graphQLErrors.length) {
            for (let i = 0; i < error.graphQLErrors.length; i++) {
                const e = error.graphQLErrors[i];
                if (e && e.extensions && e.extensions.error_code) {
                    result.error_code = e.extensions.error_code;
                    result.error_debug = e.extensions.extra_message;
                    result.message = e.message;
                }
            }
            if (!result.error_code) {
                result.error_code = 'gql';
                result.error_debug = JSON.stringify(error);
                result.message = error;
            }
        }
        else {
            result.error_code = 'unknown';
        }
    }
    return result;
}

export function dumpObj(obj) {
    switch (typeof obj) {
        case 'object': return '{' + Object.entries(obj).map(([k, v]) => `${k} = ${v}`).join(',') + '}';
        case 'string': return obj;
        case 'number': return obj;
        default: return typeof(obj);
    }
}

export function account_isAdmin(accountInfo) {
    const roles = accountInfo.roles;
    if (!Array.isArray(roles))
         return;

    return roles.map((role) => {return role.role;}).indexOf('admin') >= 0;
}

export function account_roles(account) {
    if (!account || !account.roles || !Array.isArray(account.roles) || !account.roles.length)
        return "user";
    return account.roles.map((role) => {return role.role;}).join(", ");
}

export function splitDisplayName(displayName) {
    return displayName.split(/([\s-_+\\/@]+)/);
}

export function makeAbbreviation(str) {
    const arr = splitDisplayName(str);

    if (arr.length < 1) { return "-"; }
    if (arr.length === 1) { return str.charAt(0); }

    const first = arr[0].charAt(0);
    const last = arr[arr.length - 1].charAt(0);

    return first + last;
}

export function getPositionTelemetryID(props) {
    return props.telemetryConfig
        ? props.telemetryConfig.Telemetry.find(info => info.semantics === 'location')
        : null;
}

export function getDisplayTimestampLocal(ts) {
    return moment(ts).format('YYYY-MM-DD HH:mm:ss.SSS');
}

export function getDisplayTimestampUTC(ts) {
    return moment(ts).utc().format('YYYY-MM-DD HH:mm:ss.SSS');
}

export function compareEntriesByID(a, b) {
    const an = Number.parseInt(a.id);
    const bn = Number.parseInt(b.id);
    return ( an > bn ) ? 1 : ( ( an < bn ) ? -1 : 0 );
}

export function showServerError(error) {
    const errorInfo = extractErrorInfo(error);
    const errorString = (errorInfo.message ? errorInfo.message : "errorInfo.error_debug")
                      + (errorInfo.error_debug ? ("\n" + errorInfo.error_debug) : "");
    confirmAlert({
        overlayClassName: "alert-overlay",
        title: 'Server Error!',
        message: errorString,
        buttons: [{ label: 'Ok', onClick: () => {} }]
      });
}

export function askConfirnation(message, onYes, onNo) {
    confirmAlert({
        overlayClassName: "alert-overlay",
        title: 'Please, confirm',
        message: message,
        buttons: [{ label: 'Yes', onClick: onYes },
                  { label: 'No',  onClick: onNo  }]
      });
}

export function parseJSON(str) {
    let value
    try {
        value = JSON.parse(str);
    }
    catch (e) {
        console.log("parseJSON() failed to parse: " + JSON.stringify(str) + ", error:" + e);
    }
    return value;
}

export function relURL(args) {
    const url = new URL(window.location.protocol + '//' + window.location.host + '/');

    for (const arg in args) {
        url.searchParams.append(arg, args[arg]);
    }

    return url.pathname + url.search;
}
