import React, { useEffect, useRef, useState } from 'react';
import { NetworkStatus } from 'apollo-client';
import { useLazyQuery } from 'react-apollo';
import { gql } from 'apollo-boost';
import { isArrayNonEmpty } from './Utils';

var moment = require('moment');

const GQL_QUERY_FETCH_TELEMETRY = gql`
    query TelemetryDataFetch($deviceUuid: String!, $id: Int!, $minTimestamp: String, $till: String, $limit: Int!) {
        getDevice(uuid: $deviceUuid) {
            id
            telemetry(id: $id, minTimestamp: $minTimestamp, till: $till, limit: $limit) {
                id
                time
                deviceTime
                json
            }
        }
    }`;

export function mergeTimeSeries(s1, s2) {
    var result = [];
    const nMax = s1.length + s2.length;
    var n = 0;
    var n1 = 0
    var n2 = 0
    var prev
    var prevTime

    while (n < nMax) {
        var t
        var val

        if (n1 < s1.length) {
            if (n2 < s2.length) {
                const t1 = moment(s1[n1].time);
                const t2 = moment(s2[n2].time);
                if (t1.isAfter(t2)) {
                    val=s1[n1];
                    t = t1
                    ++n1;
                }
                else {
                    val=s2[n2];
                    t = t2
                    ++n2;
                }
            }
            else {
                val = s1[n1];
                t = moment(val.time)
                ++n1;    
            }
        }
        else if (n2 < s2.length) {
            val = s2[n2];
            t = moment(val.time)
            ++n2;
        }
        else {
            break; // done
        }

        if (prev) {
            if (prevTime.isAfter(t)) {
                result.push(val);
            }
            else if (prevTime.isSame(t)) {
                // FIXME: DOOMan: remove all points with the same timestamp, last non-nul value wins
                if (val.json !== 'null' && result[result.length-1] === 'null') {
                    result[result.length-1] = val;
                }
                else {
                    // don't override values with null
                }
                /*
                // FIXME: uncomment the following line when more precise timestamping will be implemented
                // console.assert(prev.json === val.json, {prev, val});
                if (prev.json === val.json) {
                    // don't add - remove the duplicate
                }
                else {
                    result.push(val);
                }
                */
            }
            else {
                console.assert(false, {prev, val});
                // don't add
            }
        }
        else {
            result.push(val);
        }
        prev = val;
        prevTime = t;
        ++n;
    }
    // console.log("mergeTimeSeries() - s1(" + s1.length + ") + s2(" + s2.length + ") -> result(" + result.length + ")");
    /*
    console.log(
        "mergeTimeSeries()\n  s1=" + JSON.stringify(s1) +
        "\n  s2=" + JSON.stringify(s2) +
        "\n  result = " + JSON.stringify(result)
    );
    */
    return result;
}

export function isSameRange(r1, r2) {
    // console.log("isSameRange() - r1=" + JSON.stringify(r1) + ", r2=" + JSON.stringify(r2));
    if (r1 === r2) {
        // console.log("  isSameRange() - same ref");
        return true;
    }
    if (!r1 || !r2) {
        // console.log("  isSameRange() - no, at least one is null");
        return false;
    }
    const result = r1.min === r2.min && r1.max === r2.max;
    // console.log("  isSameRange() - " + result);
    return result;
}

export function getQueryTimestampUTC(ts) {
    return moment(ts).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}

export function getQueryTimestampLocal(ts) {
    return moment(ts).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
}

function TelemetryDataLoader(props) {
    // console.log("TelemetryDataLoader()");
    const loadingRange = useRef(null);
    const viewRange = useRef(null);
    viewRange.current = props.viewRange;
    const telemetryData = useRef(null);
    const completed = useRef(false);
    const cancel = useRef(false);
    const [status, setStatus] = useState(null);

    // console.log("TelemetryDataLoader()\n  vr=" + JSON.stringify(viewRange.current) + "\n  lr=" + JSON.stringify(loadingRange.current));

    function updateTelemetryData() {
        // setTelemetryData(newData);
        if (isSameRange(viewRange.current, loadingRange.current)) {
            // console.log("TelemetryDataLoader.updateTelemetryData() - reporting results");
            props.onTelemetryDataChanged(telemetryData.current);
            setStatus(null);
        }
        else {
            console.log("TelemetryDataLoader.updateTelemetryData() - view range changed, restarting fetch with delay");
            let timerId =
                setTimeout(
                    () => {
                        console.log("TelemetryDataLoader.updateTelemetryData() - restart timeout");
                        clearTimeout(timerId);
                        timerId = null;
                        restartQuery();
                    },
                100
            );
            setStatus("Restarting data download...");
        }
    }

    function mergeTelemetryData(data) {
        if (telemetryData.current) {
            console.log("TelemetryDataLoader.mergeTelemetryData() - adding " + data.length + " to " + telemetryData.current.length);
            telemetryData.current = mergeTimeSeries(telemetryData.current, data);
            // console.log("TelemetryDataLoader.mergeTelemetryData() - result=" + JSON.stringify(telemetryData.current));
            console.log("TelemetryDataLoader.mergeTelemetryData() - result with " + telemetryData.current.length + " items");
        }
        else {
            telemetryData.current = data;
        }
    }

    function setCompleted(b) {
        // console.log("TelemetryDataLoader.setCompleted() - completed: " + JSON.stringify(completed) + " -> " + b);
        completed.current = b;
    }

    function onQueryCompleted(data) {
        console.log("TelemetryDataLoader.onQueryCompleted() - completed=" + JSON.stringify(completed));
        if (completed.current) {
            console.log("TelemetryDataLoader.onQueryCompleted() - ignore buggy call");
            return;
        }
        if (cancel.current) {
            console.log("TelemetryDataLoader.onQueryCompleted() - cancelled!");
            setStatus("Cancelled");
            return;
        }
        let done = true;
        let newStatus = null;

        if (
            data &&
            data.getDevice
        ) {
            const telemetry = data.getDevice.telemetry;
            if (isArrayNonEmpty(telemetry)) {
                console.log("TelemetryDataLoader.onQueryCompleted() - " + telemetry.length + " items");
                const countBeforeMerge = isArrayNonEmpty(telemetryData.current) ? telemetryData.current.length : 0;
                mergeTelemetryData(telemetry);
                const countAfterMerge = isArrayNonEmpty(telemetryData.current) ? telemetryData.current.length : 0;
                if (loadingRange.current === null) {
                    console.log("TelemetryDataLoader.onQueryCompleted() - initial fetch completed!");
                }
                else {
                    const minTimestamp = new Date(telemetry[telemetry.length-1].time);
                    if (minTimestamp <= loadingRange.current.min) {
                        console.log("TelemetryDataLoader.onQueryCompleted() - completed");
                    }
                    else if (countBeforeMerge === countAfterMerge) {
                        console.log("TelemetryDataLoader.onQueryCompleted() - no new data arrived, assuming the request complete");
                    }
                    else {
                        console.log(`TelemetryDataLoader.onCompleted() - fetching next for id=${props.telemetry.id}, [0]=${telemetry[0].time}, [last]=${telemetry[telemetry.length-1].time}`);
                        queueMicrotask(restartQuery);
                        // Promise.resolve().then(restartQuery);
                        newStatus = "Fetching more...";
                        done = false;
                    }
                }
            }
            else {
                console.log("TelemetryDataLoader.onCompleted() - done (no data) for id=" + props.telemetry.id);
                newStatus = "Error: no data";
            }
        }
        else {
            console.log("TelemetryDataLoader.onCompleted() - done (strange params) for id=" + props.telemetry.id);
            newStatus = "Error: unexpected params";
        }

        if (done) {
            setCompleted(true);
            updateTelemetryData();
        }

        if (done || newStatus) {
            setStatus(newStatus);
        }
    }

    const [runQuery, queryResult] =
        useLazyQuery(
            GQL_QUERY_FETCH_TELEMETRY,
            {
                notifyOnNetworkStatusChange: true,
                onCompleted: onQueryCompleted,
                onError: () => {
                    console.log("TelemetryDataLoader.onError()");
                    setCompleted(true);
                    updateTelemetryData();
                },
            }
        );

    function restartQuery() {
        if (queryResult.networkStatus !== NetworkStatus.ready && queryResult.networkStatus !== NetworkStatus.error) {
            console.log("TelemetryDataLoader.restartQuery() - not started: networkStatus=" + queryResult.networkStatus );
            setStatus(`Error: no request started due to the network status (${queryResult.networkStatus})`);
            return;
        }
        loadingRange.current = viewRange.current;
        setCompleted(false);
        cancel.current = false;
        const minTimestamp = loadingRange.current ? getQueryTimestampUTC(loadingRange.current.min) : null;
        const till = isArrayNonEmpty(telemetryData.current) ? telemetryData.current[telemetryData.current.length-1].time : (loadingRange.current ? getQueryTimestampUTC(loadingRange.current.max) : null);
        console.log(`TelemetryDataLoader.restartQuery() - starting query for id=${props.telemetry.id}, range: ` + JSON.stringify(minTimestamp) + ".." + JSON.stringify(till));
        setStatus("Fetching...");
        runQuery({
            variables: {
                deviceUuid: props.deviceUuid,
                id: props.telemetry.id,
                limit: loadingRange.current ? props.pageSize : props.maxInitialPoints,
                minTimestamp: minTimestamp,
                till: till
            },
            updateQuery: null
        });
    }

    function cancelQuery() {
        cancel.current = true;
        telemetryData.current = null;
    }

    useEffect(
        () => {
            // console.log("TelemetryDataLoader.useEffect() - enter");
            let timerId = null;

            timerId =
                setTimeout(
                    (range) => {
                        // console.log("TelemetryDataLoader.useEffect() - timeout");
                        clearTimeout(timerId);
                        timerId = null;
                        if (isSameRange(range, viewRange.current)) {
                            restartQuery();
                        }
                        else {
                            console.log("TelemetryDataLoader.useEffect() - bailing out, range=" + JSON.stringify(range) + ", viewRange.current=" + JSON.stringify(viewRange.current));
                        }
                    },
                    1000,
                    props.viewRange
                );
            setStatus("Waiting...");
            // console.log("TelemetryDataLoader.useEffect() - delaying...");
            return () => {
                // console.log("TelemetryDataLoader.useEffect() - unmounting");
                if (timerId !== null) {
                    clearTimeout(timerId);
                    timerId = null;
                }
                cancelQuery();
            };
        },
        // TODO: React Hook useEffect has missing dependencies: 'props.viewRange' and 'restartQuery'. Either include them or remove the dependency array   react-hooks/exhaustive-deps
        // TODO: React Hook useEffect has a complex expression in the dependency array. Extract it to a separate variable so it can be statically checked  react-hooks/exhaustive-deps
        [ runQuery, JSON.stringify(props.viewRange) /* , props.id */ ]
    );
    
    return (<div className="progressText">{status ? status : "Idle"}</div>);
}

export default TelemetryDataLoader;