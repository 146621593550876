import { gql } from 'apollo-boost';
import { useSubscription } from 'react-apollo';

const GQL_SUB_TELEMETRY = gql`
    subscription TelemetrySubscription($deviceUuid: String!, $id: Int!) {
        onTelemetry(deviceUuid: $deviceUuid, id: $id) {
            id
            time
            json
        }
    }`;

function TelemetryDataSubscription(props) {
    function onSubscriptionData(options) {
        // console.log("TelemetryDataSubscription.onSubscriptionData(): " + JSON.stringify(options.subscriptionData));
        if (
            options &&
            options.subscriptionData &&
            options.subscriptionData.data &&
            options.subscriptionData.data.onTelemetry &&
            options.subscriptionData.data.onTelemetry.time
        ) {
            props.onSubscriptionData(
                props.telemetry.id,
                options.subscriptionData.data.onTelemetry
            );
        }
    }

    useSubscription(
        GQL_SUB_TELEMETRY,
        {
            variables: {
                deviceUuid: props.deviceUuid,
                id: props.telemetry.id
            },
            onSubscriptionData: onSubscriptionData
        }
    );

    return null;
}

export default TelemetryDataSubscription;