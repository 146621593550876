import React from 'react';
import {Marker, Popup, Circle} from 'react-leaflet';
import * as L from 'leaflet';

function SafeZonesManagerMap(props) {

    function renderSafeZoneInput() {
        if (!props.newSafeZoneInput) return null;
        return (
            <Circle center={[props.newSafeZoneInput.latitude, props.newSafeZoneInput.longitude]} pathOptions={{color: "yellow"}} radius={props.newSafeZoneInput.radius}>
                <Popup>{props.newSafeZoneInput.displayName}</Popup>
            </Circle>
        );
    }

    function isSZAttachedToDevice(device, safeZone) {
        for (const key in device.safeZones) {
            const deviceSZ = device.safeZones[key];
            if (deviceSZ.id === safeZone.id) {
                return {label: device.displayName, value: device.uuid, safeZone: safeZone.id};
            }
        }
        return null;
    }

    function attachedDevices(safeZone) {
        const devices = props.devices;
        if (!devices) {
            return [];
        }
        const elements = devices.map((device) => (
            <div key={"device_" + device.uuid}>{isSZAttachedToDevice(device, safeZone) ? device.displayName : ""}</div>
        ));
        return elements;
    }

    function renderSafeZonesMap() {
        const safeZones = props.safeZones;
        if (!safeZones)
            return [];

        const elements = safeZones.map((safeZone) => (
            <Circle
                key={"safeZone_" + safeZone.id}
                center={[safeZone.latitude, safeZone.longitude]}
                pathOptions={(safeZone.type === "ALLOWED") ? {color: "green"} : {color: "red"}}
                radius={safeZone.radius}
            >
                <Popup>
                    <div className="map-safeZone-popup">
                        <b>{safeZone.displayName}</b>
                        <hr/>
                    </div>
                    <div>
                        {attachedDevices(safeZone)}
                    </div>
                </Popup>
            </Circle>
        ));
        return elements;
    }

    function renderMe() {
        if (!props.myLocation)
            return null;

        const icon = L.divIcon({
            className: 'custom-div-icon',
                html: "<div style='background-color:#AA38cc;' class='marker-pin'></div>"
                    + "<div class='marker-text-wrapper'>"
                    +   "<div class='marker-text'>"
                    +     "Me"
                    +   "</div>"
                    + "</div>",
                iconSize:   [30, 42],
                iconAnchor: [15, 42]
            });

        return (
            <Marker position={[props.myLocation.lat, props.myLocation.lon]} icon={icon}>
                <Popup>That's me!</Popup>
            </Marker>
        );
    }

    return (
        <div>
            {renderSafeZonesMap()}
            {renderSafeZoneInput()}
            {renderMe()}
        </div>
    );
}

export default SafeZonesManagerMap;
