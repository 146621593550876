import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { Link, useHistory } from 'react-router-dom';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { createApolloClient, closeClient, copyLoginState, extractErrorInfo, dumpObj, relURL } from './Utils';

const GQL_LOGIN_MUTATION = gql`
  mutation loginAccount($email: String!, $password: String!, $uuid: String!) {
    loginAccount(email: $email, password: $password, applicationUuid: $uuid) {
      token
    }
  }
`;

function Login(props) {
    // console.log("Login()");

    function getLoginNotReadyCause(server, email, password) {
        if (!email) {
            return "User's email address is required";
        }
        if (!password) {
            return "Password is required";
        }
        return '';
    }

    const [email, setEmail] = useState(props.loginState.email);
    const [password, setPassword] = useState((props.loginState.password === undefined || props.loginState.password === null) ? '' : props.loginState.password );
    const [error, setError] = useState('');
    const clientRef = useRef(props.loginState.client);
    const history = useHistory();

    if (clientRef.current) {
        if (props.loginState.client) {
            if (clientRef.current !== props.loginState.client) {
                closeClient(clientRef.current);
                clientRef.current = props.loginState.client;
            }
        }
    }
    else {
        clientRef.current = props.loginState.client || createApolloClient(props.loginState);
    }

    const [loginAccount, loginResult] =
        useMutation(
            GQL_LOGIN_MUTATION,
            {
                client: clientRef.current,
                onCompleted: onCompleted,
                onError: onError
            }
        );
/*
    console.log(
        "Login()\n" +
        "  props.loginState = " + dumpObj(props.loginState) + "\n" +
        "  loginResult = " + dumpObj(loginResult) + "\n" +
        "  loginResult.error = " + JSON.stringify(loginResult.error)
    );
*/
    function onEmailChanged(event) {
        setEmail(event.target.value);
        setError("");
    }

    function onPasswordChanged(event) {
        setPassword(event.target.value);
        setError("");
    }

    function onClickLogin(event) {
        event.preventDefault();
        startLogin();
    }

    function startLogin() {
        const server=props.loginState.server;
        console.log("startLogin(): server=" + server + ", email=" + email + ", password=" + password );
        if (!props.loginState.loggedIn && !props.loginState.loginInProgress) {
            const errMsg = getLoginNotReadyCause(server, email, password);
            if (errMsg) {
                setError(errMsg);
            }
            else {
                loginAccount({variables: {email: email, password: password, uuid: props.loginState.appUuid}});
                console.assert(loginResult.client === clientRef.current);
                setError('');
                props.onLoginStateChanged(copyLoginState(props.loginState, {
                    email: email,
                    token: null,
                    loggedIn: false,
                    loginInProgress: true,
                    client: loginResult.client,
                }));
            }
        }
        else {
            setError("Internal error (unexpected state: loggedIn=" + props.loginState.loggedIn + ", loginInProgress=" + props.loginState.loginInProgress + ')');
        }
    }

    useEffect(
        () => {
/*
            console.log(
                "Login.useEffect()\n" +
                "  props.loginState = " + dumpObj(props.loginState) + "\n" +
                "  loginResult = " + dumpObj(loginResult) + "\n" +
                "  loginResult.error = " + JSON.stringify(loginResult.error)
            );
*/
            if (props.loginState.loginInProgress && loginResult.error) {
                const errorInfo = extractErrorInfo(loginResult.error);
                // Handle token expired and confirmation code required here
                stopLogin(errorInfo.message);
                switch (errorInfo.error_code) {
                    case 'ACCOUNT_NOT_CONFIRMED': {
                        history.push(relURL({page: 'confirm_account'}));
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            else if (!props.loginState.loggedIn && !props.loginState.loginInProgress && !error) {
                if (props.loginState.token) {
                    if (!props.loginState.client) {
                        console.log("Login.useEffect() - token present, let's create the client!");
                        let newLoginState = copyLoginState(props.loginState, {
                            loggedIn: true,
                            loginInProgress: false,
                            client: clientRef.current,
                        });
                        // Replace client
                        props.onLoginStateChanged(newLoginState);
                    }
                    else {
                        console.log("Login.useEffect() - skipping: client already present");
                    }
                }
                else if (typeof props.loginState.password === 'string' && props.loginState.password.length ) {
                    console.log("Login.useEffect() - ready for login, will try");
                    startLogin();
                }
                else {
                    console.log("Login.useEffect() - not ready for login");
                }
            }
            else {
                console.log("Login.useEffect() - skipping: already logged in, or login in progress, or error occured");
            }
        },
        // TODO: React Hook useEffect has missing dependencies: 'error', 'history', 'props', 'startLogin', and 'stopLogin'.
        //       Either include them or remove the dependency array. However, 'props' will change when *any* prop changes, 
        //       so the preferred fix is to destructure the 'props' object outside of the useEffect call and refer to those
        //       specific props inside useEffect  react-hooks/exhaustive-deps
        [props.loginState, loginResult]
    );

    function onCompleted(data) {
        // console.log("Login.onCompleted():\ndata = " + JSON.stringify(data) + "\nloginResult.data = " + JSON.stringify(loginResult.data));
        console.log("Login.onCompleted():\ndata = " + JSON.stringify(data) + "\nloginResult = " + dumpObj(loginResult));
        if (data && data.loginAccount && data.loginAccount.token) {
            console.log("Login.onComplete(): setting token to " + data.loginAccount.token);
            let newLoginState = copyLoginState(props.loginState, {
                email: email,
                token: data.loginAccount.token,
                loggedIn: true,
                loginInProgress: false,
                client: null,
            });
            // Replace client
            closeClient(clientRef.current);
            clientRef.current = newLoginState.client = createApolloClient(newLoginState);
            props.onLoginStateChanged(newLoginState);
        }
        else {
            console.log("Login.onCompleted() - login failed");
        }
    }

    function stopLogin(errorString) {
        console.log("Login.stopLogin() - email="+email+", errorString="+errorString);
        setError(errorString);
        props.onLoginStateChanged(copyLoginState(props.loginState, {
            email: email,
            token: null,
            loggedIn: false,
            loginInProgress: false,
            client: null,
        }));
    }

    function onError(err) {
        console.log("onError: err = " + JSON.stringify(err));
        // not calling stopLogin() - will do it in the Login() later
        // stopLogin(err.toString());
    }

    function onLogout() {
        stopLogin('');
    }
/*
    function onCancel() {
        stopLogin('');
    }
*/
    const errorInfo = extractErrorInfo(loginResult.error);
    const errorString = errorInfo.message || error;
    const errorDebug = (error === errorInfo.message) && errorInfo.error_debug;
    let elementStatus;
    let elementForm;
    let elementError;
/*
    console.log(
        "Login()\n" +
        "  errorInfo = " + JSON.stringify(errorInfo) + "\n" +
        "  error = " + dumpObj(error) + "\n" +
        "  errorString = " + dumpObj(errorString) + "\n" +
        "  errorDebug = " + dumpObj(errorDebug)
    );
*/
    if (props.loginState.loggedIn) {
        elementStatus = (<div>
            <p>Logged in as {email}</p>
            <button onClick={onLogout}>Logout</button>
        </div>);
    }
    else if (props.loginState.loginInProgress) {
        // Analyze the state
        if (loginResult.loading) {
            elementStatus = (<div className="centered-column">
                <p>Logging in as {email} ...</p>
                <div><UseAnimations animation={loading} /></div>
            </div>);
            // <button onClick={onCancel}>Cancel</button>
        }
        // Not loading => finished
        else if (loginResult.error) {
            elementStatus = (<div className="centered-column">
                <p className="errorText">{errorInfo.message}</p>
                <p className="progressText">{errorInfo.error_debug}</p>
            </div>);
        }
        // Not loading and not error - what's going on?
        else {
            elementStatus = (<p>Logging in as {email} ...</p>);
        }
    }
    else {
        elementForm = (<form onSubmit={onClickLogin}>
            <table>
                <tbody>
                    {typeof props.loginState.server === 'string' && props.loginState.server && (
                        <tr>
                            <td className="Login-form-table-label-td"><label htmlFor="server">Server</label></td>
                            <td>{props.loginState.server}</td>
                        </tr>
                    )}
                    <tr>
                        <td className="Login-form-table-label-td"><label htmlFor="email">Email</label></td>
                        <td><input name="email" type="email" value={email} onChange={onEmailChanged} className="Login-form-table-input"/></td>
                    </tr>
                    <tr>
                        <td className="Login-form-table-label-td"><label htmlFor="pwd">Password</label></td>
                        <td><input name="pwd" type="password" value={password} onChange={onPasswordChanged} className="Login-form-table-input"/></td>
                    </tr>
                    <tr>
                        <td colSpan={2}><button type="submit">Login</button></td>
                    </tr>
                    <tr>
                        <td colSpan={2}><Link to={relURL({page: 'signup'})}>Sign up</Link> if you don't have an acccount</td>
                    </tr>
                </tbody>
            </table>
        </form>);
        if (errorString) {
            elementError = (<div className="centered-column">
                <p className="errorText">{errorString}</p>
                {errorDebug && (<p className="progressText">{errorDebug}</p>)}
            </div>);
        }
    }

    return (
        <div className="Login-screen">
            {elementStatus}
            {elementForm}
            {elementError}
        </div>
    );
}

export default Login;