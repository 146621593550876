import React from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {ApolloProvider} from 'react-apollo';

import DeviceTelemetryView from './DeviceTelemetryView';
import Login from './Login';
import TelemetryConfigLoader from './TelemetryConfigLoader';
import AccountInfoLoader from './AccountInfoLoader';
import AccountInfoView from './AccountInfoView';
import SignupView from './SignupView';
import AccountConfirmView from './AccountConfirmView';
import DeviceObserveRequest from './DeviceObserveRequest';
import DeviceRegisterView from './DeviceRegisterView';
import AdminViewAccountsTable from './Admin/AdminViewAccountsTable';
import AdminViewAccountController from './Admin/AdminViewAccountController';
import PrometheusCharts from './Prometheus/PrometheusCharts';

import _ from 'lodash';

function AdminAppPages(props) {
    const query = props.query;
    const admin = query.get("admin");
    switch ( _.isEmpty(admin) ? '' : admin ) {
        case 'charts':
            return <PrometheusCharts {...props} refresh={true}/>

        case 'accounts':
        default:
            const email = query.get("email");
            if (_.isEmpty(email))
                return <AdminViewAccountsTable {...props}/>

            return <AdminViewAccountController {...props} adminViewAccountEmail={email}/>
    }
}

function AppPages(props) {
    const query = props.query;
    const page  = query.get("page");

    switch (page) {
        case 'devobserve':
            return <DeviceObserveRequest loginState={props.loginState} />

        case 'devadd':
            return <DeviceRegisterView loginState={props.loginState} />

        case 'devices':
            const uuid = query.get("uuid");
            return <DeviceTelemetryView
                key={uuid}
                deviceUuid={uuid}
                telemetryConfig={props.telemetryConfig}
                loginState={props.loginState}
            />

        case 'admin':
            return <AdminAppPages {...props} />

        default:
            return <AccountInfoView accountInfo={props.accountInfo} />
    }
}

function PreLoginAppPages(props) {
    const query = new URLSearchParams(useLocation().search);
    const page  = query.get("page");

    switch (page) {
        case 'signup':
            return <SignupView loginState={props.loginState} onLoginStateChanged={props.onLoginStateChanged} />;

        case 'confirm_account':
            return <AccountConfirmView loginState={props.loginState} />;

        case 'logout':
            return <Redirect to="/" />;

        default:
            return (
                <Route>
                    {props.loginState && !props.loginState.loggedIn
                        && <Login loginState={props.loginState} onLoginStateChanged={props.onLoginStateChanged} />
                    }

                    {props.loginState && props.loginState.loggedIn
                        && <AccountInfoLoader
                                loginState={props.loginState}
                                onAccountInfoChanged={props.onAccountInfoChanged}
                                telemetryConfig={props.telemetryConfig}
                                onLoginStateChanged={props.onLoginStateChanged}
                            />
                    }

                    {props.loginState && props.loginState.loggedIn && !props.telemetryConfig
                        && <TelemetryConfigLoader
                                loginState={props.loginState}
                                onTelemetryConfigLoaded={props.onTelemetryConfigLoaded}
                            />
                    }

                    {props.loginState && props.loginState.loggedIn && props.telemetryConfig && props.accountInfo &&
                        <ApolloProvider client={props.loginState.client}>
                            <Switch>
                                <Route path={`/`}>
                                    <AppPages {...props} query={query}/>
                                </Route>
                            </Switch>
                        </ApolloProvider>
                    }
                </Route>
            );
    }
}

function MainPane(props) {
    return (<div className="MainPane">
        <Switch>
            <Route path={`/`}>
                <PreLoginAppPages {...props}/>
            </Route>
        </Switch>
    </div>);
}

export default MainPane;