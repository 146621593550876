import React from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { FaTrashAlt } from 'react-icons/fa';
import { isArrayNonEmpty } from './Utils';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const GQL_MUTATION_OWNERSHIP_DROP = gql`
  mutation OwnershipDrop($uuid: String!) {
    deviceUnregister(uuid: $uuid) {
      message
    }
  }
`;

function OwnershipDrop(props) {
    const [drop, dropResult] =
        useMutation(
            GQL_MUTATION_OWNERSHIP_DROP,
            {
                variables: {
                    uuid: props.device.uuid,
                }
            }
        );
    function canDropRequest() {
        // console.log( `OwnershipDrop.canDropRequest - props.device.owners=${JSON.stringify(props.device.owners)} props.ownerEmail = ${props.ownerEmail}` )
        return (
            props.ownerEmail &&
            props.device &&
            isArrayNonEmpty(props.device.owners) &&
            (props.ownerEmail === props.device.owners[0].email) &&
            (
                !dropResult.called ||
                (
                    !dropResult.loading &&
                    dropResult.error
                )
            )
        ) ? true : false
    }

    function onDrop(e) {
        e.preventDefault();
        if (canDropRequest()) {
            confirmAlert({
                overlayClassName: "alert-overlay",
                title: 'Giving Up Ownership',
                message: 'Are you sure you want to give up ownership of this device? Anyone knowing the Device UUID will be able to claim ownership after this.',
                buttons: [{ label: 'Yes', onClick: () => drop() },
                          { label: 'No', onClick: () => {} }]
              });
        }
    }

    function renderDropButton() {
        return canDropRequest() && (<button className="image-button icon-big Red" onClick={onDrop} title="Drop ownersip!"><FaTrashAlt className="icon-big Red"/></button>);
    }

    return (
        <span>
            {renderDropButton() || (dropResult.loading && (<UseAnimations animation={loading} />))}
        </span>
    );
}

export default OwnershipDrop;