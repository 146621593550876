import React from 'react';

function DeviceConfigurationView(props) {

    console.log("DeviceConfigurationView, INFO: properties");
    console.log(props);

    function isOwnDevice() {
        for (var owner in props.deviceInfo.owners) {
            var email = props.deviceInfo.owners[owner].email;
            if (props.loginState.email === email)
                return true;
        }
        return false;
    }

    function onSubmit(event) {
        console.log("DeviceConfigurationView, INFO: user has submitted a new configuration");

        event.preventDefault();

        var wrongInput = false;

        var input_batteryCriticalLevel  = Number.parseInt(event.target.batteryCriticalLevel.value);
        var input_sosDataUploadInterval = Number.parseInt(event.target.sosDataUploadInterval.value);
        var input_dataUploadInterval    = Number.parseInt(event.target.dataUploadInterval.value);

        if (!Number.isInteger(input_batteryCriticalLevel)
            || input_batteryCriticalLevel < 0
            || input_batteryCriticalLevel > 100)
        {
            // restore previous value
            event.target.batteryCriticalLevel.value = props.deviceInfo.deviceConfig.batteryCriticalLevel;
            wrongInput = true;
        }

        if (!Number.isInteger(input_dataUploadInterval)) {
            // restore previous value
            event.target.dataUploadInterval.value = props.deviceInfo.deviceConfig.dataUploadInterval;
            wrongInput = true;
        }

        if (!Number.isInteger(input_sosDataUploadInterval)) {
            // restore previous value
            event.target.sosDataUploadInterval.value = props.deviceInfo.deviceConfig.sosDataUploadInterval;
            wrongInput = true;
        }

        if (wrongInput) {
            console.log("DeviceConfigurationView, ERROR: user did a wrong input");
            return;
        }

        // Check configuration was really changed:
        if ( input_batteryCriticalLevel  !== props.deviceInfo.deviceConfig.batteryCriticalLevel
          || input_sosDataUploadInterval !== props.deviceInfo.deviceConfig.sosDataUploadInterval
          || input_dataUploadInterval    !== props.deviceInfo.deviceConfig.dataUploadInterval
        ) {
            console.log("DeviceConfigurationView, INFO: user has changed the device configuration");
            props.onConfigurationChanged({
                ...props.deviceInfo.deviceConfig, 
                ...{
                    batteryCriticalLevel: input_batteryCriticalLevel,
                    sosDataUploadInterval: input_sosDataUploadInterval,
                    dataUploadInterval: input_dataUploadInterval,
                }
            });
        } else {
            console.log("DeviceConfigurationView, INFO: configuration was not changed, ignore");
        }
    }

    function renderSimpleProp(name, id, value) {
        return (
            <tr>
                <td className="Account-prop-name-td">{name}</td>
                <td className="Account-prop-value-td">
                    <input key={id} id={id} defaultValue={value} className="Login-form-table-input" disabled={props.actionInProgress || !isOwnDevice()}/>
                </td>
            </tr>
        );
    }

    function status() {
        if (props.actionInProgress) {
            return (
                <div>Processing...</div>
            );
        }
        if (props.actionResult) {
            return (
                <div><b>DONE</b></div>
            );
        }
        return (
            <div className="errorText"><b>ERROR</b></div>
        );
    }

    function renderSubmitButton() {
        if (!isOwnDevice()) {
            return null;
        }
        return (
            <tr>
                <td align="center">{status()}</td>
                <td align="center"><button type="submit" disabled={props.actionInProgress || !isOwnDevice()}>Set Config</button></td>
            </tr>
        );
    }

    return (
        <div>
            <h3 className="Account-h3">Device Configuration</h3>
            <form onSubmit={onSubmit}>
                <table>
                    <tbody>
                        {renderSimpleProp('Battery Critical Level', "batteryCriticalLevel",  props.deviceInfo.deviceConfig.batteryCriticalLevel)}
                        {renderSimpleProp('Data Upload Interval', "dataUploadInterval", props.deviceInfo.deviceConfig.dataUploadInterval)}
                        {renderSimpleProp('Data Upload Interval (SOS mode)', "sosDataUploadInterval", props.deviceInfo.deviceConfig.sosDataUploadInterval)}
                        {renderSubmitButton()}
                    </tbody>
                </table>
            </form>
        </div>
    );
}

export default DeviceConfigurationView;