import React from 'react';
import {Line} from 'react-chartjs-2';
import ChartDatasourcePrometheusPlugin from 'chartjs-plugin-datasource-prometheus';
import byteSize from 'byte-size';

function PrometheusCharts(props) {
    const httpServer = (props.loginState.server ? 'http://' + props.loginState.server : '');
    const options = {
        animation: {
            duration: 0,
        },
        plugins: {
            'datasource-prometheus': {
                prometheus: {
                    endpoint: httpServer + '/prometheus',
                    baseURL: "/api/v1",
                },
                query: 'promexample_system_memory_usage{state="used"}',
                timeRange: {
                    type: 'relative',
                    start: -2 * 60 * 60 * 1000,
                    end: 0,
                    msUpdateInterval: 60 * 1000,
                },
            },
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        return byteSize(value).toString();
                    },
                },
            }]
        },
        title: {
            display: true,
            text: 'Droplet memory utilization (OpenTelemetry Collector agent)',
        },
    }
    return (
        <div>
            <Line
                plugins={[ChartDatasourcePrometheusPlugin]}
                options={options}
            />
        </div>
    );
}

export default PrometheusCharts;