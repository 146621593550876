import { useMutation } from 'react-apollo';
import { useEffect, useRef } from 'react';

function CommonMutation(props) {
    // console.log("CommonMutation");
    // console.log(props);

    const timer = useRef(null);

    const [mutation, mutationResult] = useMutation(
        props.mutation(),
        {
            // NOTE: ALWAYS DO CATCH DATA IN THE onCompleted, otherwise you may get an error like:
            //       "Trying update <component> while another <component> is rendering."
            onCompleted: onCompleted
        }
    );

    function onTimer() {
        console.log("CommonMutation, INFO: onTimer");
        stopTimer();
        props.onError(mutationResult.error);
    }

    function startTimer() {
        timer.current = setTimeout(onTimer, 100);
    }

    function stopTimer() {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    }

    // console.log(mutationResult);
    function onCompleted(data) {
        if (data) {
            console.log("CommonMutation, INFO: onCompleted");
            // console.log(data);
            props.onCompleted(data);
        }
    }

    // May cause error like "Trying update <component> while CommonMutation is rendering." here.
    // Using timer as DPC call.
    if (mutationResult.error) {
        console.log("CommonMutation, ERROR: got mutationResult.error");
        startTimer();
    }

    useEffect(
        () => {
            console.log("CommonMutation, useEffect");
            stopTimer();
            mutation({variables: props.mutationArgs});
            // cleanup function
            return () => {
                stopTimer();
            }
        },
        [mutation, props.mutationArgs]
    );

    return null;
}

export default CommonMutation;