import React from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

const GQL_MUTATION_SOS_RESET = gql`
  mutation DeviceSafeNow($sosId: Int, $uuid: String) {
    deviceSosReset(sosId: $sosId, uuid: $uuid) {
      message
    }
  }
`;

function DeviceSafeNowButton(props) {
    const [sosReset, sosResetResult] =
        useMutation(
            GQL_MUTATION_SOS_RESET
        );

    function canResetSOS() {
        return (
            props.device.sosId &&
            (
                !sosResetResult.called ||
                (
                    !sosResetResult.loading &&
                    sosResetResult.error
                )
            )
        ) ? true : false
    }

    function onClick(e) {
        e.preventDefault();
        if (canResetSOS()) {
            sosReset({
                variables: {
                    sosId: props.device.sosId,
                    uuid: props.device.uuid
                },
            });
        }
    }

    const canReset = canResetSOS();
    if (canReset) {
        return (
            <div><button onClick={onClick}>Safe Now</button></div>
        );
    }

    if (sosResetResult.loading) {
        return (
            <div><UseAnimations animation={loading}/></div>
        );
    }
    
    return null;
}

export default DeviceSafeNowButton;