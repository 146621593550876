import React, { useEffect } from 'react';
import {useState, createRef} from 'react';
import Select from 'react-select';
import SafeZoneInteractor from './SafeZone';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { FaMapMarkerAlt, FaTrashAlt } from 'react-icons/fa';

function SafeZonesManager(props) {

    const [action, setAction] = useState(null);
    const [actionsSequence, setSequenceOfAction] = useState(null);
    const [position, setPosition] = useState(props.inputPosition ? props.inputPosition : [0, 0]);

    if (actionsSequence && !action) {
        var nextAction = actionsSequence.shift();
        if (nextAction) {
            setAction(nextAction);
            setSequenceOfAction(actionsSequence);
        } else {
            setAction(null);
            setSequenceOfAction(null);
        }
    }

    const safeZones = props.safeZones; // props.accountInfo.safeZones;
    const [safeZoneError, setSafeZoneError] = useState(null);

    var inputDisplayName = createRef();
    var inputLatitude    = createRef();
    var inputLongitude   = createRef();
    var inputRadius      = createRef();
    var inputType        = createRef();

    if (props.inputPosition && props.inputPosition[0] !== position[0] && props.inputPosition[1] !== position[1]) {
        setPosition(props.inputPosition);
        setSafeZoneError(null);
    }

    // ---------------------------------------------------------------------------------------
    // SAFE ZONES MANAGEMENT
    // ---------------------------------------------------------------------------------------
    function renderSafeZoneError() {
        if (Array.isArray(safeZoneError))
            return ( <div> {safeZoneError.map((error) => (<div>{error}</div>))} </div> );
        return (<div>{safeZoneError}</div>);
    }

    function onInputLatitude(event) {
        setPosition([event.target.value, position ? position[1] : 0]);
        onInputSafeZone();
    }

    function onInputLongitude(event) {
        setPosition([position ? position[0] : 0, event.target.value]);
        onInputSafeZone();
    }

    function onInputSafeZone() {
        const input = getSafeZoneInput();
        if (!input.wrongInput) {
            setSafeZoneError(null);
        } else {
            setSafeZoneError("Incorrect values.");
        }
        props.onNewSafeZoneInput(input);
    }

    function renderAddSafeZoneForm() {
        const safeZoneTypes = [
            {label: "Allowed", value: "ALLOWED"},
            {label: "Restricted", value: "RESTRICTED"}
        ];
        return (
            <div>
                <form onSubmit={onSubmitSafeZone}>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td className="Account-prop-name-td">Name</td>
                            <td className="Account-prop-value-td">
                                <input 
                                    type="text" key={"sz_input_displayName"}
                                    onChange={onInputSafeZone}
                                    ref={inputDisplayName}
                                    style={{minWidth: "200px"}}
                                    className="Login-form-table-input"
                                    disabled={(action ? true : false)}
                                />
                            </td>
                            <td width="100%" rowSpan={4}>
                                {renderSafeZoneError()}
                            </td>
                        </tr>
                        <tr>
                            <td className="Account-prop-name-td">Latitude</td>
                            <td className="Account-prop-value-td">
                                <input type="number" step="any" key={"sz_input_latitude"}
                                       onChange={onInputLatitude}
                                       ref={inputLatitude}
                                       style={{minWidth: "200px"}}
                                       value={position ? position[0] : 0}
                                       className="Login-form-table-input"
                                       disabled={(action ? true : false)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="Account-prop-name-td">Longitude</td>
                            <td className="Account-prop-value-td">
                                <input type="number" step="any" key={"sz_input_longitude"}
                                       onChange={onInputLongitude}
                                       ref={inputLongitude}
                                       style={{minWidth: "200px"}}
                                       value={position ? position[1] : 0}
                                       className="Login-form-table-input"
                                       disabled={(action ? true : false)}/>
                            </td>
                        </tr>
                        <tr>
                            <td className="Account-prop-name-td">Radius, meters</td>
                            <td className="Account-prop-value-td">
                                <input
                                    type="number"
                                    step="any"
                                    key={"sz_input_radius"}
                                    onChange={onInputSafeZone}
                                    ref={inputRadius}
                                    style={{minWidth: "100px"}}
                                    className="Login-form-table-input"
                                    disabled={(action ? true : false)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="Account-prop-name-td">Type</td>
                            <td className="Account-prop-value-td">
                                <Select
                                    key={"sz_input_type"}
                                    ref={inputType}
                                    options={safeZoneTypes}
                                    isMulti={false}
                                    defaultValue={safeZoneTypes[0]}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td align="center"><button type="submit" disabled={(action ? true : false)}>Add</button></td>
                        </tr>
                    </tbody>
                </table>
                </form>
            </div>
        );
    }

    function onGQLMutation_CreateSafeZone_Completed(data) {
        inputDisplayName.current.value = "";
        inputLatitude.current.value    = "";
        inputLongitude.current.value   = "";
        inputRadius.current.value      = "";
        props.onNewSafeZoneInput(null);
        setAction(null);
    }

    function onGQLMutation_CreateSafeZone_Error(error) {
        setAction(null);
        setSafeZoneError(error.split('\n'));
    }

    function getSafeZoneInput() {
        var wrongInput = false;
    
        var input_displayName = inputDisplayName.current.value.trim();
        var input_latitude    = Number.parseFloat(inputLatitude.current.value.trim());
        var input_longitude   = Number.parseFloat(inputLongitude.current.value.trim());
        var input_radius      = Number.parseFloat(inputRadius.current.value.trim());
        var input_type        = inputType.current.state.value.value;

        if ( !input_displayName ) { wrongInput = true; }
        if ( isNaN(input_radius) ||
             input_radius <= 0. ) { wrongInput = true; }

        if ( isNaN(input_latitude) ) { wrongInput = true; }
        if ( input_latitude < -90. ) { wrongInput = true; }
        if ( input_latitude >  90. ) { wrongInput = true; }

        if ( isNaN(input_longitude) ) { wrongInput = true; }
        if ( input_longitude < -180. ) { wrongInput = true; }
        if ( input_longitude >  180. ) { wrongInput = true; }

        if ( input_type !== "ALLOWED"
          && input_type !== "RESTRICTED") { wrongInput = true; }

        return {
            wrongInput: wrongInput,
            displayName: input_displayName,
            latitude: input_latitude,
            longitude: input_longitude,
            radius: input_radius,
            type: input_type
        };
    }

    function onSubmitSafeZone(event) {

        console.log("AccountInfoView, INFO: user has submitted a new sage zone");
        event.preventDefault();
    
        const input = getSafeZoneInput();
        if (input.wrongInput) {
            setSafeZoneError("Incorrect values.");
            return;
        }
    
        setAction({create: input});
    }

    function onGQLQuery_GetSafeZones_Completed(data) { setAction(null); }
    function onGQLQuery_GetSafeZones_Error(error) {
        console.log(error);
        setAction(null);
    }
    
    function onGQLMutation_AttachSafeZone_Completed(data) {
        // new safe zone information will come from subscription
        setAction(null);
    }

    function onGQLMutation_AttachSafeZone_Error(error) {
        console.log(error);
        setAction(null);
    }

    function onGQLMutation_DetachSafeZone_Completed(data) {
        // new safe zone information will come from subscription
        setAction(null);
    }

    function onGQLMutation_DetachSafeZone_Error(error) {
        console.log(error);
        setAction(null);
    }

    function onGQLMutation_RemoveSafeZone_Completed(data) {
        // new safe zone information will come from subscription
        setAction(null);
    }

    function onGQLMutation_RemoveSafeZone_Error(error) {
        console.log(error);
        setAction(null);
    }

    function onChangeAttachedDevices(newDevicesSet, action) {
        console.log(newDevicesSet);
        console.log(action);
        switch(action.action) {
            case "select-option":
                setAction({attach: {safeZoneId: action.option.safeZone, deviceUuid: action.option.value}});
                break;
            case "remove-value":
                setAction({detach: {safeZoneId: action.removedValue.safeZone, deviceUuid: action.removedValue.value}});
                break;
            case "clear":
                var actions = [];
                for (var key in action.removedValues) {
                    actions.push({detach: {safeZoneId: action.removedValues[key].safeZone, deviceUuid: action.removedValues[key].value}});
                }
                setSequenceOfAction(actions);
                break;
            default:
        }
    }

    function isSZAttachedToDevice(device, safeZone) {
        for (var key in device.safeZones) {
            var deviceSZ = device.safeZones[key];
            if (deviceSZ.id === safeZone.id) {
                return {label: device.displayName, value: device.uuid, safeZone: safeZone.id};
            }
        }
        return null;
    }

    function createDevicesSelector(allOptions, safeZone) {
        var preselected = props.devices.map((device) => (
            isSZAttachedToDevice(device, safeZone)
        ));
        return (
            <Select
                options={allOptions}
                isMulti={true}
                defaultValue={preselected}
                onChange={onChangeAttachedDevices}
                isDisabled={(action ? true : false) || safeZone.foreign}
            />
        );
    }

    function generateOptionsForSafeZone(safeZone) {
        return props.devices
            .filter((device) => device.foreign !== true)
            .map((device) => (
                {label: device.displayName, value: device.uuid, safeZone: safeZone.id}
            ));
    }

    function onRemoveSafeZone(safeZoneId) {
        confirmAlert({
            overlayClassName: "alert-overlay",
            title: 'Please, confirm',
            message: 'Are you sure you want to delete safe zone?',
            buttons: [{ label: 'Yes', onClick: () => setAction({remove: {id: safeZoneId}}) },
                      { label: 'No', onClick: () => {} }]
          });
    }

    function onCenterSafeZone(coords) {
        if (!props.centerMapProc) return;
        props.centerMapProc(coords);
    }

    function renderCenterButton(safeZone) {
        if (!props.centerMapProc) return null;
        return (
            <button className="image-button icon-big" title="Show on map!" onClick={() => onCenterSafeZone({latitude: safeZone.latitude, longitude: safeZone.longitude})}>
                <FaMapMarkerAlt/>
            </button>
        );
    }

    function renderSafeZonesTable() {
        const elements = safeZones.map((safeZone) => (
            <tr key={"safeZone" + safeZone.id}>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    {renderCenterButton(safeZone)}
                </td>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <span>{safeZone.id}</span>
                </td>
                <td className="Account-table-td">{safeZone.displayName}</td>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    {safeZone.type}
                </td>
                <td className="Account-table-td">{safeZone.latitude}</td>
                <td className="Account-table-td">{safeZone.longitude}</td>
                <td className="Account-table-td">{safeZone.radius}</td>
                <td className="Account-table-td" style={{maxWidth: "50%"}}>
                    {createDevicesSelector(generateOptionsForSafeZone(safeZone), safeZone)}
                </td>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <button className="image-button icon-big Red"
                        title="Delete!" id="red"
                        disabled={(action ? true : false) || safeZone.foreign}
                        onClick={() => onRemoveSafeZone(safeZone.id)}
                    >
                        <FaTrashAlt/>
                    </button>
                </td>
            </tr>
        ));
        return (
            <table className="Account-table" width="100%">
                <thead>
                    <tr>
                        <th className="Account-table-th"></th>
                        <th className="Account-table-th">id</th>
                        <th className="Account-table-th">Name</th>
                        <th className="Account-table-th">Type</th>
                        <th className="Account-table-th">Latitude</th>
                        <th className="Account-table-th">Longitude</th>
                        <th className="Account-table-th">Radius</th>
                        <th className="Account-table-th">Attached Devices</th>
                        <th className="Account-table-th"></th>
                    </tr>
                </thead>
                <tbody>
                    {elements}
                </tbody>
            </table>
        );
    }

    useEffect(
        () => {
                onInputSafeZone();
                return () => {} // cleanup function
        },
        [position]
    );

    return (
        <div>
            {renderSafeZonesTable()}
            {renderAddSafeZoneForm()}

            <SafeZoneInteractor action={action}
                safeZones={safeZones}
                onGQLMutation_CreateSafeZone_Completed={onGQLMutation_CreateSafeZone_Completed}
                onGQLMutation_CreateSafeZone_Error={onGQLMutation_CreateSafeZone_Error}
                onGQLQuery_GetSafeZones_Completed={onGQLQuery_GetSafeZones_Completed}
                onGQLQuery_GetSafeZones_Error={onGQLQuery_GetSafeZones_Error}
                onGQLMutation_AttachSafeZone_Completed={onGQLMutation_AttachSafeZone_Completed}
                onGQLMutation_AttachSafeZone_Error={onGQLMutation_AttachSafeZone_Error}
                onGQLMutation_DetachSafeZone_Completed={onGQLMutation_DetachSafeZone_Completed}
                onGQLMutation_DetachSafeZone_Error={onGQLMutation_DetachSafeZone_Error}
                onGQLMutation_RemoveSafeZone_Completed={onGQLMutation_RemoveSafeZone_Completed}
                onGQLMutation_RemoveSafeZone_Error={onGQLMutation_RemoveSafeZone_Error}
            />
        </div>
    );
}

export default SafeZonesManager;