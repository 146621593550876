import { gql } from 'apollo-boost';
import CommonQuery from '../Utils/CommonQuery';
import CommonMutation from '../Utils/CommonMutation';
import React, { useState } from 'react';

function GQLQuery_GetDevice() {
    return gql`
    query DeviceInfo($deviceUuid: String!) {
        getDevice(uuid: $deviceUuid) {
            id
            uuid
            displayName
            phone
            sticky
            debug
            deviceConfig {
                batteryCriticalLevel
                callsAllowAcceptAll
                dataUploadInterval
                sosDataUploadInterval
                observeAutoAccept
            }
            owners {
                id
                email
                displayName
                phone
            }
            observers {
                id
                email
                displayName
                phone
            }
            safeZones {
                id
                displayName
                latitude
                longitude
                radius
                type
            }
            wifiLists {
                id
                displayName
            }
        }
    }`;
}

function GQLMutation_DeviceConfig() {
    var mutation = `mutation DeviceConfig(
        $deviceUuid: String!,
        $dataUploadInterval: Int!,
        $batteryCriticalLevel: Int!,
        $callsAllowAcceptAll: Boolean!,
        $observeAutoAccept: Boolean!,
        $sosDataUploadInterval: Int!
        ) {
            deviceChangeConfig(
                uuid: $deviceUuid,
                dataUploadInterval: $dataUploadInterval,
                batteryCriticalLevel: $batteryCriticalLevel,
                callsAllowAcceptAll: $callsAllowAcceptAll,
                observeAutoAccept: $observeAutoAccept,
                sosDataUploadInterval: $sosDataUploadInterval,
            ) { message }
        }`;
    console.log(mutation);
    return gql`${mutation}`;
}

function DeviceInteractor(props) {

    console.log("DeviceInteractor, INFO: properties");
    console.log(props);

    var deviceUuid = props.deviceUuid;
    const [deviceInfo, setDeviceInfo] = useState(null);

    if (!props.action ||
        (!props.action.applyConfig &&
         !props.action.reloadInfo)
    ) {
        console.log("DeviceInteractor: INFO: no any action required");
        return null;
    }

    //---------------------------------------------------------------------------------
    // CHANGE DEVICE CONFIG MUTATION
    function GQL_changeDeviceConfig() {
        // Check if device info was not downloaded yet
        if (!props.action.applyConfig) {
            console.log("DeviceInteractor: INFO: skip config change action, is not required");
            return null;
        }
        if (!props.action.configChangeset) {
            console.log("DeviceInteractor: WARNING: no changeset supplied");
            return null;
        }
        if (!deviceInfo) {
            console.log("DeviceInteractor: WARNING: skip config change, no device info");
            return null;
        }

        // Apply new config
        console.log("DeviceInteractor: Do call GQL deviceChangeConfig()");

        var args = {};
        args.mutation = GQLMutation_DeviceConfig;
        args.mutationArgs = {
            ...props.action.configChangeset,
            ...{deviceUuid: deviceUuid}
        };

        args.onCompleted = onGQLMutation_DeviceChangeConfig_Completed;
        args.onError = onGQLMutation_DeviceChangeConfig_Error;

        return (
            <CommonMutation {...args}/>
        );
    }

    function onGQLMutation_DeviceChangeConfig_Completed(data) {
        // console.log("DeviceInteractor, INFO: deviceChangeConfig() completed with data");
        // console.log(data);
        props.onGQLMutation_DeviceChangeConfig_Completed(data);
    }

    function onGQLMutation_DeviceChangeConfig_Error(err) {
        // console.log("DeviceInteractor, INFO: deviceChangeConfig() completed with ERROR");
        // console.log(err);
        props.onGQLMutation_DeviceChangeConfig_Error(err);
    }

    //---------------------------------------------------------------------------------
    // GET DEVICE INFO QUERY
    function GQL_getDevice() {
        // Check if device info was already downloaded
        if (deviceInfo && !props.action.reloadInfo) {
            console.log("DeviceInteractor, INFO: GQL_getDevice, skip loading data");
            return null;
        }

        var args = {};
        args.queryArgs = {};
        args.query       = GQLQuery_GetDevice;
        args.queryArgs   = {deviceUuid: props.deviceUuid};
        args.onCompleted = onGQLQuery_GetDevice_Completed;
        args.onError     = onGQLQuery_GetDevice_Error;

        return (
            <CommonQuery {...args}/>
        );
    }

    function onGQLQuery_GetDevice_Completed(data) {
        if (!data) {return}
        console.log("DeviceInteractor.onCompleted()");
        var info = data.getDevice;
        setDeviceInfo(info);
        props.onGQLQuery_GetDevice_Completed(info);
    }

    function onGQLQuery_GetDevice_Error(err) {
        console.log("DeviceInteractor.onError()");
        props.onGQLQuery_GetDevice_Error(err);
    }

    return (
        <div>
            {GQL_changeDeviceConfig()}
            {GQL_getDevice()}
        </div>
    );
}

export default DeviceInteractor;