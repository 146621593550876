import React, { createRef, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import UseAnimations from 'react-useanimations';
import alertCircle from 'react-useanimations/lib/alertCircle';
import { FaBatteryQuarter, FaUserShield, FaThumbtack, FaTools, FaMapMarkerAlt, FaGlobeEurope } from 'react-icons/fa';
import DeviceSafeNowButton from './DeviceSafeNowButton';
import DeviceObserveIncomingRequest from './DeviceObserveIncomingRequest';
import DeviceObserveOutgingRequest from './DeviceObserveOutgingRequest';
import DevicePositionSubscription from './Device/DevicePositionSubscription';
import ObserverDrop from './ObserverDrop';
import OwnershipDrop from './OwnershipDrop';
import { account_roles, isArrayNonEmpty, makeAbbreviation, relURL } from './Utils';

import SafeZonesManager from './SafeZone/SafeZonesManager';
import SafeZonesManagerMap from './SafeZone/SafeZonesManagerMap';

import WiFiListsManager from './WiFiLists/WiFiListsManager';
import PhoneListsManager from './Phonelists/PhoneListsManager';

import { MapContainer, TileLayer, FeatureGroup, useMapEvents, Marker, Popup, useMap } from 'react-leaflet';
import * as L from 'leaflet';

import axios from 'axios'

import _ from 'lodash';

// ---------------------------------------------------------------------------------------
// ACCOUNT INFO DISPLAY HELPERS
// ---------------------------------------------------------------------------------------
function renderSimpleProp(name, val) {
    return (
        <tr>
            <td className="Account-prop-name-td">{name}</td>
            <td className="Account-prop-value-td">{val}</td>
        </tr>
    );
}

function renderOwnerBrief(owner) {
    return (<a key={owner.email} href={'mailto:'+owner.email}>
        {owner.displayName}
    </a>);
}

function getArrayLengthString(array, singleForm, pluralForm, undefOrNull, empty) {
    if (!array) {
        return undefOrNull || 'Not specified';
    }
    if (!array.length) {
        return empty || 'Empty';
    }
    return array.length + ' ' + (array.length === 1 ? singleForm : pluralForm);
}

function renderIncomingObserveRequests(accountInfo) {
    if (!accountInfo || !accountInfo.incomingRequests || !accountInfo.incomingRequests) {
        return null;
    }
    return (<ul>
        {accountInfo.incomingRequests.map((request) =>
            (<li key={request.deviceUuid}>
                <DeviceObserveIncomingRequest request={request} accountInfo={accountInfo}/>
            </li>)
        )}
    </ul>);
}

function renderOutgoingObserveRequests(accountInfo) {
    if (!accountInfo || !accountInfo.outgoingRequests || !accountInfo.outgoingRequests) {
        return null;
    }
    return (<ul>
        {accountInfo.outgoingRequests.map((request) =>
            (<li key={request.deviceUuid}>
                <DeviceObserveOutgingRequest request={request}/>
            </li>)
        )}
    </ul>);
}

// ---------------------------------------------------------------------------------------
// ACCOUNT INFO VIEW IMPLEMENTATION
// ---------------------------------------------------------------------------------------
function AccountInfoView(props) {

    console.log("AccountInfoView");
    // console.log(props);

    const initTimer = useRef(null);

    const [mapClickPosition, setMapClickPosition] = useState(null);
    const [newSafeZoneInput, setNewSafeZoneInput] = useState(null);
    const [myLocation, setMyLocation] = useState(null);

    let allDevices = mergeAllDevices();
    const allSafeZones = mergeAllSafeZones(allDevices);
    const allWiFiLists = mergeAllWiFiLists(allDevices);
    const allPhoneLists = mergeAllPhoneLists(allDevices);

    const [mapBounds, setMapBounds] = useState(null);
    const featuresMapLayer = createRef();

    const [mapCenter, setMapCenter] = useState(null);

    const deviceMarkers =
        allDevices
        .reduce(
            function (map, obj) {
                map[obj.uuid] = createRef();
                return map;
            },
        {});

    function renderMyLocationButton() {
        if (!myLocation) { return null; }
        return (
            <button className="image-button icon-big" title="Show on map!"
                onClick={() => {
                    setMapCenter({latitude: myLocation.lat, longitude: myLocation.lon})
                }}>
                <FaMapMarkerAlt/>
            </button>
        );
    }

    function renderHeader() {
        if (props.foreign)
            return ( <h2>View Account: {props.accountInfo.email}</h2> );
        return ( 
            <h2>
                {renderMyLocationButton()}
                <span> My Account</span>
            </h2>
        );
    }

    // ---------------------------------------------------------------------------------------
    // DEVICES MANAGEMENT
    // ---------------------------------------------------------------------------------------
    function onSubscriptionData(position, uuid) {
        deviceMarkers[uuid].current.setLatLng({lat: position.latitude, lng: position.longitude});
    }

    const devicesSubscriptionElements =
    props.accountInfo.devices.map((device) => (
            <DevicePositionSubscription key={"device_subscription_" + device.uuid} device={device} onSubscriptionData={onSubscriptionData}/>
        ));
    const observesSubscriptionElements =
    props.accountInfo.observes.map((device) => (
            <DevicePositionSubscription key={"observe_subscription_" + device.uuid} device={device} onSubscriptionData={onSubscriptionData}/>
        ));
    
    function renderObservers(device) {
        if (!device || !device.observers || !device.observers.length) {
            return '-';
        }
        return (
            <table width="100%">
                <tbody>
                    <tr><td colSpan="2">{getArrayLengthString(device.observers, 'observer', 'observers')}:</td></tr>
                    {device.observers.map((observer) => (
                        <tr key={observer.email}>
                            <td><ObserverDrop device={device} observer={observer} accountInfo={props.accountInfo}/></td>
                            <td width="100%" align="left">{renderOwnerBrief(observer)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
    
    function renderDeviceStatus(device) {
        let elements = [];
        if (device.warningLowBattery) {
            elements.push((<div key="batterylow" className="Account-device-status-warn"><FaBatteryQuarter data-for="tooltipLowBattery" data-tip="Low Battery"/><ReactTooltip id="tooltipLowBattery"/></div>));
        }
        if (device.sosId) {
            const tooltipText =
                'SOS ' + device.sosId +
                (device.sosStartedTime ? '<br />at ' + (new Date(device.sosStartedTime)).toLocaleString() : '') +
                (device.sosNotificationTime ? '<br />last notified ' + (new Date(device.sosNotificationTime)).toLocaleString() : '');
            elements.push((<div key="sos" className="Account-device-status-warn"><UseAnimations animation={alertCircle} strokeColor="red" data-for="tooltipSOS" data-tip={tooltipText} data-html={true}/><ReactTooltip id="tooltipSOS"/></div>));
            elements.push((<DeviceSafeNowButton key="sosReset" device={device}/>));
        }
        if (device.sticky) {
            elements.push((<div key="sticky"><FaThumbtack data-for="tooltipSticky" data-tip="Sticky device - can't be unregistered"/><ReactTooltip id="tooltipSticky"/></div>));
        }
        if (device.debug) {
            elements.push((<div key="debug"><FaTools data-for="tooltipDebug" data-tip="Debug - connected to debug data stream"/><ReactTooltip id="tooltipDebug"/></div>));
        }
        let violatesRestricted = false;
        let violatesAllowed = null;
        if (isArrayNonEmpty(device.safeZones)) {
            for (const i in device.safeZones) {
                if (device.safeZones[i].type === "ALLOWED") {
                    // initialize if have at least one safe zone with type == ALLOWED
                    if (violatesAllowed === null) { violatesAllowed = true; }
                    if (!device.safeZones[i].violated) { violatesAllowed = false; }
                    continue;
                }
                if (device.safeZones[i].type === "RESTRICTED") {
                    if (device.safeZones[i].violated) { violatesRestricted = true; }
                    continue;
                }
            }
        } else {
            violatesAllowed = false;
            violatesRestricted = false;
        }
        if (violatesRestricted || violatesAllowed) {
            elements.push((
                <div key="safeZoneViolation" className="Account-device-status-warn">
                    <FaUserShield data-for="tooltipSafeZoneViolation" data-tip="Safe Zone violation!"/>
                    <ReactTooltip id="tooltipSafeZoneViolation"/>
                </div>));
        }

        return elements.length ? (<div className="Account-device-status">{elements}</div>) : null;
    }

    function parseDeviceCoordinates(device) {
        if (device.coordinates && device.coordinates[0]) {
            let coords = device.coordinates[0].json;
            try {
                coords = JSON.parse(coords);
                return {latitude: coords.latitude, longitude: coords.longitude};
            } catch { }
        }
        return null;
    }
    
    function renderGoToLocation(device) {
        const coords = parseDeviceCoordinates(device);
        if (coords) {
            return (
                <button className="image-button icon-big" title="Show on map!" onClick={() => {setMapCenter(coords)}}>
                    <FaMapMarkerAlt/>
                </button>
            );
        }
        return null;
    }
    
    function renderDevice(email, device) {
        return (<tr key={device.id}>
            <td className="Account-table-td">{renderGoToLocation(device)}</td>
            <td className="Account-table-td">{device.id}</td>
            <td className="Account-table-td"><Link key={device.uuid} to={relURL({page: 'devices', uuid: device.uuid})}>{device.displayName}</Link></td>
            <td className="Account-table-td">{renderDeviceStatus(device)}</td>
            <td className="Account-table-td">{device.phone}</td>
            <td className="Account-table-td">{device.owners ? renderOwnerBrief(device.owners[0]) : 'No owner'}</td>
            <td className="Account-table-td">{renderObservers(device)}</td>
            <td className="Account-table-td">{getArrayLengthString(device.phoneLists, 'phone list', 'phone lists', '-', '-')}</td>
            <td className="Account-table-td">{getArrayLengthString(device.safeZones, 'zone', 'zones', '-', '-')}</td>
            <td className="Account-table-td">{getArrayLengthString(device.wifiLists, 'list', 'lists', '-', '-')}</td>
            <td className="Account-table-td">{device.uuid}</td>
            <td className="Account-table-td" style={{width: "0.01%"}}><OwnershipDrop ownerEmail={email} device={device}/></td>
        </tr>);
    }
    
    function renderDevices(email, devices) {
        if (!devices || !devices.length) {
            return 'No devices';
        }
        return (<div>
            <div>{getArrayLengthString(devices, 'device', 'devices')}</div>
            <table className="Account-table">
                <thead>
                    <tr>
                        <th className="Account-table-th"></th>
                        <th className="Account-table-th">id</th>
                        <th className="Account-table-th">Name</th>
                        <th className="Account-table-th">Status</th>
                        <th className="Account-table-th">Phone</th>
                        <th className="Account-table-th">Owner</th>
                        <th className="Account-table-th">Observers</th>
                        <th className="Account-table-th">White List</th>
                        <th className="Account-table-th">Safe Zones</th>
                        <th className="Account-table-th">WiFi APs</th>
                        <th className="Account-table-th">uuid</th>
                        <th className="Account-table-th">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {devices.map((device) => renderDevice(email, device))}
                </tbody>
            </table>
        </div>);
    }
    
    // ---------------------------------------------------------------------------------------
    // MAP HELPERS AND EVENT PROCESSORS
    // ---------------------------------------------------------------------------------------
    function MapClickProcessor() {
        useMapEvents({
            click: (e) => {
                setMapClickPosition([e.latlng.lat, e.latlng.lng]);
            }
        });
        return null;
    }

    function CenterMapView(args) {
        const map = useMap();
        if (args.coordinates) {
            const mapAnchor = document.getElementById("mapAnchor");
            mapAnchor.scrollIntoView({ behavior: "smooth" });
            map.setView({lat: args.coordinates.latitude, lon: args.coordinates.longitude}, map.getZoom());
            queueMicrotask(() => {setMapCenter(null)});
            return null;
        }

        // proceed only if the map vew is not centered yet on any point
        if (myLocation) { return null; }

        // try get user location
        if (!props.foreign) {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        // map.setView({lat: position.coords.latitude, lon: position.coords.longitude}, map.getZoom());
                        setMyLocation({lat: position.coords.latitude, lon: position.coords.longitude});
                    });
            } else {
                axios.get('https://ipapi.co/json/').then((response) => {
                    let data = response.data;
                    // map.setView({lat: data.latitude, lon: data.longitude}, map.getZoom());
                    setMyLocation({lat: data.latitude, lon: data.longitude});
                }).catch((error) => {
                    console.log(error);
                });
            }
        }

        return null;
    }

    function renderDeviceOnMap(device) {
        // console.log(device);
        const coords = parseDeviceCoordinates(device);
        if (!coords) { return null; }
        const name = makeAbbreviation(device.displayName);
        const icon = L.divIcon({
            className: 'custom-div-icon',
                html: "<div style='background-color:#4838cc;' class='marker-pin'></div><div class='marker-text-wrapper'><div class='marker-text'>"
                      + name
                      + "</div></div>",
                iconSize: [30, 42],
                iconAnchor: [15, 42]
            });
        return (
            <Marker key={"marker_" + device.uuid} ref={deviceMarkers[device.uuid]} position={[coords.latitude, coords.longitude]} icon={icon}>
                <Popup>{device.displayName}</Popup>
            </Marker>
        );
    }

    function renderMe() {
        if (!myLocation)
            return null;

        const icon = L.divIcon({
            className: 'custom-div-icon',
                html: "<div style='background-color:#AA38cc;' class='marker-pin'></div>"
                    + "<div class='marker-text-wrapper'>"
                    +   "<div class='marker-text'>"
                    +     "Me"
                    +   "</div>"
                    + "</div>",
                iconSize:   [30, 42],
                iconAnchor: [15, 42]
            });

        return (
            <Marker position={[myLocation.lat, myLocation.lon]} icon={icon}>
                <Popup>That's me!</Popup>
            </Marker>
        );
    }

    function mergeAllDevices() {
        let merged = _.cloneDeep(props.accountInfo.devices);
        for (const d in props.accountInfo.observes) {
            let device = _.cloneDeep(props.accountInfo.observes[d]);
            device.foreign = true;
            merged.push(device);
        }
        return merged;
    }

    function mergeAllSafeZones(devices) {
        let mergedSafeZones = new Map();
        for (const sz in props.accountInfo.safeZones) {
            const safeZone = _.cloneDeep(props.accountInfo.safeZones[sz])
            mergedSafeZones.set(safeZone.id, safeZone);
        }
        for (const d in devices) {
            for (const sz in devices[d].safeZones) {
                let safeZone = devices[d].safeZones[sz];
                if (!mergedSafeZones.has(safeZone.id)) {
                    safeZone.foreign = true;
                    mergedSafeZones.set(safeZone.id, safeZone);
                }
            }
        }
        return Array.from(mergedSafeZones.values());
    }

    function mergeAllWiFiLists(devices) {
        let merged = new Map();
        for (const key in props.accountInfo.wifiLists) {
            const list = _.cloneDeep(props.accountInfo.wifiLists[key]);
            merged.set(list.id, list);
        }
        for (const d in devices) {
            for (const key in devices[d].wifiLists) {
                let list = devices[d].wifiLists[key];
                if (!merged.has(list.id)) {
                    list.foreign = true;
                    merged.set(list.id, list);
                }
            }
        }
        return Array.from(merged.values());
    }

    function mergeAllPhoneLists(devices) {
        let merged = new Map();
        for (const key in props.accountInfo.phonesLists) {
            const list = _.cloneDeep(props.accountInfo.phonesLists[key]);
            merged.set(list.id, list);
        }
        for (const d in devices) {
            for (const key in devices[d].phonesLists) {
                let list = devices[d].phonesLists[key];
                if (!merged.has(list.id)) {
                    list.foreign = true;
                    merged.set(list.id, list);
                }
            }
        }
        return Array.from(merged.values());
    }    

    function fitAllObjects() {
        if (featuresMapLayer.current) {
            setMapBounds(featuresMapLayer.current.getBounds());
        }
    }

    function onInitTimer() {
        if (!featuresMapLayer.current) { return; }

        const bounds = featuresMapLayer.current.getBounds();
        if (!bounds._northEast || !bounds._southWest) {
            return;
        }

        clearTimeout(initTimer.current);
        fitAllObjects();
    }

    if (!initTimer.current) {
        initTimer.current = setInterval(onInitTimer, 200);
    }

    function onAutoZoom() {
        queueMicrotask(() => {setMapBounds(null)});
    }

    function AutoZoom(args) {
        const map = useMap();
        if (args.bounds && args.bounds.isValid()) {
            map.fitBounds(args.bounds, {padding: [5, 5]});
            args.onZoom()
        }
        return null;
    }

    function renderMap() {
        return (
            <div>
                <div>
                    <MapContainer
                        center={[60, 30]}
                        zoom={15}
                        scrollWheelZoom={false}>
                        <CenterMapView coordinates={mapCenter}/>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <FeatureGroup ref={featuresMapLayer}>
                            <SafeZonesManagerMap
                                safeZones={allSafeZones}
                                devices={allDevices}
                                newSafeZoneInput={newSafeZoneInput}
                            />
                            {allDevices.map((device) => ( renderDeviceOnMap(device) ))}
                            {renderMe()}
                        </FeatureGroup>
                        <AutoZoom bounds={mapBounds} onZoom={onAutoZoom}/>
                        <MapClickProcessor/>
                    </MapContainer>
                </div>
                <div className="map-controls">
                    <button className="map-button icon-big" title="Show all!" onClick={fitAllObjects}>
                        <FaGlobeEurope/>
                    </button>
                </div>
            </div>
        );
    }

    function onNewSafeZoneInput(inputData) {
        setMapClickPosition(null);
        if (inputData && inputData.wrongInput === true)
             setNewSafeZoneInput(null);
        else setNewSafeZoneInput(inputData); // inputData = null is also here
    }

    function onCenterMap(coords) {
        setMapCenter(coords);
    }

    const ownerEmail = props.foreign ? null : props.accountInfo.email;

    return (
        <div className="Account-screen">
            {renderHeader()}
            <h3 className="Account-h3">Info</h3>
            <table>
                <tbody>
                    {renderSimpleProp('id', props.accountInfo.id)}
                    {renderSimpleProp('Name', props.accountInfo.displayName)}
                    {renderSimpleProp('Email', props.accountInfo.email)}
                    {renderSimpleProp('Phone', props.accountInfo.phone)}
                    {renderSimpleProp('Roles', account_roles(props.accountInfo))}
                </tbody>
            </table>

            <h3 className="Account-h3">Devices</h3>
            {renderDevices(ownerEmail, props.accountInfo.devices)}
            <h3 className="Account-h3">Observed Devices</h3>
            {renderDevices(ownerEmail, props.accountInfo.observes)}
            <h3 className="Account-h3">Incoming Observe Requests</h3>
            {getArrayLengthString(props.accountInfo.incomingRequests, 'requests', 'requests')}
            {renderIncomingObserveRequests(props.accountInfo)}
            <h3 className="Account-h3">Outgoing Observe Requests</h3>
            {getArrayLengthString(props.accountInfo.outgoingRequests, 'requests', 'requests')}
            {renderOutgoingObserveRequests(props.accountInfo)}

            <h3 className="Account-h3" id="mapAnchor">Safe Zones</h3>
            {renderMap()}
            <SafeZonesManager
                {...props}
                safeZones={allSafeZones}
                devices={allDevices}
                inputPosition={mapClickPosition}
                onNewSafeZoneInput={onNewSafeZoneInput}
                centerMapProc={onCenterMap}
            />

            <h3 className="Account-h3">White Lists</h3>
            {getArrayLengthString(props.accountInfo.phoneLists, 'phone list', 'phone lists')}

            <h3 className="Account-h3">WiFi Access Points</h3>
            <WiFiListsManager
                {...props}
                wifiLists={allWiFiLists}
                devices={allDevices}
            />

            <h3 className="Account-h3">Phone Lists</h3>
            <PhoneListsManager
                {...props}
                phoneLists={allPhoneLists}
                devices={allDevices}
            />

            {devicesSubscriptionElements}
            {observesSubscriptionElements}
        </div>
    );
}

export default AccountInfoView;
