import React, {useState, createRef} from 'react';
import Select from 'react-select';
import { FaTrashAlt, FaPlus } from 'react-icons/fa';

import PhoneListsInteractor from './PhoneListsInteractor';
import { isArrayNonEmpty, compareEntriesByID, showServerError, askConfirnation} from '../Utils';
import { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumberWithError, ParseError} from 'libphonenumber-js'

import _ from 'lodash';

function isAttachedToDevice(device, phoneList) {
    for (const key in device.phonesLists) {
        const l = device.phonesLists[key];
        if (l.id === phoneList.id) {
            return {label: device.displayName, value: device.uuid, phoneList: phoneList.id};
        }
    }
    return null;
}

function getPhoneInput(phoneList) {
    const inputDisplayName = document.getElementById("phone_input_name_"    + phoneList.id);
    const inputPhone       = document.getElementById("phone_input_phone_"   + phoneList.id);
    const inputSosSms      = document.getElementById("phone_input_sosSms_"  + phoneList.id);
    const inputSosCall     = document.getElementById("phone_input_sosCall_" + phoneList.id);
    const errorString      = document.getElementById("phone_input_error_"   + phoneList.id);

    let args = {};
    args.displayName = inputDisplayName.value.trim();
    args.phone       = inputPhone.value.trim();
    args.sosSms      = inputSosSms.checked;
    args.sosCall     = inputSosCall.checked;

    if (_.isEmpty(args.displayName) || _.isEmpty(args.phone)) {
        errorString.innerText = "Error: incorrect value";
        return null;
    }

    try {
        const pn = parsePhoneNumberWithError(args.phone);
        const formatted = pn.format("INTERNATIONAL")
        if (isPossiblePhoneNumber(formatted) !== true) {
            errorString.innerText = "Error: wrong phone number";
            return null;
        }

        if (isValidPhoneNumber(formatted) !== true) {
            errorString.innerText = "Error: invalid phone number";
            return null;
        }
    } catch (error) {
        if (error instanceof ParseError) {
            console.log(error.message)
        } else {
            throw error
        }
        errorString.innerText = error;
        return null;
    }

    errorString.innerHTML = "";
    return args;
}

function renderPhoneListTableHeader(foreign) {
    return (
        <tr>
            <th className="Account-table-th shrink">ID</th>
            <th className="Account-table-th shrink list-name-min-width">Name</th>
            <th className="Account-table-th" style={{width: '20%'}}>Phones</th>
            <th className="Account-table-th">Attached Devices</th>
            {foreign ? null : (<th className="Account-table-th shrink"></th>)}
        </tr>
    );
}

function renderPhoneListTable(elements, foreign) {
    return (
        <table className="Account-table" width="100%">
            <thead>{renderPhoneListTableHeader(foreign)}</thead>
            <tbody>
                {elements}
            </tbody>
        </table>
    );
}

function PhoneListsManager(props) {

    const [action, setAction] = useState(null);
    const [actionsSequence, setSequenceOfAction] = useState(null);

    if (actionsSequence && !action) {
        const nextAction = actionsSequence.shift();
        if (nextAction) {
            setAction(nextAction);
            setSequenceOfAction(actionsSequence);
        } else {
            setAction(null);
            setSequenceOfAction(null);
        }
    }

    const phoneLists = _.cloneDeep(props.phoneLists);
    const viewOnly = props.foreign ? true : false;

    let ownPhoneLists = phoneLists.filter((pl) => !pl.foreign);
    let foreignPhoneLists = phoneLists.filter((pl) => pl.foreign);

    ownPhoneLists.sort(compareEntriesByID);
    foreignPhoneLists.sort(compareEntriesByID);

    const [actionError, setActionError] = useState(null);

    let inputDisplayName = createRef();

    function renderActionError() {
        if (Array.isArray(actionError))
            return (<div> {actionError.map((error) => (<div>{error}</div>))} </div> );
        return (<div> {actionError} </div>);
    }

    function onCreateCompleted(data) {
        inputDisplayName.current.value = "";
        setAction(null);
    }

    function getUserInput() {
        let args = {}
        args.displayName = inputDisplayName.current.value.trim();
        if (_.isEmpty(args.displayName)) {
            setActionError("Incorrect values.");
            return null;
        }

        setActionError(null);
        return args;
    }

    function onSubmitPhoneList() {
        const args = getUserInput();
        if (!args) return;
    
        setAction({create: args});
    }

    function onGetCompleted(data) { setAction(null); }
    function onGetError(error) { console.log(error); setAction(null); }
    
    function onAttachDetachDeleteCompleted(data) { setAction(null); }
    function onCreateAttachDetachDeleteError(error) {
        showServerError(error);
        setAction(null);
    }

    function onChangeAttachedDevices(_newDevicesSet, action) {
        switch(action.action) {
            case "select-option":
                setAction({attach: {id: parseInt(action.option.phoneList), deviceUuid: action.option.value}});
                break;
            case "remove-value":
                setAction({detach: {id: parseInt(action.removedValue.phoneList), deviceUuid: action.removedValue.value}});
                break;
            case "clear":
                let actions = [];
                for (const key in action.removedValues) {
                    actions.push({detach: {id: parseInt(action.removedValues[key].phoneList), deviceUuid: action.removedValues[key].value}});
                }
                setSequenceOfAction(actions);
                break;
            default:
        }
    }

    function createDevicesSelector(allOptions, phoneList) {
        const preselected = props.devices.map((device) => (
            isAttachedToDevice(device, phoneList)
        ));
        return (
            <Select
                options={allOptions}
                isMulti={true}
                value={preselected}
                defaultValue={preselected}
                onChange={onChangeAttachedDevices}
                isDisabled={(action ? true : false) || phoneList.foreign || viewOnly}
            />
        );
    }

    function generateOptionsForPhoneList(phoneList) {
        return props.devices
            .filter((device) => device.foreign !== true)
            .map((device) => (
                {label: device.displayName, value: device.uuid, phoneList: phoneList.id}
            ));
    }

    function onDeletePhoneList(id) {
        askConfirnation(
            'Are you sure you want to delete phone list?',
            () => setAction({delete: {id: parseInt(id)}}),
            () => {}
        );
    }

    function onSubmitPhone(phoneList) {
        let args = getPhoneInput(phoneList);
        if (!args) {
            console.log("Can't add phone, invalid arguments");
            return;
        }

        args.phoneListId = parseInt(phoneList.id);
    
        console.log("Adding...");
        setAction({addPhone: args});
    }

    function cleanupInput(phoneListId) {
        const inputDisplayName = document.getElementById("phone_input_name_"    + phoneListId);
        const inputPhone       = document.getElementById("phone_input_phone_"   + phoneListId);
        const inputSosSms      = document.getElementById("phone_input_sosSms_"  + phoneListId);
        const inputSosCall     = document.getElementById("phone_input_sosCall_" + phoneListId);
        if (inputDisplayName) { inputDisplayName.value = ""; }
        if (inputPhone)  { inputPhone.value = ""; }
        if (inputSosSms) { inputSosSms.value = true; }
        if (inputSosCall) { inputSosCall.value = true; }
    }

    function onAddPhoneCompleted(data) {
        if (action && action.addPhone && action.addPhone.phoneListId) {
            cleanupInput(action.addPhone.phoneListId)
        }
        setAction(null);
    }

    function onAddPhoneError(error) {
        showServerError(error);
        setAction(null);
    }

    function onRemovePhoneCompleted(data) { setAction(null); }
    function onRemovePhoneError(error) {
        showServerError(error);
        setAction(null);
    }

    function renderInputErrorString(phoneList) {
        if (phoneList.foreign || viewOnly) {
            return null;
        }
        return (
            <tr><td colSpan={5} id={"phone_input_error_" + phoneList.id}></td></tr>
        );
    }

    function renderAddPhoneForm(phoneList) {
        if (phoneList.foreign || viewOnly) {
            return null;
        }
        return (
            <tr>
                <td className="td-wifi-spot-id" style={{width: "0.01%", textAlign: "center"}}>
                    <button
                        className="image-button icon-middle Green"
                        title="Add"
                        onClick={() => onSubmitPhone(phoneList)}
                        disabled={(action ? true : false)}
                    ><FaPlus/></button>
                </td>
                <td className="td-wifi-spot" style={{width: "50%"}}>
                    <input type="text"
                        className="name-input"
                        key={"phone_input_name_" + phoneList.id}
                        id={"phone_input_name_" + phoneList.id}
                        style={{minWidth: "150px"}}
                        disabled={(action ? true : false)}
                        placeholder="Name"
                    />
                </td>
                <td className="td-wifi-spot" style={{width: "50%"}}>
                    <input type="tel"
                        className="name-input"
                        key={"phone_input_phone_" + phoneList.id}
                        id={"phone_input_phone_" + phoneList.id}
                        style={{minWidth: "150px"}}
                        disabled={(action ? true : false)}
                        placeholder="Phone"
                    />
                </td>
                <td className="td-wifi-spot" style={{width: "0.01%"}}>
                    <div>
                    <table>
                        <tbody>
                            <tr>
                                <td className="td-wifi-spot right-border-dotted" rowSpan={2} style={{padding: "0px 5px 0px 10px"}}>SOS</td>
                                <td className="td-wifi-spot left">
                                    <input type="checkbox"
                                        key={"phone_input_sosSms_" + phoneList.id}
                                        id={"phone_input_sosSms_" + phoneList.id}
                                        defaultChecked={true}
                                        disabled={(action ? true : false)}
                                    />SMS
                                </td>
                            </tr>
                            <tr>
                                <td className="td-wifi-spot left">
                                    <input type="checkbox"
                                        key={"phone_input_sosCall_" + phoneList.id}
                                        id={"phone_input_sosCall_" + phoneList.id}
                                        defaultChecked={true}
                                        disabled={(action ? true : false)}
                                    />Call
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </td>
                <td style={{width: "0.01%"}}>
                    <button className="image-button icon-middle" style={{opacity: "0%"}} disabled={true}>
                        <FaTrashAlt/>
                    </button>
                </td>
            </tr>
        );
    }

    function onRemovePhone(phoneListId, phoneId) {
        askConfirnation(
            'Are you sure you want to delete phone entry?',
            () => setAction({removePhone: {id: parseInt(phoneId), phoneListId: parseInt(phoneListId)}}),
            () => {}
        );
    }

    function renderPhone(phoneList, phone) {
        return (
        <tr key={"phone_" + phone.id}>
            <td className="td-wifi-spot-id" style={{width: "0.01%"}}>
                <input className="name-input" value={"ID: " + phone.id} disabled={true}/>
            </td>
            <td className="td-wifi-spot" style={{width: "50%", minWidth: "150px"}}>
                <input className="name-input" type={"text"} disabled={true} value={phone.displayName}/>
            </td>
            <td className="td-wifi-spot" style={{width: "50%", minWidth: "150px"}}>
                <input className="name-input" type={"text"} disabled={true} value={phone.phone}/>
            </td>
            <td className="td-wifi-spot" style={{width: "0.01%"}}>
                <div>
                <table>
                    <tbody>
                        <tr>
                            <td className="td-wifi-spot right-border-dotted" rowSpan={2} style={{padding: "0px 5px 0px 10px"}}>SOS</td>
                            <td className="td-wifi-spot left">
                                <input type="checkbox"
                                    checked={phone.sosSms}
                                    disabled={true}
                                />SMS
                            </td>
                        </tr>
                        <tr>
                            <td className="td-wifi-spot left">
                                <input type="checkbox"
                                    checked={phone.sosCall}
                                    disabled={true}
                                />Call
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </td>
            {(phoneList.foreign || viewOnly) ? null : (
                <td style={{width: "0.01%"}}>
                    <button
                        className="image-button icon-middle Red"
                        title="Delete!"
                        disabled={(action ? true : false)}
                        onClick={() => onRemovePhone(phoneList.id, phone.id)}
                    >
                        <FaTrashAlt/>
                    </button>
                </td>
            )}
        </tr>);
    }

    function renderPhones(phoneList) {
        const elements = 
            isArrayNonEmpty(phoneList.phones)
                ? phoneList.phones.sort(compareEntriesByID).map((phone) => (
                        renderPhone(phoneList, phone)
                    ))
                : [];
        return (
            <div>
                <table>
                    <tbody>
                        {elements}
                        {renderAddPhoneForm(phoneList)}
                        {renderInputErrorString(phoneList)}
                    </tbody>
                </table>
            </div>
        );
    }

    function renderOwnPhoneListsTable(phoneLists) {
        let elements = phoneLists.map((phoneList) => (
            <tr key={"phoneList_" + phoneList.id}>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <span>{phoneList.id}</span>
                </td>
                <td className="Account-table-td" style={{width: "20%"}}>{phoneList.displayName}</td>
                <td className="Account-table-td" style={{width: "30%"}}>{renderPhones(phoneList)}</td>
                <td className="Account-table-td" style={{width: "50%"}}>
                    {createDevicesSelector(generateOptionsForPhoneList(phoneList), phoneList)}
                </td>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <button className="image-button icon-big Red"
                        title="Delete!" id="red"
                        disabled={(action ? true : false)}
                        onClick={() => onDeletePhoneList(phoneList.id)}
                    >
                        <FaTrashAlt/>
                    </button>
                </td>
            </tr>
        ));

        elements.push((
            <tr key={"phoneList_add_list"}>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <button
                        className="image-button icon-middle Green"
                        title="Add new Phone list"
                        onClick={() => onSubmitPhoneList()}
                        disabled={(action ? true : false)}
                    ><FaPlus/></button>
                </td>
                <td className="Account-table-td center">
                    <input
                        type="text"
                        key={"phoneList_input_displayName"}
                        ref={inputDisplayName}
                        className="name-input"
                        style={{textAlign: "left"}}
                        placeholder="New Phone List Name"
                        disabled={(action ? true : false)}
                    />
                </td>
                <td className="Account-table-td" style={{width: "0.01%"}}>{renderActionError()}</td>
                <td className="Account-table-td" style={{width: "50%"}}></td>
                <td className="Account-table-td" style={{width: "0.01%"}}></td>
            </tr>
        ));

        return renderPhoneListTable(elements);
    }

    function renderForeignPhoneListsTable(phoneLists) {
        const elements = phoneLists.map((phoneList) => (
            <tr key={"phoneList_" + phoneList.id}>
                <td className="Account-table-td" style={{width: "0.01%"}}>
                    <span>{phoneList.id}</span>
                </td>
                <td className="Account-table-td" style={{width: "20%"}}>{phoneList.displayName}</td>
                <td className="Account-table-td" style={{width: "30%"}}>{renderPhones(phoneList)}</td>
                <td className="Account-table-td" style={{width: "50%"}}>
                    {createDevicesSelector(generateOptionsForPhoneList(phoneList), phoneList)}
                </td>
            </tr>
        ));
        return renderPhoneListTable(elements, true);
    }

    return (
        <div>
            <h4>Own Phone lists:</h4>
            {
                viewOnly 
                    ? renderForeignPhoneListsTable(ownPhoneLists)
                    : renderOwnPhoneListsTable(ownPhoneLists)
            }
            
            <h4>Phone lists attached to observed devices:</h4>
            {renderForeignPhoneListsTable(foreignPhoneLists)}

            <PhoneListsInteractor action={action}
                phoneLists={phoneLists}
                onCreateCompleted={onCreateCompleted}
                onCreateError={onCreateAttachDetachDeleteError}
                onGetCompleted={onGetCompleted}
                onGetError={onGetError}
                onAttachCompleted={onAttachDetachDeleteCompleted}
                onAttachError={onCreateAttachDetachDeleteError}
                onDetachCompleted={onAttachDetachDeleteCompleted}
                onDetachError={onCreateAttachDetachDeleteError}
                onDeleteCompleted={onAttachDetachDeleteCompleted}
                onDeleteError={onCreateAttachDetachDeleteError}
                onAddPhoneCompleted={onAddPhoneCompleted}
                onAddPhoneError={onAddPhoneError}
                onRemovePhoneCompleted={onRemovePhoneCompleted}
                onRemovePhoneError={onRemovePhoneError}
            />
        </div>
    );
}

export default PhoneListsManager;