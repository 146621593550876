import React, { useState, useRef } from 'react';
import { useMutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { useHistory } from 'react-router-dom';
import { createApolloClient, closeClient, copyLoginState, getLoggedOutState, extractErrorInfo } from './Utils';

const GQL_MUTATION_CREATE_ACCOUNT = gql`
  mutation CreateAccount($displayName: String!, $email: String!, $password: String!, $phone: String!) {
    createAccount(displayName: $displayName, email: $email, password: $password, phone: $phone) {
      message
    }
  }
`;

function SignupView(props) {
    // console.log("SignupView");

    const [displayName, setDisplayName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [error, setError] = useState('');
    const clientRef = useRef(props.loginState.client);
    const history = useHistory();

    if (clientRef.current) {
        if (props.loginState.client) {
            if (clientRef.current !== props.loginState.client) {
                closeClient(clientRef.current);
                clientRef.current = props.loginState.client;
            }
        }
    }
    else {
        clientRef.current = props.loginState.client || createApolloClient(props.loginState);
    }

    function onCompleted(data) {
        // console.log("SignupView.onCompleted() - data=" + JSON.stringify(data));
        if (data && data.createAccount && data.createAccount.message) {
            const newLoginState = copyLoginState(getLoggedOutState(props.loginState), {
                email: email,
                password: password,
                client: clientRef.current,
            });
            props.onLoginStateChanged(newLoginState);
            history.push('/');
        }
        else {
            console.log("SignupView.onCompleted() - signup failed");
        }
    }

    const [createAccount, createAccountResult] =
        useMutation(
            GQL_MUTATION_CREATE_ACCOUNT,
            {
                client: clientRef.current,
                onCompleted: onCompleted,
                onError: (err) => console.log("SignupView.onError() - err=" + err)
            }
        );

    function onDisplayNameChanged(event) {
        setDisplayName(event.target.value);
        setError("");
    }

    function onPhoneChanged(event) {
        setPhone(event.target.value);
        setError("");
    }

    function onEmailChanged(event) {
        setEmail(event.target.value);
        setError("");
    }

    function onPasswordChanged(event) {
        setPassword(event.target.value);
        setError("");
    }

    function onPassword2Changed(event) {
        setPassword2(event.target.value);
        setError("");
    }

    function onClickSignup(event) {
        event.preventDefault();
        startSignup();
    }

    function startSignup() {
        const msgErr = getSignupNotReadyCause();
        if (msgErr !== '') {
            console.log("SignupView.startSignup() - not starting: " + msgErr);
            setError(msgErr);
            return;
        }
        console.log("SignupView.startSignup()");
        createAccount({
            variables: {
                displayName: displayName.trim(),
                email: email.trim(),
                password: password,
                phone: phone.trim()
            }
        });
    }

    function getSignupNotReadyCause() {
        if (createAccountResult.loading) {
            return "Creating account...";
        }
        if (displayName.trim() === '') {
            return "Display name can't be empty";
        }
        if (phone.trim() === '') {
            return 'Phone number can\'t be empty';
        }
        if (email.trim() === '') {
            return "User's email address is required";
        }
        if (password.trim() === '') {
            return "Password is required";
        }
        if (password2 !== password) {
            return "Passwords don't match";
        }
        return '';
    }

    if (createAccountResult.loading) {
        return (<div className="Login-screen">
            <div>Creating account...</div>
            <UseAnimations animation={loading} />
        </div>);
    }

    let elementError;

    if (error) {
        elementError = (<p className="errorText">{error}</p>);
    }
    else if (createAccountResult.error) {
        const errorInfo = extractErrorInfo(createAccountResult.error);
        elementError = errorInfo.message && (<div className="centered-column">
            <p className="errorText">{errorInfo.message}</p>
            <p className="progressText">{errorInfo.error_debug}</p>
        </div>);
    }
    return (
        <div className="Login-screen">
            <div>
                <form onSubmit={onClickSignup}>
                    <table>
                        <tbody>
                            {typeof props.loginState.server === 'string' && props.loginState.server && (
                                <tr>
                                    <td className="Login-form-table-label-td"><label htmlFor="server">Server</label></td>
                                    <td>{props.loginState.server}</td>
                                </tr>
                            )}
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="name">Name</label></td>
                                <td><input name="name" value={displayName} onChange={onDisplayNameChanged} className="Login-form-table-input"/></td>
                            </tr>
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="phone">Phone</label></td>
                                <td><input name="phone" type="tel" value={phone} onChange={onPhoneChanged} className="Login-form-table-input"/></td>
                            </tr>
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="email">Email</label></td>
                                <td><input name="email" type="email" value={email} onChange={onEmailChanged} className="Login-form-table-input"/></td>
                            </tr>
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="pwd">Password</label></td>
                                <td><input name="pwd" type="password" value={password} onChange={onPasswordChanged} className="Login-form-table-input"/></td>
                            </tr>
                            <tr>
                                <td className="Login-form-table-label-td"><label htmlFor="pwd2">Re-type password</label></td>
                                <td><input name="pwd2" type="password" value={password2} onChange={onPassword2Changed} className="Login-form-table-input"/></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><button type="submit">Signup</button></td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            {elementError}
        </div>
    );
}

export default SignupView;