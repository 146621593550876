import { gql } from 'apollo-boost';
import CommonQuery from '../Utils/CommonQuery';
import CommonMutation from '../Utils/CommonMutation';
import React, { useState } from 'react';
import {extractErrorInfo} from '../Utils';

function GQLQuery_GetSafeZones() {
    return gql`
    query GetSafeZones {
        myAccount {
          safeZones {
            id
            displayName
            latitude
            longitude
            radius
            alertTimeout
          }
        }
      }`;
}

function GQLMutation_CreateSafeZone() {
    var mutation = `mutation CreateSafeZone(
            $displayName: String!, 
            $latitude: Float!,
            $longitude: Float!,
            $radius: Float!,
            $alertTimeout: Int!,
            $type: SafeZoneType!
        ) {
            createSafeZone(
                displayName: $displayName,
                latitude: $latitude,
                longitude: $longitude,
                radius: $radius,
                alertTimeout: $alertTimeout,
                type: $type,
            ) {
                id
                displayName
                latitude
                longitude
                radius
                alertTimeout
            }
        }`;
    return gql`${mutation}`;
}

function GQLMutation_AttachSafeZone() {
    var mutation = `mutation attachSafeZoneToDevice($safeZoneId: Int!, $deviceUuid: String!) {
        attachSafeZoneToDevice(
            safeZoneId: $safeZoneId,
            deviceUuid: $deviceUuid,
        ) { message }
    }`;
    return gql`${mutation}`;
}

function GQLMutation_DetachSafeZone() {
    var mutation = `mutation detachSafeZoneFromDevice($safeZoneId: Int!, $deviceUuid: String!) {
        detachSafeZoneFromDevice(
            safeZoneId: $safeZoneId,
            deviceUuid: $deviceUuid,
        ) { message }
    }`;
    return gql`${mutation}`;
}

function GQLMutation_RemoveSafeZone() {
    var mutation = `mutation deleteSafeZone($id: Int!) { deleteSafeZone(id: $id) { message } }`;
    return gql`${mutation}`;
}

function SafeZoneInteractor(props) {

    console.log("SafeZoneInteractor, INFO: properties");
    // console.log(props);

    const [safeZonesInfo, setSafeZonesInfo] = useState(props.safeZones);

    if (!props.action ||
        (!props.action.create &&
         !props.action.remove &&
         !props.action.reload &&
         !props.action.attach &&
         !props.action.detach)
    ) {
        // console.log("SafeZoneInteractor: INFO: no any action required");
        return null;
    }

    function processError(error, callback) {
        console.log(error);
        var info = extractErrorInfo(error);
        if (info.message) {
            info = info.message + "\n";
            if (info.error_debug) {
                info += info.error_debug.replace("DEBUG: ", "");
            }
        } else {
            info = "Unknown error.";
        }
        callback(info);
    }

    //---------------------------------------------------------------------------------
    // CREATE SAFE ZONE
    function GQL_createSafeZone() {
        if (!props.action.create) { return null; }

        var args = {};
        args.mutation = GQLMutation_CreateSafeZone;
        args.mutationArgs = {...{alertTimeout: 60}, ...props.action.create};

        args.onCompleted = onGQLMutation_CreateSafeZone_Completed;
        args.onError = onGQLMutation_CreateSafeZone_Error;

        return (
            <CommonMutation {...args}/>
        );
    }

    function onGQLMutation_CreateSafeZone_Completed(data) {
        props.onGQLMutation_CreateSafeZone_Completed(data);
    }

    function onGQLMutation_CreateSafeZone_Error(error) { processError(error, props.onGQLMutation_CreateSafeZone_Error); }

    //---------------------------------------------------------------------------------
    // GET SAFE ZONES
    function GQL_getSafeZones() {
        if (safeZonesInfo && !props.action.reload) {
            return null;
        }

        var args = {};
        args.queryArgs = {};
        args.query       = GQLQuery_GetSafeZones;
        args.queryArgs   = {deviceUuid: props.deviceUuid};
        args.onCompleted = onGQLQuery_GetSafeZones_Completed;
        args.onError     = onGQLQuery_GetSafeZones_Error;

        return (
            <CommonQuery {...args}/>
        );
    }

    function onGQLQuery_GetSafeZones_Completed(data) {
        console.log(data);
        var info = data.myAccount.safeZones;
        setSafeZonesInfo(info);
        props.onGQLQuery_GetSafeZones_Completed(info);
    }

    function onGQLQuery_GetSafeZones_Error(error) { processError(error, props.onGQLQuery_GetSafeZones_Error); }

    //---------------------------------------------------------------------------------
    // ATTACH SAFE ZONE
    function GQL_attachSafeZone() {
        if (!props.action.attach) { return null; }

        var args = {};
        args.mutation = GQLMutation_AttachSafeZone;
        args.mutationArgs = {...props.action.attach};

        args.onCompleted = onGQLMutation_AttachSafeZone_Completed;
        args.onError = onGQLMutation_AttachSafeZone_Error;

        return (
            <CommonMutation {...args}/>
        );
    }

    function onGQLMutation_AttachSafeZone_Completed(data) {
        props.onGQLMutation_AttachSafeZone_Completed(data);
    }

    function onGQLMutation_AttachSafeZone_Error(error) {
        processError(error, props.onGQLMutation_AttachSafeZone_Error);
    }

    //---------------------------------------------------------------------------------
    // DETACH SAFE ZONE
    function GQL_detachSafeZone() {
        if (!props.action.detach) { return null; }

        var args = {};
        args.mutation = GQLMutation_DetachSafeZone;
        args.mutationArgs = {...props.action.detach};

        args.onCompleted = onGQLMutation_DetachSafeZone_Completed;
        args.onError = onGQLMutation_DetachSafeZone_Error;

        return (
            <CommonMutation {...args}/>
        );
    }

    function onGQLMutation_DetachSafeZone_Completed(data) {
        props.onGQLMutation_DetachSafeZone_Completed(data);
    }

    function onGQLMutation_DetachSafeZone_Error(error) {
        processError(error, props.onGQLMutation_DetachSafeZone_Error);
    }

    //---------------------------------------------------------------------------------
    // REMOVE SAFE ZONE
    function GQL_removeSafeZone() {
        if (!props.action.remove) { return null; }

        var args = {};
        args.mutation = GQLMutation_RemoveSafeZone;
        args.mutationArgs = {...props.action.remove};

        args.onCompleted = onGQLMutation_RemoveSafeZone_Completed;
        args.onError = onGQLMutation_RemoveSafeZone_Error;

        return (
            <CommonMutation {...args}/>
        );
    }

    function onGQLMutation_RemoveSafeZone_Completed(data) {
        props.onGQLMutation_RemoveSafeZone_Completed(data);
    }

    function onGQLMutation_RemoveSafeZone_Error(error) {
        processError(error, props.onGQLMutation_RemoveSafeZone_Error);
    }
    
    return (
        <div>
            {GQL_createSafeZone()}
            {GQL_getSafeZones()}
            {GQL_attachSafeZone()}
            {GQL_detachSafeZone()}
            {GQL_removeSafeZone()}
        </div>
    );
}

export default SafeZoneInteractor;